import React, {useMemo, useCallback, useEffect} from "react";
import {styled} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import styles from "./createOrder.module.scss";
import {Box, InputAdornment} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {ErrorMessage} from "formik";
import {enforceFormat, enforceFormat2, formatToPhone} from "utils/helperFunctions/helpers";
import _debounce from "lodash/debounce";
import {getformateDateUS, getFormattedTimeinAMPM, convertToLocal} from "utils/app-dates/dates";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";

import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
    border: `1px solid #fff`,

    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{fontSize: "1.3rem", color: "#000000"}} />} {...props} />
))(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    minHeight: "5px",
    height: "2rem",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(180deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1.0)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(1.5),
    marginTop: "-0.6rem",
    marginLeft: "34px"
}));

export default function CustomizedAccordions({formik}) {
    const [expanded, setExpanded] = React.useState("panel1");
    const {values, getFieldProps} = formik;
    const [maxView, setMaxview] = React.useState(50);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const leaveByTimeCalculation = (driveTime, setupTime) => {
        if (formik.values.headerData.when !== "") {
            if (driveTime !== "" || setupTime !== "") {
                const duration =
                    ((driveTime !== "" ? parseFloat(driveTime) : 0) + (setupTime !== "" ? parseFloat(setupTime) : 0)) * 60 * 1000;
                const endTime = moment(values.headerData.when);
                const startTime = endTime.subtract(duration, "ms").format("MM/DD/YYYY, h:mm A");
                formik.setFieldValue("leave_by", new Date(startTime));
            }
        }
    };

    useEffect(() => {
        if (formik.values.headerData.when !== "") {
            leaveByTimeCalculation(formik.values.drive_time, formik.values.setup_time);
        }
    }, [formik.values.headerData.when]);
    return (
        <Box sx={{marginTop: "0px"}}>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                        backgroundColor: "#fff",
                        "&": {
                            ".MuiAccordionSummary-content": {
                                alignItems: "center"
                            }
                        }
                    }}
                >
                    <Typography className={styles.headerTitle}>Delivery details</Typography>{" "}
                    <ErrorMessage
                        name="drive_time"
                        render={(msg) => (
                            <>
                                <CustomErrorMessage errorMessage={msg} />
                                <p>&nbsp;&nbsp;</p>
                            </>
                        )}
                    />{" "}
                    <ErrorMessage name="setup_time" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowGap={2}>
                        <Grid item xs={10} pt={1} sx={{display: "flex", gap: 2, justifyItems: "center"}}>
                            <div style={{width: 290}}>
                                <CustomTextField
                                    sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                    fullWidth
                                    label="Company or location name"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 250}}
                                    value={values?.company}
                                    onChange={(e) => {
                                        formik.setFieldValue("company", e.target.value);
                                    }}
                                />
                                {/* {formik?.touched?.company && formik?.errors?.company && (
                                    <CustomErrorMessage errorMessage={formik?.errors?.company} />
                                )} */}
                                <ErrorMessage name="company" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </div>
                            <div style={{width: 290}}>
                                <CustomTextField
                                    sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                    fullWidth
                                    label="Apartment name (optional)"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 14}}
                                    // defaultValue={values?.phone}
                                    value={values?.apartment}
                                    onChange={(e) => {
                                        formik.setFieldValue("apartment", e.target.value);
                                    }}
                                />{" "}
                            </div>
                        </Grid>
                        <Grid item xs={10} pt={1} sx={{display: "flex", gap: 2, justifyItems: "center"}}>
                            <div style={{width: 290}}>
                                <CustomTextField
                                    sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                    fullWidth
                                    label="Contact name"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 250}}
                                    value={values?.name}
                                    onChange={(e) => {
                                        formik.setFieldValue("name", e.target.value);
                                    }}
                                />
                                <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </div>
                            <div style={{width: 290}}>
                                <CustomTextField
                                    sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                    fullWidth
                                    label="Contact phone number"
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{maxLength: 14}}
                                    // defaultValue={values?.phone}
                                    onKeyDown={enforceFormat}
                                    onKeyUp={formatToPhone}
                                    value={values?.phone}
                                    onChange={(e) => {
                                        formik.setFieldValue("phone", e.target.value);
                                    }}
                                />{" "}
                                <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <CustomTextField
                                multiline
                                minRows={2.5}
                                sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                fullWidth
                                label="Delivery instructions (optional)"
                                InputLabelProps={{shrink: true}}
                                inputProps={{maxLength: 250}}
                                // defaultValue={values.deliveryInstruction}
                                value={values.deliveryInstruction}
                                onBlur={(e) => {
                                    formik.setTouched({deliveryInstruction: true});
                                    // formik.setFieldValue("deliveryInstruction", e.target.value);
                                }}
                                // {...formik.getFieldProps("deliveryInstruction")}
                                onChange={(e) => {
                                    formik.setFieldValue("deliveryInstruction", e.target.value);
                                }}
                            />
                        </Grid>
                        {values.headerData.when && (
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={7}
                                sx={{
                                    maxWidth: "370px !important",
                                    pt: 1,
                                    minWidth: {lg: "370px !important"},
                                    flexWrap: {lg: "nowrap", md: "wrap", xs: "wrap"}
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={3} md={4}>
                                        <CustomTextField
                                            sx={{
                                                "&": {
                                                    ".MuiOutlinedInput-root": {
                                                        width: "107px"
                                                    },
                                                    ".MuiOutlinedInput-input": {
                                                        padding: "15.5px 11px !important"
                                                    }
                                                }
                                            }}
                                            label="Drive time"
                                            {...(formik.values?.drive_time && {
                                                InputProps: {
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography color="#CCCFD6">mins</Typography>
                                                        </InputAdornment>
                                                    )
                                                }
                                            })}
                                            onKeyDown={enforceFormat2}
                                            value={values.drive_time}
                                            {...formik.getFieldProps("drive_time")}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Regular expression to enforce the desired format
                                                const regex = /^-?\d{0,3}(\.\d{0,2})?$/;

                                                // Check if the input matches the regex pattern or is empty
                                                if (regex.test(value) || value === "") {
                                                    formik.setFieldValue("drive_time", e.target.value);
                                                    setTimeout(() => leaveByTimeCalculation(e.target.value, values.setup_time), 1000);
                                                }
                                                return false; // eslint-disable-line
                                            }}
                                            // {...getFieldProps("drive_time")}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={4}>
                                        <CustomTextField
                                            sx={{
                                                "&": {
                                                    ".MuiOutlinedInput-root": {
                                                        width: "107px"
                                                    },
                                                    ".MuiOutlinedInput-input": {
                                                        padding: "15.5px 11px !important"
                                                    }
                                                }
                                            }}
                                            label="Setup time"
                                            {...(formik.values?.setup_time && {
                                                InputProps: {
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography color="#CCCFD6">mins</Typography>
                                                        </InputAdornment>
                                                    )
                                                }
                                            })}
                                            onKeyDown={enforceFormat2}
                                            value={values.setup_time}
                                            {...formik.getFieldProps("setup_time")}
                                            onChange={(e) => {
                                                const value = e.target.value;

                                                // Regular expression to enforce the desired format
                                                const regex = /^-?\d{0,3}(\.\d{0,2})?$/;

                                                // Check if the input matches the regex pattern or is empty
                                                if (regex.test(value) || value === "") {
                                                    formik.setFieldValue("setup_time", e.target.value);
                                                    setTimeout(() => leaveByTimeCalculation(values.drive_time, e.target.value), 1000);
                                                }
                                                return false; // eslint-disable-line
                                            }}
                                            // {...getFieldProps("setup_time")}
                                        />
                                    </Grid>
                                    <Grid item xs={3} md={4}>
                                        <CustomNewDateTimePicker
                                            // disabled
                                            timepicker
                                            placeholder="hh:mm"
                                            sx={{
                                                "&": {
                                                    ".MuiOutlinedInput-input": {
                                                        padding: "15.5px 10px !important"
                                                    }
                                                }
                                            }}
                                            label="Leave by"
                                            value={values.leave_by || null}
                                            {...formik.getFieldProps("leave_by")}
                                            onChange={(val) => {
                                                formik.setFieldValue(`leave_by`, val);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" sx={{backgroundColor: "#fff"}}>
                    <Typography className={styles.headerTitle}>Special instructions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={10} pt={1}>
                            <CustomTextField
                                multiline
                                minRows={2.5}
                                defaultValue={values.specialInstruction}
                                // value={values.specialInstruction}
                                InputLabelProps={{shrink: true}}
                                sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                fullWidth
                                label="Special instructions"
                                onBlur={(e) => {
                                    formik.setTouched({specialInstruction: true});
                                    formik.setFieldValue("specialInstruction", e.target.value);
                                }}
                                //  {...formik.getFieldProps("specialInstruction")}
                                onChange={(e) => {
                                    // formik.setFieldValue("specialInstruction", e.target.value);
                                }}
                                inputProps={{maxLength: 250}}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" sx={{backgroundColor: "#fff"}}>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Typography className={styles.headerTitle}>Kitchen note</Typography>
                        <Typography className={styles.semiDetail}>(not visible to customer)</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={10} pt={1}>
                            <CustomTextField
                                multiline
                                minRows={2.5}
                                sx={{"&": {height: "fit-content !important", backgroundColor: "#A0A7B9", maxWidth: "596px"}}}
                                fullWidth
                                label="Kitchen note"
                                inputProps={{maxLength: 250}}
                                // {...formik.getFieldProps("kitchenNote")}
                                defaultValue={values.kitchenNote}
                                InputLabelProps={{shrink: true}}
                                onBlur={(e) => {
                                    formik.setTouched({kitchenNote: true});
                                    formik.setFieldValue("kitchenNote", e.target.value);
                                }}
                                onChange={(e) => {
                                    // formik.setFieldValue("kitchenNote", e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {values.orderHistory?.length ? (
                <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" sx={{backgroundColor: "#fff"}}>
                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Typography className={styles.headerTitle}>Order history </Typography>
                            <Typography className={styles.semiDetail}>(not visible to customer)</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container xs={12}>
                            {values.orderHistory &&
                                values.orderHistory.map((row) => (
                                    <>
                                        {row?.description && (
                                            <Grid item xs={7}>
                                                <Typography variant="body1" color="#26272A" fontSize="0.7525rem" fontWeight={400}>
                                                    {row.description}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {row?.note && (
                                            <Grid item xs={7}>
                                                <Typography
                                                    color="#26272A"
                                                    fontSize="0.7525rem"
                                                    fontWeight={400}
                                                    sx={{display: "flex"}}
                                                    className={styles.view}
                                                >
                                                    Note: {ellipsizeText(row.note, maxView)}{" "}
                                                    {row.note.length > maxView && (
                                                        <Typography
                                                            color="#00ab3a"
                                                            sx={{cursor: "pointer"}}
                                                            fontSize="0.7525rem"
                                                            fontWeight={700}
                                                            onClick={() => setMaxview(10000)}
                                                        >
                                                            VIEW
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={5} sx={{display: "flex", flexDirection: "row", rowGap: 1, columnGap: 1}}>
                                            <Typography
                                                variant="body1"
                                                color="#26272A"
                                                sx={{textTransform: "capitalize", minWidth: "70px"}}
                                                fontSize="0.7525rem"
                                                fontWeight={600}
                                            >
                                                {ellipsizeText(
                                                    row.admin
                                                        ? `${row.admin?.first_name} ${row?.admin?.last_name}`
                                                        : row.customer && `${row.customer?.full_name} ${row?.customer?.last_name}`,
                                                    12
                                                )}
                                            </Typography>
                                            <Typography variant="body1" color="#26272A" fontSize="0.7525rem" fontWeight={400}>
                                                {" "}
                                                {getformateDateUS(convertToLocal(row?.created_at))}{" "}
                                                {getFormattedTimeinAMPM(convertToLocal(row?.created_at))}
                                            </Typography>
                                        </Grid>
                                    </>
                                ))}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ) : null}
        </Box>
    );
}
