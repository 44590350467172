import {Grid, Typography, Box} from "@mui/material";
import React, {useEffect, useState} from "react";

import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import Loader from "ui-component/Loader";
import Cards from "../../Profile/Cards/Cards";

function HouseAccountView() {
    const dispatch = useDispatch();
    const [customerData, setCustomerData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [urlSearchParams] = useSearchParams();
    const [houseData, setHouseData] = useState([]);
    // const CustomerId = urlSearchParams.get("customerid");
    const mrkName = urlSearchParams.get("customerName");
    const editMode = urlSearchParams.get("edit");
    const prm = useParams();
    const CustomerId = prm?.type;

    const getCustomerProfileData = async () => {
        const instance = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${CustomerId}`));
        const response = await instance.request();
        if (response.success) {
            setCustomerData(response.data);
            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getHouseAccountData = async () => {
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(CustomerId));

        const response = await instance.request();
        if (response.success) {
            const data = response.data;
            if (response.data?.customer_card_id) {
                setHouseData(response.data);
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Error fetching existing companies",
                    variant: "error",
                    close: true
                })
            );
        }
    };
    function convertToTitleCase(label) {
        if (typeof label !== "string") {
            return label;
        }

        const words = label.split("_").map((word, index) => {
            if (word.length > 0) {
                if (index === 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                } else {
                    return word.charAt(0).toLowerCase() + word.slice(1).toLowerCase();
                }
            }
            return word;
        });

        return words.join("-");
    }

    useEffect(() => {
        getCustomerProfileData();
        getHouseAccountData();
    }, [CustomerId]);
    const companyDetailsData = [
        {label: "Customer name", value: `${customerData?.full_name} ${customerData?.last_name}` || "-"},
        {label: "Company name", value: customerData?.company?.company_name || "-"}
    ];

    const CutomerAddressDetails = [
        {label: "First name", value: houseData?.billing_first_name || "-"},
        {label: "Last name", value: houseData?.billing_last_name || "-"},
        {label: "Email", value: houseData?.billing_email || "-"},
        {label: "Phone", value: houseData?.billing_phone || "-"},
        {label: "Billing address (optional)", value: houseData?.billing_address || "-"}
    ];

    const PaymentDetails = houseData?.routing_number
        ? [
              {label: "Payment terms", value: convertToTitleCase(houseData?.payment_term) || "-"},
              {label: "Payment type", value: "ACH" || "-"},
              {label: "Bank routing number", value: houseData?.routing_number || "-"},
              {label: "Bank account number", value: houseData?.bank_account || "-"}
          ]
        : [
              {label: "Payment term", value: convertToTitleCase(houseData?.payment_term) || "-"},
              {label: "Payment type", value: "Check" || "-"}
          ];

    const defaultAddress = customerData?.customerAddresses?.find((address) => address.is_default);
    const otherAddresses = customerData?.customerAddresses?.filter((address) => !address.is_default);

    const addressData = [
        {label: "Default", value: defaultAddress?.delivery_address || "-"},
        {
            label: "Other Addresses",
            value: otherAddresses?.map((address, index) => <div key={index}>{address.delivery_address}</div>) || "-"
        }
    ];

    const paymentData = [
        // {label: "Default", value: customerData?.customerCard[0]?.card_type || ""},
        {label: "Other cards", value: customerData?.defaultPayment || "-"}
    ];

    return (
        <>
            {loading && <Loader />}
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{maxWidth: "80%", gap: "16px"}}>
                        <Box sx={{display: "flex", gap: "16px", width: "100%", flexWrap: {xs: "wrap", sm: "nowrap"}}} mt={2}>
                            <Cards title="Customer" data={companyDetailsData} />
                            <Cards title="Payment" data={PaymentDetails} />
                        </Box>

                        <Box sx={{marginTop: "18px"}}>
                            <Grid
                                container
                                sx={{
                                    borderRadius: "4px",
                                    border: "1px solid #DBDBDB",
                                    padding: "16px"
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography sx={{fontSize: "1.125rem", fontWeight: 500}} color="#020A11">
                                        Account payable details
                                    </Typography>
                                    <Grid container mb={2}>
                                        {CutomerAddressDetails.map((item, index) => (
                                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index} mt={1.5}>
                                                <Typography sx={{fontSize: "0.625rem", fontWeight: 400}} color="#9e9e9e">
                                                    {item.label}
                                                </Typography>
                                                <Typography sx={{fontSize: "0.875rem", fontWeight: 500}} color="#212121">
                                                    {item.value}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default HouseAccountView;
