import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import React, {useEffect, useState} from "react";
import CommonHeader from "./CommonHeader";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";

import useTempDrawer from "hooks/useTempDrawer";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {API, NetworkManager} from "network/core";
import {generateUrl} from "utils/helperFunctions/helpers";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import Loader from "ui-component/Loader";

const CouponComponent = ({data, handleApplyDicount}) => {
    return (
        <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Grid container>
                    <Typography variant="h5" fontSize="1.25em" fontWeight={400} color="#0D0D0D">
                        {data?.discount_code}
                    </Typography>
                    <Typography fontSize="1.25em" fontWeight={400} color="#0D0D0D">
                        {data?.description}
                    </Typography>
                </Grid>
                <Button onClick={() => handleApplyDicount(data)} variant="contained" sx={{width: "97px", color: "#FFF"}}>
                    Apply
                </Button>
            </Stack>
        </Grid>
    );
};

const ViewAllPromotionsDrawer = ({handleApplyDicount, formik}) => {
    const {toggleDrawer} = useTempDrawer();
    const [discountList, setDiscountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        if (formik.values.selectedBrand !== "" && formik.values.marketIDForDiscount !== "") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [formik.values.selectedBrand, formik.values.marketIDForDiscount]);

    const getDiscountList = async () => {
        setLoading(true); // Start loading
        const param = {
            page: 1,
            pageSize: 25,
            sortBy: "createdAt",
            orderBy: "desc",
            service_name: "CATERING",
            market_ids: formik?.values?.marketIDForDiscount || "",
            brand_name: formik?.values?.brandName || ""
        };
        const instance = NetworkManager(API.PROMOTIONS.GET);
        const url = generateUrl(param);
        const res = await instance.request({}, [url]);

        if (res.success) {
            const discountList = res.data.discountList.filter((row) => row.number_available > 0).filter((row) => row.is_active);
            setDiscountList(discountList);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.error.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setLoading(false); // End loading
    };

    useEffect(() => {
        if (formik?.values?.marketNameForDiscount !== "" && formik?.values?.brandName !== "") {
            if (formik?.values?.marketNameForDiscount !== undefined) {
                getDiscountList();
            }
        }
    }, [formik?.values]);

    return (
        <TemporaryDrawer drawerName="viewAllPromotions" type="right" zIndex={1350} doWhenDrawerClose={() => {}}>
            <Box sx={{height: "100%", display: "flex", flexDirection: "column"}}>
                <Box sx={{flex: 1, overflowY: "scroll", paddingBottom: "2rem"}}>
                    <Box>
                        <Grid
                            container
                            sx={{
                                px: 0.5,
                                pt: {sx: 0, sm: 2, md: 0.5},
                                pb: 2,
                                position: "sticky",
                                top: 0,
                                backgroundColor: "#fff",
                                width: "100%",
                                zIndex: 1
                            }}
                        >
                            <PageHeader title="Available promotions" fontSize="1.625rem" showOnlyTitle />
                        </Grid>
                        {loading ? (
                            <Stack alignItems="center" justifyContent="center" sx={{height: "50vh"}}>
                                <Loader />
                            </Stack>
                        ) : (
                            <Grid container rowSpacing={3.5} sx={{px: 0.5, pt: 2, pr: 2}}>
                                {discountList?.map((el, i) => (
                                    <CouponComponent key={i} data={el} handleApplyDicount={handleApplyDicount} />
                                ))}
                                {discountList.length === 0 && (
                                    <Stack direction="row" mt={1} alignItems="center" justifyContent="space-between">
                                        <Typography fontSize="0.8rem" fontWeight={400} color="#f0475c">
                                            {isLoading ? (
                                                <Loader /> // You can replace this with a spinner component if needed
                                            ) : formik.values.selectedBrand === "" || formik.values.marketIDForDiscount === "" ? (
                                                "Please select brand, kitchen location and service"
                                            ) : (
                                                "No promotions available"
                                            )}
                                        </Typography>
                                    </Stack>
                                )}
                            </Grid>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            position: "fixed",
                            justifyContent: "flex-end",
                            alignItems: "end",
                            bottom: 0,
                            p: 0.5,
                            pb: "1rem",
                            bgcolor: "#fff",
                            zIndex: 1,
                            width: "438px"
                        }}
                    >
                        <ButtonGrouping
                            btnprops={[
                                {
                                    btnTitle: "Cancel",
                                    sx: (t) => ({color: t.palette.error.dark, fontFamily: "Inter"}),
                                    onClick: (e) => {
                                        toggleDrawer("right", false)(e);
                                    }
                                }
                            ]}
                        />
                    </Box>
                </Box>
            </Box>
        </TemporaryDrawer>
    );
};

export default ViewAllPromotionsDrawer;
