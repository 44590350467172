import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slider, Typography, useMediaQuery} from "@mui/material";
import React, {useState, useCallback} from "react";

import Cropper from "react-easy-crop";
import getCroppedImg from "./CropImage";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";

const cropContainer = {
    position: "relative",
    width: "100%",
    background: "#ffff",
    height: 400
};

const CropModal = ({imgSrc, open, aspectWidth = 4, aspectHeight = 3, handleClose, changeImage, aspect, cropShape, loading}) => {
    const matches = useMediaQuery("(max-width:600px)");
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [width, setWidth] = useState(aspectWidth);
    const [height, setHeight] = useState(aspectHeight);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const HandleCloseDialog = () => {
        setRotation(0);
        setZoom(1);
        handleClose();
    };

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels, rotation, changeImage);
            changeImage(croppedImage);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation]);

    return (
        <Dialog open={open} onClose={handleClose} scroll="body" maxWidth="sm" fullWidth>
            <DialogTitle id="customized-dialog-title">
                <Typography variant="headingOne">Crop Image</Typography>
            </DialogTitle>
            <DialogContent>
                <div>
                    <div style={cropContainer}>
                        <Cropper
                            image={imgSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            showGrid={false}
                            aspect={(width || 4) / (height || 3)}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            maxZoom={50}
                            objectFit="horizontal-cover"
                            cropShape={cropShape}
                        />
                    </div>
                    <div>
                        <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{marginTop: "1.5em"}}>
                            <Grid item xs={1}>
                                <ZoomOutIcon sx={{color: "#00ab3a", cursor: "pointer"}} onClick={() => zoom > 1 && setZoom(zoom - 1)} />
                            </Grid>
                            <Grid item xs={8}>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={50}
                                    step={1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom)}
                                    style={{width: "97%"}}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <ZoomInIcon style={{color: "#00ab3a", cursor: "pointer"}} onClick={() => zoom < 50 && setZoom(zoom + 1)} />
                            </Grid>
                            <Grid item xs={1}>
                                <RotateLeftIcon style={{color: "#00ab3a", cursor: "pointer"}} onClick={() => setRotation(rotation + 90)} />
                            </Grid>
                            <Grid item xs={1}>
                                <RotateRightIcon style={{color: "#00ab3a", cursor: "pointer"}} onClick={() => setRotation(rotation - 90)} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Box mb={1}>
                    <Typography variant="headingOne" fontSize="16px">
                        Aspect Ratio
                    </Typography>
                </Box>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3}>
                        <CustomTextField
                            type="number"
                            fullWidth
                            value={width}
                            onChange={(e) => {
                                setWidth(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextField
                            type="number"
                            fullWidth
                            value={height}
                            onChange={(e) => {
                                setHeight(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{px: 3}}>
                <Button
                    wide={matches}
                    issecondary
                    onClick={() => {
                        setWidth(4);
                        setHeight(3);
                        HandleCloseDialog();
                    }}
                >
                    <Typography variant="h4" sx={{color: "#f0475c"}} fontWeight={500}>
                        Cancel
                    </Typography>
                </Button>
                <Button variant="contained" wide={matches} onClick={showCroppedImage}>
                    <Typography variant="h4" fontWeight={500} sx={{color: "#fff"}}>
                        {loading ? "Image Uploading..." : "Upload"}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CropModal;
