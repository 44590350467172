import {Box, Grid, IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {ErrorMessage, FieldArray, Formik} from "formik";
import React, {useCallback, useEffect, useRef, useState} from "react";
import DietaryOptions from "../DietaryPopup/DietaryPopup";
import {enforceFormat, twoDigitAfterDecimal,enforceFormatFor3Digits,} from "utils/helperFunctions/helpers";
import * as yup from "yup";
import networkManager from "network/core/networkManager";
import {API} from "network/core";
import {dispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import {validationContant} from "utils/validations/validationConstants";
import _debounce from "lodash/debounce";

const defaultValue = {
    name: "",
    price: "",
    dietary: []
};

const AddNewModifier = (props) => {
    const {modifierGroupId, parentFormik} = props;

    const [initialValues, setInitialValues] = useState(defaultValue);

    const closeRef = useRef(null);

    const [existingModifier, setExisitingModifiers] = useState([]);
    const [searchedVlaue, setSearchedValue] = useState("");

    const handleSearch = (e) => {
        setSearchedValue(e.target.value);
    };

    const debounceFn = useCallback(_debounce(handleSearch, 800), []);

    const getExisitingModifier = async () => {
        const instance = networkManager(API.ITEM.GET);
        const response = await instance.request({}, [`?page=1&pageSize=5000&sortBy=item_name&orderBy=asc&item_name=${searchedVlaue}`]);
        if (response.success) {
            const data = response.data.item;
            setExisitingModifiers(data);
        }
    };

    useEffect(() => {
        if (searchedVlaue) {
            getExisitingModifier();
        } else {
            setExisitingModifiers([]);
        }
    }, [searchedVlaue]);

    const handleDuplicateModifier = async (id) => {
        const instance = networkManager(API.MODIFIER.DUPLICATE(`${id}?modifier_group_id=${modifierGroupId}`));
        const response = await instance.request({});
        if (response.success) {
            const md = response.data;

            const modifierData = {
                isEdit: false,
                id: md.modifier_id,
                name: md.modifier_name,
                price: md.price,
                dietary: md.ModifierDietary.map((el) => el.modifier_dietary)
            };

            const existingModifier = parentFormik.values.modifierOptions;

            parentFormik.setFieldValue("modifierOptions", [modifierData, ...existingModifier]);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setInitialValues(defaultValue);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleAddModifier = async (val, setSubmitting, resetForm) => {
        setSubmitting(true);

        if (val.id) {
            handleDuplicateModifier(val.id);
            return;
        }

        const instance = networkManager(API.MODIFIER.ADD);

        const payload = {
            name: val.name,
            price: val.price || 0,
            modifier_group_id: [modifierGroupId],
            modifier_dietary: val.dietary,
            series_no:parentFormik.values.modifierOptions?.length
        };

        const response = await instance.request(payload);

        if (response.success) {
            resetForm();
            setInitialValues(defaultValue);

            const md = response.data.modifierDetails;

            const modifierData = {
                isEdit: false,
                id: md.modifier_id,
                name: md.modifier_name,
                price: md.price,
                dietary: md.ModifierDietary.map((el) => el.modifier_dietary)
            };

            const existingModifier = parentFormik.values.modifierOptions;

            parentFormik.setFieldValue("modifierOptions", [...existingModifier, modifierData]);

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }

        setSubmitting(false);
    };

    const handleSelectExistingModifier = (obj) => {
        setInitialValues({
            name: obj?.item_name,
            price: obj?.price,
            dietary: []
        });
        setExisitingModifiers([]);
    };

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (!closeRef.current?.contains(e.target)) {
                setExisitingModifiers([]);
            }
        });
    }, []);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={yup.object({
                    name: yup
                        .string()
                        .required("Modifier name is required")
                        .max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
                    price: yup.string().test("is-decimal", "Two digits decimal", twoDigitAfterDecimal)
                })}
                onSubmit={(val, {setSubmitting, resetForm}) => {
                    handleAddModifier(val, setSubmitting, resetForm);
                }}
            >
                {(formik) => (
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={7}>
                            <Stack direction="row" alignItems="center">
                                <SymbolButton />
                                <Box sx={{width: "100%"}}>
                                    <Box sx={{position: "relative"}} ref={closeRef}>
                                        <CustomTextField
                                            sx={{
                                                fontSize: "0.73em",

                                                "&": {
                                                    height: "40px !important",
                                                    "& .MuiOutlinedInput-root": {
                                                        height: "40px",
                                                        overflow: "hidden",
                                                        "& >input": {
                                                            fontWeight: 500
                                                        }
                                                    }
                                                }
                                            }}
                                            {...props}
                                            fullWidth
                                            name="name"
                                            placeholder=""
                                            value={formik.values.name}
                                            onChange={(e) => {
                                                debounceFn(e);
                                                formik.setFieldValue("name", e.target.value);
                                            }}
                                            inputProps={{
                                                maxLength: 80
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={formik.handleSubmit}>
                                                            <Typography
                                                                variant="checkBoxSelected"
                                                                fontSize="14px"
                                                                sx={(t) => ({color: t.palette.primary.main})}
                                                            >
                                                                Add
                                                            </Typography>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {!!existingModifier?.length && (
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                                    borderRadius: 0.5,
                                                    position: "absolute",
                                                    width: "100%",
                                                    bgcolor: "#fff",
                                                    maxHeight: "200px",
                                                    overflow: "auto",
                                                    zIndex: 1380
                                                }}
                                            >
                                                {existingModifier?.map((obj, i) => (
                                                    <Box
                                                        key={i}
                                                        sx={{p: 0.2, py: 0.2, cursor: "pointer", "&:hover": {bgcolor: "#Fafafa"}}}
                                                        onClick={() => {
                                                            handleSelectExistingModifier(obj);
                                                        }}
                                                    >
                                                        <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                            {obj?.item_name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            <CustomTextField
                                sx={{
                                    "&": {
                                        height: "40px !important",
                                        "& .MuiInputBase-root > input": {
                                            bgcolor: "#F0F0F0"
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            bgcolor: "#F0F0F0",
                                            height: "40px",
                                            overflow: "hidden"
                                        }
                                    }
                                }}
                                fullWidth
                                placeholder=""
                                InputProps={{
                                    maxLength: 6,
                                    startAdornment: (
                                        <InputAdornment position="start" sx={{color: "#212121"}}>
                                            $
                                        </InputAdornment>
                                    )
                                }}
                                onKeyDown={enforceFormat}
                               // {...formik.getFieldProps("price")}
                               value={formik.values.price}
                                onChange={(e) => {
                                    if (enforceFormatFor3Digits(e)) {
                                        formik.setFieldValue("price", e.target.value);
                                    }
                                }}
                            />
                            <ErrorMessage name="price" render={(msg) => <CustomErrorMessage fontSize="0.8em" errorMessage={msg} />} />
                        </Grid>

                        <Grid item xs={1}>
                            <FieldArray
                                name="dietary"
                                render={(arrayHelper) => (
                                    <DietaryOptions
                                        haslength={formik.values.dietary.length}
                                        checked={(obj) => formik.values.dietary.includes(obj.value)}
                                        onChange={(obj) => {
                                            if (formik.values.dietary.length && formik.values.dietary.includes(obj.value)) {
                                                const valIndex = formik.values.dietary.findIndex((el) => el === obj.value);
                                                arrayHelper.remove(valIndex);
                                            } else {
                                                arrayHelper.push(obj.value);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={7} mt={-1} ml={3}>
                            <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default AddNewModifier;
