import {Box, Menu, Stack, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddAddressForm from "./addAddressForm";
import SymbolButton from "commonComponent/Buttons/SymbolButton";
import CustomerForm from "./CustomerForm";
import AddNewCustomerForm from "./AddNewCustomerForm";
import {ErrorMessage, Formik} from "formik";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import networkManager from "network/core/networkManager";
import {API} from "network/core";
import {convertToLocal, convertToUTC} from "utils/app-dates/dates";
import WithPermission from "commonComponent/RBAC/permissionHoc";

const Customer = (props) => {
    const {Parentformik, customerName, customerId, editScreen, preferrefPriceRef} = props;
    const {headerData, orderStatus} = Parentformik.values;

    const addresslist = [];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openForm, setOpenForm] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        if (orderStatus === "Draft" || orderStatus === "Quote Sent" || orderStatus === "" || orderStatus === "QUOTE_REJECTED") {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        if (openForm) {
            setOpenForm(false);
        }
        setAnchorEl(null);
    };

    const handleAddressSelect = (obj) => {
        handleClose();
    };

    const handleSelectCustomerModifier = (obj) => {
        const values = {
            customerName: obj?.full_name,
            name: obj?.full_name,
            email: obj?.email,
            phone: obj?.phone,
            company: obj?.company,
            companyId: obj?.company_id ?? "",
            customerAddresses: obj?.customerAddresses,
            userId: obj?.user_id
        };

        Parentformik.setFieldValue("headerData.customer", values);
    };

    const getCustomerProfileData = async (id) => {
        const instance = networkManager(API.CUSTOMER.GETCUSTOMERDATA(`${id}`));

        const response = await instance.request();

        if (response.success) {
            handleSelectCustomerModifier(response?.data);
        }
    };

    const getCustomerPreferredPrice = async (id) => {
        const deliveryDate = new Date(Parentformik.values.headerData.when);
        const instance = networkManager(API.ORDER.PREFERREDPRICE(`${id}`));
        const response = await instance.request();

        if (response.success) {
            const isCustomerExist = response.data;
            if (isCustomerExist.discount_value) {
                let startDate = null;
                let endDate = null;
                if (isCustomerExist.start_date) {
                    startDate = new Date(convertToLocal(isCustomerExist.start_date));
                }
                if (isCustomerExist.end_date) {
                    endDate = new Date(convertToLocal(isCustomerExist.end_date));
                }
                if (startDate && new Date() >= startDate && !endDate) {
                    // means no end date and delivery date is greater than start date
                    Parentformik.setFieldValue("headerData.preferredDiscountType", isCustomerExist.discount_value_type);
                    Parentformik.setFieldValue("headerData.preferredDiscountValue", Number(isCustomerExist.discount_value));
                    preferrefPriceRef.current = Number(isCustomerExist.discount_value);
                } else if (startDate && endDate && new Date() > startDate && new Date() < endDate) {
                    // console.log("HIIIyes");
                    // means  delivery date is greater than start date and less than end date
                    Parentformik.setFieldValue("headerData.preferredDiscountType", isCustomerExist.discount_value_type);
                    Parentformik.setFieldValue("headerData.preferredDiscountValue", Number(isCustomerExist.discount_value));
                    preferrefPriceRef.current = Number(isCustomerExist.discount_value);
                }
            } else {
                Parentformik.setFieldValue("headerData.preferredDiscountType", "DISCOUNT");
                Parentformik.setFieldValue("headerData.preferredDiscountValue", 0);
                preferrefPriceRef.current = Number(isCustomerExist.discount_value);
            }
        }
    };

    useEffect(() => {
        const user = headerData?.customer?.userId;
        if (user && headerData.when !== "") {
            getCustomerPreferredPrice(user);
        }
    }, [headerData?.customer?.userId, headerData.when]);

    useEffect(() => {
        if (customerId) {
            getCustomerProfileData(customerId);
        }
    }, [customerId]);

    return (
        <>
            <Stack
                nowrap
                sx={{minWidth: 0, ml: {xs: 2, md: 2, lg: 0}, cursor: "pointer", flexShrink: 20}}
                spacing={0.5}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                className="muiStack_start"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Typography variant="OrderSectionHeading" sx={{whiteSpace: "nowrap"}}>
                    Customer
                </Typography>

                <Stack direction="row" spacing={1} mt="0px !important" alignItems="center">
                    <Typography
                        noWrap
                        variant="OrderSectionHeading"
                        fontWeight={400}
                        // {...(!headerData.customer?.name && {sx: (t) => ({color: t.palette.error.light})})}
                    >
                        {headerData.customer?.name || "Add customer"}
                    </Typography>
                    {(orderStatus === "Draft" ||
                        orderStatus === "Quote Sent" ||
                        orderStatus === "" ||
                        orderStatus === "QUOTE_REJECTED") && <KeyboardArrowDownIcon fontSize="small" />}
                </Stack>
                <ErrorMessage name="headerData.customer.userId" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
            </Stack>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                onKeyDown={(e) => e.stopPropagation()}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button"
                }}
                PaperProps={{
                    sx: {
                        "&.MuiPaper-root": {
                            overflowX: "visible",
                            overflowY: "visible"
                        }
                    }
                }}
            >
                {openForm ? (
                    <AddNewCustomerForm handleClose={handleClose} Parentformik={Parentformik} />
                ) : (
                    <CustomerForm Parentformik={Parentformik} handleClose={handleClose} customerName={customerName} customerId={customerId}>
                        {WithPermission({module: "Customers", permission: ["create_permission"]}) && (
                            <Box onClick={() => setOpenForm(!openForm)} sx={{maxWidth: "170px"}}>
                                <SymbolButton title="Add new customer" />
                            </Box>
                        )}
                    </CustomerForm>
                )}
            </Menu>
        </>
    );
};

export default Customer;
