import {Grid, Typography, Box, Button} from "@mui/material";
import React, {useState, useEffect} from "react";
import AnimateButton from "ui-component/extended/AnimateButton";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import useAuth from "hooks/useAuth";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import PrintOrderModal from "commonComponent/DilogBox/PrintOrderModal";
import RefundForm from "../../CreateOrder/createOrderFooter/Refund";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewNotes from "views/pages/privatePages/customers/CreateCustomer/AddNewNoteForm";
import OrderNotes from "../../Notes/OrderNotes";
import CancellationSideout from "../../CancellationSideout";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const Rejected = (props) => {
    const {
        search,
        showFilter,
        handleEditOrder,
        handleReorder,
        openEditDialog,
        setSelectedCheckbox,
        updateListCount,
        selectedCheckbox,
        filterChips,
        setSearchedValue
    } = props;
    const [searchParams] = useSearchParams();
    const OrderId = searchParams.get("orderId");
    const {orderUpdate} = useAuth();

    const [open, setOpen] = React.useState(false);
    const [rejectedOrderListing, setRejectedOrderListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "desc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "created_at");
    const [isEdited, setEdited] = useState(false);
    const [priceParam, setPriceParam] = useState("customer_order");
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showHeading, setShowHeading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const [printOptionModal, setPrintOptionModal] = useState(false);

    const navigation = useNavigate();

    const {toggleDrawer, state} = useTempDrawer();

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    React.useEffect(() => {
        if (OrderId) {
            // if user comes from request edit email template
            openEditDialog(OrderId);
        }
    }, [OrderId]);

    const handleStatus = () => {};

    useEffect(() => {
        setSelectedCheckbox([]);
    }, []);

    const getRejectedOrderList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.GET);
        const params = {
            offset: currentPage,
            status: "Rejected",
            limit: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (moreFilters && moreFilters.guest_count) {
            if (moreFilters.guestFilter === "equal" || moreFilters.guestFilter === undefined) {
                params.guest_count = moreFilters.guest_count;
            } else {
                delete params.guest_count;
                params[`guest_count${moreFilters.guestFilter}`] = moreFilters.guest_count;
            }
            delete params.guestFilter;
        }
        if (moreFilters && moreFilters.total_amount) {
            if (moreFilters.amountFilter === "equal" || moreFilters.amountFilter === undefined) {
                params.total_amount = moreFilters.total_amount;
            } else {
                delete params.total_amount;
                params[`total_amount${moreFilters.amountFilter}`] = moreFilters.total_amount;
            }
            delete params.amountFilter;
        }
        if (moreFilters?.charged_by) {
            params.charged_by = moreFilters?.charged_by.join(",");
        }

        if (currentOrderBy) {
            params.sort_by = currentOrderBy;
            params.sort = currentOrder;
        }

        if (search === "") {
            params.offset = currentPage;
        }
        if (search !== "") {
            params.search = search;
            setRejectedOrderListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setRejectedOrderListing(response.data.orderList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
        setSearchedValue({});
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
        toggleDrawer("right", false)();
    };

    const handlePrintModal = () => {
        setPrintOptionModal((pre) => !pre);
    };

    const handlePrintDownload = (price) => {
        handlePrintModal();
        window.open(`/print?orderId=${selectedRow.order_id}&price=${price}`, "_blank", "noreferrer");
    };

    const handleSendReceipt = async (row) => {
        const instance = NetworkManager(API.ORDER.QUOTE);
        const payload = {
            to_email: row.customer.email,
            body: "Hey there this is you new order",
            subject: "New order created",
            order_id: row.order_id,
            url: `${process.env.REACT_APP_CUSTOMER_URL}receipt?orderId=${row.order_id}`,
            send_receipt: true
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Order receipt sent successfully",
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Issue refund": {
                toggleDrawer("right", "refund")(e);
                break;
            }
            case "Edit": {
                handleEditOrder(row.order_id);
                break;
            }
            case "Download": {
                window.open(`/print?orderId=${row.order_id}&price=customer_order`, "_blank", "noreferrer");
                break;
            }
            case "Re-order": {
                handleReorder(row.order_id);
                break;
            }
            case "Delete draft": {
                handleDeleteModal();
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            case "Print": {
                handlePrintModal(row);
                break;
            }
            case "Send receipt": {
                handleSendReceipt(row);
                break;
            }
            case "Add note": {
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            case "Cancelation fee": {
                toggleDrawer("right", "cancelationFee")();
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ORDER.DELETE(selectedRow.order_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getRejectedOrderList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };
    const handleSort = (val1, val2) => {
        if (val2 === "payment_by") {
            dispatch(setOrder(val1))
            dispatch(setOrderBy("charged_by"))
        } else if (val2 === "kitchen_location.market.market_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1))
            dispatch(setOrderBy(splitText[2]));
        } else if (val2 === "order.status" || val2 === "brand.brand_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1))
            dispatch(setOrderBy(splitText[1]));
        } else if (val2 === "customer") {
            const filterText = `${val2}_name`;
            dispatch(setOrder(val1))
            dispatch(setOrderBy(filterText))
        } else {
            dispatch(setOrder(val1))
            dispatch(setOrderBy(val2))
        }
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getRejectedOrderList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, orderUpdate, updateListCount]);

    const getMarketList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.marketList.forEach((el) => {
                tempData.push({id: el.market_id, label: el.market_name});
            });
        }
        return tempData;
    };

    const getBrandList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.brandList.forEach((el) => {
                tempData.push({id: el.brand_id, label: el.brand_name});
            });
        }
        return tempData;
    };

    const filterState = [
        {
            key: "search",
            label: "Order id",
            type: "textfield"
        },
        {
            key: "start_date",
            label: "Created from",
            type: "startDate"
        },
        {
            key: "end_date",
            label: "Created to",
            type: "endDate"
        },
        {
            key: "delivery_start_date",
            label: "Delivery from",
            type: "startDate"
        },
        {
            key: "delivery_end_date",
            label: "Delivery to",
            type: "endDate"
        },
        {
            key: "customer_name",
            label: "Customer",
            type: "textfield"
        },
        {
            key: "delivery_address",
            label: "Address",
            type: "textfield"
        },
        {
            key: "source",
            label: "Source",
            type: "multiselect",
            getData: () => [
                {id: "ADMIN", label: "Direct order"},
                {id: "Customer", label: "Marketplace"},
                {id: "EZcater", label: "EZcater"},
                {id: "EZorder", label: "EZorder"}
            ]
        },
        {
            key: "service_type",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "market_id",
            label: "Market",
            type: "multiselect",
            getData: getMarketList
        },
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: getBrandList
        },
        {
            key: "guest_count",
            label: "Guests",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "guestFilter"
        },
        {
            key: "total_amount",
            label: "Total",
            type: "numberfieldWithDecimal",
            filterType: "Equals",
            filterKey: "amountFilter"
        },
        {
            key: "status",
            label: "Status",
            type: "select",
            getData: () => [{id: "Rejected", label: "Rejected"}]
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        dispatch(setPageNumber(1));
        // setFilterChips(Morefilters);
    };

    return (
        <>
            {showFilter ? (
                <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    // setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                    sideBarStateName="orderFilter"
                />
            ) : null}
            {state.right === "refund" && (
                <RefundForm
                    handleSelectedRow={handleSelectedRow}
                    customerId={selectedRow?.customer?.user_id}
                    orderId={selectedRow?.order_id}
                    orderNumber={selectedRow?.order_number}
                    refundMethod={selectedRow?.charged_by}
                    orderData={selectedRow}
                />
            )}

            {state.right === "addNewNotes" && (
                <OrderNotes
                    userID={selectedRow?.customer?.user_id}
                    selectedData={selectedRow}
                    handleIsEdited={handleIsEdited}
                    handleSelectedRow={handleSelectedRow}
                    // setSelectedRow={setSelectedRow}
                />
            )}
            <Grid container>
                <Grid item xs={12}>
                    {/* <FilterChips
                        filterChips={filterChips}
                        // setFilterChips={setFilterChips}
                    /> */}
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={rejectedOrderListing}
                        checkbox
                        header={[
                            {key: "order_number", label: "Order #"},
                            {key: "created_at", label: "Created"},
                            {key: "delivery_date", label: "Delivery"},
                            {key: "customer", label: "Customer"},
                            {key: "delivery_address", label: "Address"},
                            {key: "service_type", label: "Service"},
                            {key: "kitchen_location.market.market_name", label: "Market"},
                            {key: "brand.brand_name", label: "Brand"},
                            {key: "total_price", label: "Total"},

                            // { key: "charge_fee_amount", label: "Cancelation fee" },
                            {key: "order.status", label: "Status"},
                            {key: "action", label: "Action"}
                        ]}
                        height="75vh"
                        actions={[]}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        sortingLabel={[
                            "Order #",
                            "Created",
                            "Delivery",
                            "Total",
                            "Brand",
                            "Customer",
                            "Status",
                            "Service",
                            "Payment",
                            "Market"
                        ]}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>
            <PrintOrderModal
                open={printOptionModal}
                buttonTitle="Print order"
                title="Print order"
                description="Select the type of order you would like to print"
                handleClose={handlePrintModal}
                handleSubmit={(price) => handlePrintDownload(price)}
                noOnClose
                selected={priceParam}
                onSelect={(price) => {
                    setPriceParam(price);
                    setShowHeading(price === "price");
                }}
            />

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this order?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
            {state.right === "cancelationFee" && (
                <CancellationSideout
                    userID={selectedRow?.customer?.user_id}
                    selectedData={selectedRow}
                    handleIsEdited={handleIsEdited}
                    handleSelectedRow={handleSelectedRow}
                    setSelectedRow={setSelectedRow}
                />
            )}
        </>
    );
};

export default Rejected;
