// eslint-disable react/jsx-no-bind*

import {Box, Stack, Typography, Grid} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import {useSearchParams, useNavigate} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import useToggleDilog from "hooks/useToggleDilog";
import CommonTable from "commonComponent/TableSection/CommonTable";
import Loader from "ui-component/Loader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Bold from "assets/images/icons/Bold.svg";
import Italic from "assets/images/icons/italic.svg";
import underline from "assets/images/icons/underline.svg";
import justify from "assets/images/icons/Justify.svg";
import right from "assets/images/icons/alignRight.svg";
import center from "assets/images/icons/Center.svg";
import bullet from "assets/images/icons/Bullet.svg";
import numbered from "assets/images/icons/Numbered.svg";
import styles from "../createOrder.module.scss";
import Info from "assets/images/icons/Info.svg";
import Image from "assets/images/icons/Image.svg";
import Indent from "assets/images/icons/Indet.svg";
import Outdent from "assets/images/icons/Outdent.svg";
import left from "assets/images/icons/alignLeft.svg";
import CustomNewDateTimePicker from "commonComponent/newDateAndTimePicker/CustomNewDateTimePicker";

const icons = ReactQuill.Quill.import("ui/icons");
icons.bold = `<img src=${Bold} alt=""/>`;
icons.italic = `<img src=${Italic} alt=""/>`;
icons.underline = `<img src=${underline} alt=""/>`;
icons.align[""] = `<img src=${left} alt=""/>`;
icons.align.center = `<img src=${center} alt=""/>`;
icons.align.justify = `<img src=${justify} alt=""/>`;
icons.align.right = `<img src=${right} alt=""/>`;
icons.list.bullet = `<img src=${bullet} alt=""/>`;
icons.list.ordered = `<img src=${numbered} alt=""/>`;
icons.image = `<img src=${Image} alt=""/>`;
icons.indent["+1"] = `<img src=${Indent} alt=""/>`;
icons.indent["-1"] = `<img src=${Outdent} alt=""/>`;

const fontSizeArr = ["12px", "14px", "18px", "24px"];
const Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
ReactQuill.Quill.register(Size, true);
const SendQuoteForm = (props) => {
    const {
        handleSelectedRow = () => {},
        selectedCheckbox = null,
        setSelectedCheckbox = () => {},
        customerId,
        userId = null,
        orderId = null,
        buttonTitle = "Send Quote",
        brandName = null,
        brandId = null,
        showMultipleQuote,
        formik,
        handleCloseMultipleQuote = () => {},
        setExpiryDateTime = () => {},
        expiryDateTime,
        handleEditExpirationTime,
        expiryQuote,
        singleQuoteData
    } = props;

    console.log("values: ", formik);
    const quillRef = useRef(null);
    const [searchparam] = useSearchParams();
    const [orderList, setOrderList] = useState(null);
    const {toggleDrawer} = useTempDrawer();
    const {toggleDilog} = useToggleDilog();
    const [brand, setBrand] = useState("");
    const navigate = useNavigate();
    const orderedId = searchparam.get("orderId");
    // const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    // CHECK EXPIRATION TIME
    const [selectedDateTime, setSelectedDateTime] = useState(() => {
        const currentTime = new Date().toISOString();
        if (formik?.values?.cutOffTime && formik?.values?.cutOffTime > currentTime) {
            setExpiryDateTime(formik?.values?.cutOffTime);
            return formik.values.cutOffTime;
        } else if (formik?.values?.quote_expiry_time && formik?.values?.quote_expiry_time > currentTime) {
            setExpiryDateTime(formik?.values?.quote_expiry_time);
            return formik.values.quote_expiry_time;
        } else if (singleQuoteData) {
            return singleQuoteData[0]?.quote_expiry_time;
        } else return "";
    });

    const [multiDateTime, setMultiDateTime] = useState(new Set());

    useLayoutEffect(() => {
        if (localStorage.getItem("quote") === "" || localStorage.getItem("quote") === null) {
            setText("");
        } else {
            setText(localStorage.getItem("quote"));
        }
    }, []);
    const getBrandDetail = async (id) => {
        const instance = NetworkManager(API.BRAND.GETBYID(id));

        const response = await instance.request();

        if (response.success) {
            const data = response.data;
            const bd = data?.brand;
            setBrand(bd.brand_name);
        }
    };

    const fetchMultipleCutoffTime = async () => {
        if (!selectedCheckbox || selectedCheckbox.length === 0) {
            return;
        }
        setLoading(true);

        // Create an instance of your network request
        const instance = NetworkManager(API.ORDER.MULTIPLECUTOFFTIME);

        // Extract the order IDs from selectedCheckbox
        const orderIds = selectedCheckbox.map((row) => JSON.parse(row).order_id);
        const params = {
            orderIds: orderIds.join(",")
        };

        // Make the network request
        const response = await instance.request({}, params);

        if (response.success) {
            const cutOffTimeArray = response?.data?.cutoff_time;
            // console.log("cutOffTime :", cutOffTimeArray);

            // Create a map of cutOffTimeArray based on order_id
            const cutOffTimeMap = cutOffTimeArray.reduce((acc, item) => {
                acc[item.order_id] = item.cutOffTime;
                return acc;
            }, {});

            // Update the selectedCheckbox order list with matched cutoff times
            const updatedOrderList = selectedCheckbox.map((row) => {
                const parsedRow = JSON.parse(row);

                // Find the cutoff time using the order_id
                parsedRow.quote_expiry_time = cutOffTimeMap[parsedRow.order_id] || "";

                return parsedRow;
            });

            // Update the state with the new order list
            setOrderList(updatedOrderList);

            // Extract order IDs from the updated order list to update multiDateTime
            const idArray = updatedOrderList.map((order) => order.order_id);
            setMultiDateTime(new Set(idArray));

            setLoading(false);
        }
        // else {
        //     // Handle the error case by showing a snackbar message
        //     dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: response.message || "Failed to fetch cutoff time",
        //             variant: "alert",
        //             alert: {severity: "error", color: "error"},
        //             close: false
        //         })
        //     );
        //     setLoading(false);
        // }
    };

    useEffect(() => {
        fetchMultipleCutoffTime();
    }, [selectedCheckbox, dispatch]);

    React.useEffect(() => {
        if (!brandName) {
            getBrandDetail(brandId);
        } else {
            setBrand(brandName);
        }
    }, [brandName]);

    React.useEffect(() => {
        if (showMultipleQuote && selectedCheckbox) {
            const temp = [];
            selectedCheckbox?.forEach((row) => {
                const parsedRow = JSON.parse(row);

                if (!parsedRow.order_name) {
                    parsedRow.order_name = "";
                }
                parsedRow.expirationDate = "";
                temp.push(parsedRow);
            });

            setOrderList(temp);
        }
    }, [showMultipleQuote, selectedCheckbox]);

    // const verifyCutOff = () => {
    //     return new Promise((resolve, reject) => {
    //         if (formik) {
    //             const instance = NetworkManager(API.ORDER.CUTOFFVERIFY);

    //             const payload = {
    //                 delivery_date: formik.values.headerData.when,
    //                 latitude: formik.values.headerData.latitude,
    //                 longitude: formik.values.headerData.longitude,
    //                 brand_id: formik.values.selectedBrand
    //             };
    //             resolve(instance.request(payload));
    //         } else {
    //             resolve({success: true});
    //         }

    //         // if (response.success) {
    //         //     resolve(response)
    //     });
    // };

    function modifyImage(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        const image = doc.querySelector("img");
        if (image) {
            image.setAttribute("style", "max-width: 560px; max-height: 560px; margin-top:12px;");
        }
        return doc.body.innerHTML;
    }
    const handleChange = (html) => {
        const value = html === "<p><br></p>" ? "" : modifyImage(html);
        setText(value);
    };

    const handleDateChange = (val, key, orderId) => {
        if (key === "single") {
            setSelectedDateTime(val.toISOString());
            setExpiryDateTime(val.toISOString());
        } else {
            const multipleTimeSet = new Set(multiDateTime);
            multipleTimeSet.add(orderId);
            setMultiDateTime(multipleTimeSet);

            setOrderList((prevList) =>
                prevList.map((order) => (order.order_id === orderId ? {...order, quote_expiry_time: val.toISOString()} : order))
            );
        }
    };

    const isAnyInfoEmptyOrNull = (data) => {
        /* eslint-disable no-console */
        /* eslint-disable no-restricted-syntax */
        const keysToCheck = [
            "order_number",
            "service_type",
            "order_type",
            "delivery_address",
            "delivery_time",
            "delivery_date",
            "no_of_guest",
            "total_price",
            "status",
            "order_id",
            "created_at",
            "order_by",
            "drive_time",
            "setup_time",
            "leave_by",
            "customer",
            "brand",
            "kitchen_location"
        ];
        for (const row of data) {
            for (const key of keysToCheck) {
                if (row[key] === null || row[key] === "") {
                    return true; // Return true if any property is null or empty
                }
                if (key === "total_price" && row[key] <= row?.preferred_price + row?.sales_tax + row?.service_fee_value) {
                    return true; // Return true if total_price is not greater than 0
                }
            }
        }
        return false; // If no empty or null values found
    };

    const handleMultipleQuote = async (e) => {
        // setSubmitting(true);
        if (isAnyInfoEmptyOrNull(orderList)) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Information is missing",
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            return;
        }

        if (orderList.length === 0) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "No orders are selected",
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            return;
        }
        setLoading(true);
        const instance = NetworkManager(API.ORDER.MULTIPLEQUOTE);
        const orderIds = orderList.map((row) => row.order_id);
        const orderExpirationData = orderList.map((row) => ({
            order_id: row.order_id,
            expiry_time: row.quote_expiry_time || null
        }));
        const strOrderIds = orderIds.join(",");
        const payload = {
            to_email: customerId,
            body: text,
            subject: "Order quote",
            order_ids: orderExpirationData,
            url: `${process.env.REACT_APP_CUSTOMER_URL}quote?orderId=${orderId?.order_id}&userId=${userId}&orderIds=${strOrderIds}&multiquote=true`,
            status: "quote"
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleCloseMultipleQuote("success");
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        setLoading(false);
    };

    const handleAddQuote = async (e) => {
        // setSubmitting(true);
        setLoading(true);
        const instance = NetworkManager(API.ORDER.QUOTE);
        const payload = {
            to_email: customerId,
            body: text,
            subject: `Your ${brand} Quote For Order #${orderId?.order_number}`,
            order_id: orderId.order_id,
            url: `${process.env.REACT_APP_CUSTOMER_URL}quote?orderId=${orderId?.order_id}&userId=${userId}`,
            expiry_time: selectedDateTime
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            localStorage.removeItem("quote");
            setLoading(false);
            toggleDrawer("right", false)(e);
            toggleDilog(false)();
            navigate("/orders?tab=quotes");
            if (orderedId) {
                setTimeout(() => navigate(-1), 100);
            }
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            setLoading(false);
        }
    };

    const handleImageInserted = async (file) => {
        try {
            const instance = NetworkManager(API.IMAGE.ADD);
            const formData = new FormData();
            formData.append("image", file);

            const response = await instance.request(formData);
            const quill = quillRef.current?.getEditor();
            const range = quill?.getSelection();
            quill.insertEmbed(range.index, "image", response.data.url);
        } catch (error) {
            console.error("Error handling image insertion:", error);
        }
    };

    const openImageFileDialog = () => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64String = e.target.result.split(",")[1];
                    handleImageInserted(`data:${file.type};base64,${base64String}`);
                };
                reader.readAsDataURL(file); // Read the file as a data URL
            }
        };
        input.click();
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    ["bold", "italic", "underline"],
                    [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
                    [{list: "ordered"}, {list: "bullet"}],
                    [{size: fontSizeArr}],
                    [{indent: "-1"}, {indent: "+1"}],
                    ["image"]
                ],
                handlers: {
                    image: openImageFileDialog
                }
            }
        }),
        []
    );

    const previewEmailPost = async (e) => {
        setLoading(true);
        const instance = NetworkManager(API.ORDER.PREVIEW);
        const payload = {
            body: text,
            subject: `Your ${brand} Quote For Order #${orderId?.order_number}`,
            order_id: orderId.order_id,
            url: `${process.env.REACT_APP_CUSTOMER_URL}quote?orderId=${orderId?.order_id}&userId=${userId}`,
            expiry_time: selectedDateTime
        };
        const response = await instance.request(payload);
        if (response?.success) {
            const newWindow = window.open(`/quote/detail?tab=email&orderId=${orderId.order_id}`, "_blank", "noreferrer");

            if (newWindow) {
                // Access it using its variable
                newWindow.data_string = response?.data?.html;
            } else {
                console.error("Failed to open new window, possibly blocked by a pop-up blocker.");
            }

            localStorage.setItem("dataString", response?.data?.html);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const handleMultiplePreview = async () => {
        if (isAnyInfoEmptyOrNull(orderList)) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Information is missing",
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            return;
        }
        if (orderList.length === 0) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "No orders are selected",
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
            return;
        }
        setLoading(true);
        const instance = NetworkManager(API.ORDER.MULTIPLEQUOTE);
        const orderIds = orderList.map((row) => row.order_id);
        const strOrderIds = orderIds.join(",");
        const orderExpirationData = orderList.map((row) => {
            return {
                order_id: row.order_id,
                expiry_time: row.quote_expiry_time || null
            };
        });

        const payload = {
            to_email: customerId,
            body: text,
            subject: "Order quote",
            order_ids: orderExpirationData,
            url: `${process.env.REACT_APP_CUSTOMER_URL}quote?orderId=${orderId?.order_id}&userId=${userId}&orderIds=${strOrderIds}&multiquote=true`,
            status: "preview"
        };

        const response = await instance.request(payload);

        if (response?.success) {
            // Save orderIds to localStorage before attempting to open the window
            localStorage.setItem("orderIds", strOrderIds);

            const newWindow = window.open(`/quote/detail?tab=email&orderId=${orderId.order_id}&multiquote=true`, "_blank", "noreferrer");
            localStorage.setItem("dataString", response?.data?.html);

            if (newWindow) {
                // Window was successfully opened
                newWindow.data_string = response?.data?.html;
            }
            // else {
            //     // Window could not be opened (likely blocked by a popup blocker)
            //     console.error("New window could not be opened. It might have been blocked by the browser.");
            //     dispatch(
            //         openSnackbar({
            //             open: true,
            //             message: "Failed to open the preview. Please disable popup blockers and try again.",
            //             variant: "alert",
            //             alert: {
            //                 severity: "error",
            //                 color: "error"
            //             },
            //             close: false
            //         })
            //     );
            // }
        }

        setLoading(false);
    };

    const handleDelete = (type, row) => {
        const newSelectedItem = selectedCheckbox.filter((e) => {
            const item = JSON.parse(e);
            return item.order_id !== row.order_id;
        });

        setSelectedCheckbox(newSelectedItem);

        if (newSelectedItem.length === 0) {
            handleCloseMultipleQuote("success");
        }
    };

    return (
        <>
            <TemporaryDrawer
                sideBarStateName="SendQuote"
                sx={{zIndex: 1500, maxWidth: "430px", pb: "1rem"}}
                handleSelectedRow={handleSelectedRow}
                whileCloseDoThis={handleCloseMultipleQuote}
                text={text}
                // resetForm={formik.resetForm}
                // touched={formik.touched}
            >
                {loading && <Loader />}
                <Stack sx={{height: "100%"}} mt={-2}>
                    <PageHeader
                        title={showMultipleQuote ? "Send multiple quotes" : expiryQuote ? "Update quote expiration" : "Send quote"}
                        fontSize="1.625rem"
                        showOnlyTitle
                    />
                    {showMultipleQuote && orderList && (
                        <Grid container>
                            <Grid item xs={12}>
                                <CommonTable
                                    data={orderList}
                                    header={[
                                        {key: "quote_order_number", label: "Order #"},
                                        {key: "quote_delivery_date", label: "Delivery date"},
                                        {key: "quote_brand.brand_name", label: "Brand"}
                                    ]}
                                    headerShow={false}
                                    leftSpace={false}
                                    tableCellWidth="80px"
                                    height="75vh"
                                    // actions={[]}

                                    handleActionBtn={handleDelete}
                                    multipleQuote
                                    handleDateChange={handleDateChange}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {!showMultipleQuote && !orderList && (
                        <>
                            <CommonTable
                                data={singleQuoteData}
                                header={[
                                    {key: "quote_order_number", label: "Order #"},
                                    {key: "quote_delivery_date", label: "Delivery date"},
                                    {key: "quote_brand.brand_name", label: "Brand"}
                                ]}
                                headerShow={false}
                                leftSpace={false}
                                tableCellWidth="80px"
                                height="75vh"
                                // actions={[]}
                                handleActionBtn={handleDelete}
                                singleQuote
                                handleDateChange={handleDateChange}
                            />
                            <Box sx={{marginBottom: "15px", marginTop: "15px"}}>
                                <CustomNewDateTimePicker
                                    label="Quote expiration"
                                    value={selectedDateTime}
                                    onChange={(val) => handleDateChange(val, "single")}
                                    disablePast
                                    maxDate={new Date(formik?.values?.headerData?.when)}
                                    createdAt
                                />
                            </Box>
                        </>
                    )}
                    <Box mt={2} sx={{height: "100%"}}>
                        {!expiryQuote && (
                            <Box sx={{marginBottom: "150px"}}>
                                <ReactQuill
                                    ref={quillRef}
                                    value={text}
                                    onChange={handleChange}
                                    placeholder="Do you have a personalized message for the customer?"
                                    modules={modules}
                                />
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                position: "fixed",
                                justifyContent: "center",
                                alignItems: "center",
                                bottom: 0,
                                p: 0.5,
                                pb: "1rem",
                                bgcolor: "#fff",
                                zIndex: 1
                                // width: "min-content"
                            }}
                        >
                            {" "}
                            {!expiryQuote && (
                                <Box sx={{pb: 2, display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                    <img src={Info} alt="" />
                                    <Typography className={styles.subText}>
                                        Preview will open the quote in a new browser tab consistent with the customer view.
                                    </Typography>
                                </Box>
                            )}
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({color: t.palette.error.dark, fontFamily: "Inter"}),
                                        onClick: (e) => {
                                            toggleDrawer("right", false)(e);
                                            localStorage.setItem("quote", text);
                                            handleCloseMultipleQuote();
                                        }
                                    },
                                    !expiryQuote && {
                                        btnTitle: "Preview",
                                        variant: "outlined",
                                        disabled:
                                            (showMultipleQuote && orderList?.length !== multiDateTime.size) ||
                                            text === "" ||
                                            (!selectedDateTime && !showMultipleQuote),
                                        sx: () => ({color: "#00ab3a", width: "110px"}),
                                        onClick: (e) => {
                                            if (showMultipleQuote && selectedCheckbox) {
                                                handleMultiplePreview(e);
                                            } else {
                                                previewEmailPost(e);
                                            }
                                        }
                                    },
                                    !expiryQuote
                                        ? {
                                              btnTitle: buttonTitle,
                                              variant: "contained",
                                              disabled:
                                                  (showMultipleQuote && orderList?.length !== multiDateTime.size) ||
                                                  text === "" ||
                                                  (!selectedDateTime && !showMultipleQuote),
                                              sx: (t) => ({color: t.palette.background.paper, width: "133px"}),
                                              onClick: (e) => {
                                                  setLoading(true);
                                                  if (showMultipleQuote && selectedCheckbox) {
                                                      handleMultipleQuote(e);
                                                  } else {
                                                      handleAddQuote(e);
                                                  }
                                              }
                                          }
                                        : {
                                              btnTitle: "Update",
                                              variant: "contained",
                                              disabled: !selectedDateTime,
                                              sx: (t) => ({color: t.palette.background.paper, width: "133px"}),
                                              onClick: (e) => {
                                                  setLoading(true);
                                                  handleEditExpirationTime(orderId?.order_id, selectedDateTime);
                                              }
                                          }
                                ].filter(Boolean)} // filter out null values
                            />
                        </Box>
                    </Box>
                    )
                </Stack>
            </TemporaryDrawer>
        </>
    );
};

export default SendQuoteForm;
