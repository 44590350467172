import {API, NetworkManager} from "network/core";

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export const twoDigitAfterDecimal = (val) => {
    if (val !== undefined) {
        return patternTwoDigisAfterComma.test(val);
    }
    return true;
};

const isNumericInput = (event) => {
    const key = event.keyCode || event.which;
    return (
        (key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
};

const isModifierKey = (event) => {
    const key = event.keyCode || event.which;
    return (
        event.shiftKey ||
        key === 35 ||
        key === 36 || // Allow Home, End
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 46 || // Allow Backspace, Tab, Enter, Delete
        (key >= 37 && key <= 40) || // Allow arrow keys
        // Allow Ctrl/Command + A,C,V,X,Z
        ((event.ctrlKey || event.metaKey) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
};
// onKeyDown
export const enforceFormat = (event) => {
    const key = event.keyCode || event.which;
    if (event.repeat) {
        event.preventDefault();
    }
    // Prevent the input of a period (.) or comma (,)
    if (key === 110 || key === 190 || key === 188) {
        event.preventDefault();
    }

    // Prevent input if shift key is pressed (except for allowed modifier keys)
    if (event.shiftKey && key >= 48 && key <= 57) {
        event.preventDefault();
    }

    // Allow only numeric input and modifier keys
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
    }

    // Prevent default action on Tab key
    if (event.key === "Tab") {
        event.stopPropagation();
    }
};

export const enforceFormatHandleDecimal = (event) => {
    const key = event.keyCode || event.which;
    if (event.repeat) {
        event.preventDefault();
    }
    // Prevent the input of a comma (,)
    if (key === 188) {
        event.preventDefault();
    }
 
    // Prevent input if shift key is pressed (except for allowed modifier keys)
    if (event.shiftKey && key >= 48 && key <= 57) {
        event.preventDefault();
    }
 
    // Allow only numeric input and modifier keys
    if (!isNumericInput(event) && !isModifierKey(event)) {
        // If it's a period (.), ensure it's only allowed once in the input
        if (key === 190 || key === 110) {
            const inputValue = event.target.value;
            if (inputValue.includes(".")) {
                event.preventDefault();
            }
        } else {
            event.preventDefault();
        }
    }
 
    // Prevent default action on Tab key
    if (event.key === "Tab") {
        event.stopPropagation();
    }
};

export const enforceFormat2 = (event) => {
    if (event.shiftKey && event.which >= 48 && event.which <= 90) {
        event.preventDefault();
    }
    if (!isNumericInput(event) && !isModifierKey(event)) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        event.preventDefault();
    }
    if (event.key === ".") {
        event.preventDefault();
    }
    if (event.key === "Tab") {
        event.stopPropagation();
    }
};
export const enforceFormatFor1Digits = (e) => {
    const value = e.target.value;

    // Regular expression to enforce the desired format
    const regex = /^-?\d{0,3}(\.\d{0,1})?$/;

    // Check if the input matches the regex pattern or is empty
    if (regex.test(value) || value === "") {
        return true;
    }
    return false; // eslint-disable-line
};

export const validateInputForPercentage = (input) => {
    // Check if input is a valid number with at most 2 decimal points
    const regex = /^-?\d{0,3}(\.\d{0,2})?$/;
    // Check if input is less than or equal to 100
    const number = parseFloat(input);
    if (!Number.isNaN(number) && regex.test(input) && number <= 100) {
        return true; // Input is valid
    } else if (input === "") {
        return true;
    } else {
        return false; // Input is not valid
    }
};

export const enforceFormatFor3Digits = (e) => {
    const value = e.target.value;

    // Regular expression to enforce the desired format
    const regex = /^-?\d{0,3}(\.\d{0,2})?$/;

    // Check if the input matches the regex pattern or is empty
    if (regex.test(value) || value === "") {
        return true;
    }
    return false; // eslint-disable-line
};

export const enforceFormatFor5Digits = (e) => {
    const value = e.target.value;

    // Regular expression to enforce the desired format
    const regex = /^-?\d{0,5}(\.\d{0,2})?$/;

    // Check if the input matches the regex pattern or is empty
    if (regex.test(value) || value === "") {
        return true;
    }
    return false; // eslint-disable-line
};

export const enforceNumericAndMaxLength = (value, maxLength) => (e) => {
    if (isModifierKey(e)) {
        return;
    }
    if (e.key.length === 1 && (!/[0-9]/.test(e.key) || value.length >= maxLength)) {
        e.preventDefault();
    }
};

export const enforceDateAndMaxLength = (value, maxLength) => (e) => {
    if (isModifierKey(e)) {
        return;
    }
    if ((e.key.length === 1 && !/[0-9/]/.test(e.key)) || (value.length >= maxLength && e.key !== "Backspace")) {
        e.preventDefault();
    }
};

// only number with decimal point
export const enforceFormatForNumber = (event) => {
    if (event.shiftKey && event.which >= 48 && event.which <= 90) {
        event.preventDefault();
    }
    if (event.key === ".") {
        event.preventDefault();
    }
    if (!isNumericInput(event) && !isModifierKey(event)) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        event.preventDefault();
    }
};

export const commaFormattedNumber = (number) => {
    // Remove non-digit characters
    const cleanedValue = JSON.stringify(number)?.replace(/[^\d]/g, "");
    // Format the number with commas
    const formattedNumber = Number(cleanedValue)?.toLocaleString("en-US") ?? "";
    return formattedNumber;
};

export const enforceFormatForAlphabet = (event) => {
    const forbiddenChars = ["@", "#", "$", "%", "^", "&", "*", "(", ")", "}", "{", "[", "]", ".", "_", "-", "=", "+", "`", "~", "!", "|"];
    if (forbiddenChars.includes(event.key)) {
        event.preventDefault();
    }
    if (event.shiftKey && event.which >= 48 && event.which <= 90) {
        event.preventDefault();
    }
    if (event.key === ".") {
        event.preventDefault();
    }
    if (isNumericInput(event)) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        event.preventDefault();
    }
};

export const onlyNumbersWithOutDecimal = (event) => {
    const key = event.keyCode;
    if (key === 110 || key === 190) {
        event.preventDefault();
    } else {
        enforceFormat(event);
    }
};

export const convertToInteger = (number) => {
    const intValue = parseInt(number, 10);
    return intValue || "-";
};

// onKeyUp
export const formatToPhone = (event) => {
    if (isModifierKey(event)) {
        return;
    }

    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input?.length > 6) {
        event.target.value = `(${areaCode}) ${middle}-${last}`;
    } else if (input?.length > 3) {
        event.target.value = `(${areaCode}) ${middle}`;
    } else if (input?.length > 0) {
        event.target.value = `(${areaCode}`;
    }
};
export const handleBlur = (event) => {
    const input = event.target.value.replace(/\D/g, "").substring(0, 10);
    event.target.value = input;
};
// value
export const formatFetchedPhoneNumber = (val) => {
    let finalVlaue = "";
    const areaCode = val.substring(0, 3);
    const middle = val.substring(3, 6);
    const last = val.substring(6, 10);

    if (val?.length > 6) {
        finalVlaue = `(${areaCode}) ${middle}-${last}`;
    } else if (val?.length > 3) {
        finalVlaue = `(${areaCode}) ${middle}`;
    } else if (val?.length > 0) {
        finalVlaue = `(${areaCode}`;
    }
    return finalVlaue;
};

// getFormatedUrlForFilter
export const generateUrl = (params) => {
    let url = "";
    Object.keys(params).forEach((el) => {
        if (el !== "isCreatedAt") {
            if (Array.isArray(params[el]) && params[el].length) {
                if (
                    el === "cusine" ||
                    el === "market" ||
                    el === "services" ||
                    el === "service_name" ||
                    el === "brand_id" ||
                    el === "brand_name" ||
                    el === "market_name" ||
                    el === "market_id" ||
                    el === "service_type" ||
                    el === "source" ||
                    el === "status" ||
                    el === "templateIds" ||
                    el === "menuIds" ||
                    el === "brandIds" ||
                    el === "services" ||
                    el === "rating"
                ) {
                    if (url === "") {
                        url += `?${el}=${params[el]}`;
                    } else {
                        url += `&${el}=${params[el]}`;
                    }
                } else if (url === "") {
                    url += `?${el}=[${params[el]}]`;
                } else {
                    url += `&${el}=[${params[el]}]`;
                }
            } else if (!Array.isArray(params[el]) && params[el]) {
                const check = (el) => {
                    if (el === "active") {
                        return true;
                    }
                    if (el === "inactive") {
                        return true;
                    }

                    return false;
                };

                if (url === "") {
                    url += `?${el}=${check(params[el]) ? params[el] === "active" : params[el]}`;
                } else {
                    url += `&${el}=${check(params[el]) ? params[el] === "active" : params[el]}`;
                }
            }
        }
    });
    return url;
};

const formatCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",

    currency: "USD"
});

export default formatCurrency.format;

export const formatExpiryDate = (inputDate) => {
    if (!inputDate || !/\d/.test(inputDate)) {
        return inputDate;
    }

    const today = new Date();
    const currentYear = today.getFullYear() % 100;
    const currentMonth = today.getMonth() + 1;

    if (/^\d{2}$/.test(inputDate)) {
        const userMonth = parseInt(inputDate, 10);
        const formattedMonth = Math.min(Math.max(userMonth, 1), 12);
        return `${formattedMonth.toString().padStart(2, "0")}/`;
    }
    if (/^\d{2}\/\d{2}$/.test(inputDate)) {
        const [userMonth, userYear] = inputDate.split("/").map((str) => parseInt(str, 10));
        const formattedYear = Math.max(userYear, currentYear).toString().padStart(2, "0");
        const formattedMonth = userYear === currentYear ? Math.max(userMonth, 1) : userMonth;

        return `${formattedMonth.toString().padStart(2, "0")}/${formattedYear}`;
    }

    return inputDate;
};

export const checkforPerfectNumber = (val) => {
    const arr = val?.split(".");
    return val;
};

export const FormatCurrency = (val) => (val ? checkforPerfectNumber(formatCurrency.format(val)) : `$0.00`);

export const FormatPhoneNumber = (phoneNumber, key) => {
    if (phoneNumber) {
        // Remove any non-digit characters from the phone number
        const cleaned = phoneNumber.replace(/\D/g, "");

        // Use a regular expression to capture the parts of the phone number
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (!key) {
            if (match) {
                // Format the phone number with country code as +1 (XXX) XXX-XXXX
                return ` (${match[1]}) ${match[2]}-${match[3]}`;
            }
        } else if (key) {
            if (match) {
                // Format the phone number with country code as +1 (XXX) XXX-XXXX
                return ` (${match[1]}) ${match[2]}-${match[3]}`;
            }
        }

        // Return the original string if no match is found
        return phoneNumber;
    } else {
        return "";
    }
};
export const sortFunction = (array) => {
    return array.sort((a, b) => a.label.localeCompare(b.label));
};

export const getHeaderAddressObj = (values) => {
    const addressDetailsObject = values?.headerData?.address_details;
    const customerAddresses = values?.headerData?.customer?.customerAddresses;
    if (Object?.keys(addressDetailsObject)?.length === 0) {
        const selectedAddreess = customerAddresses.filter((item) => item?.address_id === values?.headerData?.address_id);
        const addressDetails = selectedAddreess[0]?.address_details;
        return {
            ...addressDetails,
            contact_name: values?.name,
            contact: values?.phone,
            location_name: values?.company,
            apartment_name: values?.apartment||""
        };
    } else {
        return {
            ...addressDetailsObject,
            contact_name: values?.name,
            contact: values?.phone,
            location_name: values?.company,
            apartment_name: values?.apartment||""
        };
    }
};

export const getManagers = async (setManagers, setLoading, search) => {
    setLoading(true);

    const instance = NetworkManager(API.SUBADMIN.LISTING);
    const params = {offset: 1, limit: "5000", status: "ACTIVATE", sortBy: "first_name", orderBy: "asc", page: 1};
    if (search) {
        params.search = search;
    }
    const url = generateUrl(params);

    const response = await instance.request({}, [url]);

    if (response.success) {
        const users = response?.data?.subAdmins;

        const requiredArray = users.map((user) => {
            return {
                label: `${user?.first_name} ${user?.last_name}`,
                value: user?.user_id,
                id: user?.user_id
            };
        });
        setManagers(requiredArray);
    }
    setLoading(false);
};

export const getSubAdminName = (manager) => {
    return manager ? `${manager?.first_name} ${manager?.last_name}` : "N/A";
};
export const getManagerName = (id, array) => {
    if (!id) {
        return "-";
    }
    const managerMap = new Map();
    array.forEach((item) => {
        managerMap.set(item?.id, `${item?.label}`);
    });
    return managerMap.get(id);
};

export const getUtensils = (order) => {
    const plates = order?.tableware_plates;
    const utensils = order?.serving_utensils;
    const napkins = order?.tableware_cutlery;
    let text;
    if (plates && utensils && napkins) {
        text = "Plates, cutlery with napkins and serving utensils.";
    } else if (plates && !utensils && napkins) {
        text = "Plates and cutlery with napkins.";
    } else if (plates && utensils && !napkins) {
        text = "Plates and serving utensils.";
    } else if (!plates && utensils && napkins) {
        text = "Cutlery with napkins and serving utensils.";
    } else if (plates && !utensils && !napkins) {
        text = "Plates";
    } else if (!plates && !utensils && napkins) {
        text = "Cutlery with napkins";
    } else if (!plates && utensils && !napkins) {
        text = "Serving utensils.";
    }
    return text;
};
