import {Grid} from "@mui/material";
import React, {useEffect, useLayoutEffect, useState} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CustomizedTabs from "commonComponent/NewTab";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useTempDrawer from "hooks/useTempDrawer";
import {NetworkManager, API} from "network/core";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {CsvBuilder} from "filefy";
import Users from "./Tabs/Users/Users";
import Account from "./Tabs/Accounts/Accounts";
import Roles from "./Tabs/Roles/Roles";
import User from "./UserDetail/Tabs/User/User";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Filter from "commonComponent/FilterAccoridan";
import FilterChips from "commonComponent/FilterChipsComponent";
import {sortFunction} from "utils/helperFunctions/helpers";
import {resetState, setShowFilter} from "store/slices/paginationSlice";

const Settings = () => {
    const navigation = useNavigate();
    const [value, setValue] = React.useState("settings");
    const [btnlabel, setBtnLebel] = useState("Add new user");
    const [heading, setHeading] = useState("Settings");
    const [search, setSearch] = useState("");
    // const [showFilter, setShowFilter] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    // const [filterChips, setFilterChips] = useState({});
    const [roles, setRoles] = useState([]);
    const [urlSearchParams] = useSearchParams();
    const {toggleDrawer} = useTempDrawer();
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const showFilter = useSelector((state) => state?.pagination?.showFilter);

    const navigate = useNavigate();
    let tab = urlSearchParams.get("tab");
    const modifierId = urlSearchParams.get("id");
    const dispatch = useDispatch();
    const roleCreatePermission = WithPermission({module: "Roles", permission: "create_permission"});
    const userCreatePermission = WithPermission({module: "Users", permission: "create_permission"});
    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "roles": {
                setBtnLebel(roleCreatePermission ? "Add role" : null);

                break;
            }
            case "user":
                setBtnLebel(userCreatePermission ? "Add new user" : null);
                break;
            default:
                setBtnLebel("");
                setHeading("Settings");
                break;
        }
    };
    const getRoles = async () => {
        const tempData = [];
        const params = `?page=${1}&pageSize=5000&sortBy=createdAt&orderBy=desc`;
        const instance = NetworkManager(API.ROLES.LISTING);
        const response = await instance.request({}, [params]);

        if (response.success) {
            const data = response.data;
            data.roles.forEach((el) => {
                tempData.push({id: el.role_id, label: el.role_name});
            });
        }
        setRoles(sortFunction(tempData));
    };
    useLayoutEffect(() => {
        getRoles();
    }, []);
    const filterState = [
        // {
        //     key: "search",
        //     label: "Username",
        //     type: "textfield"
        // },
        // {
        //     key: "email",
        //     label: "Email",
        //     type: "emailfield"
        // },
        {
            key: "status",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "ACTIVATE", label: "Active"},
                {id: "DEACTIVATE", label: "Inactive"},
                {id: "INVITED", label: "Invited"}
            ]
        },
        {
            key: "roleIds",
            label: "Role",
            type: "select",
            getData: roles
        }
    ];
    useEffect(() => {
        handleButtonLabelandFilter(tab);
        // toggleDrawer("right", false)();
    }, [window.location.search]);

    useEffect(() => {
        // is user edits the url query then we need to redirect it to default tab
        if (tab == null || (tab !== "account" && tab !== "roles" && tab !== "user")) {
            setValue("account");
            navigate({
                pathname: window.location.pathname,
                search: `?tab=${"account"}`
            });
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setSearch("");
        dispatch(resetState())
        setValue(newValue);
        navigate({
            pathname: window.location.pathname,
            search: `?tab=${newValue}`
        });
        dispatch(setShowFilter(false));
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "Add new user": {
                dispatch(setShowFilter(false));
                toggleDrawer("right", "addNewUser")(e);
                break;
            }
            case "none": {
                dispatch(setShowFilter(!showFilter));

                // setShowFilter((prev) => !prev);
                // toggleDrawer("right", true)(e);
                break;
            }
            case "Add role": {
                dispatch(setShowFilter(false));
                navigation(`/setting/role?tab=role&edit=false`);
                break;
            }
            default: {
                break;
            }
        }
    };

    const TabsData = [
        {
            value: "account",
            label: "Account",
            comp: () => <Account search={search} showFilter={showFilter} />
        },
        WithPermission({module: "Roles", permission: "read_permission"}) && {
            value: "roles",
            label: "Roles",
            comp: () => <Roles search={search} showFilter={showFilter} />
        },
        WithPermission({module: "Users", permission: "read_permission"}) && {
            value: "user",
            label: "Users",
            comp: () => (
                <Users
                    search={search}
                    showFilter={showFilter}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    // setFilterChips={setFilterChips}
                />
            )
        }
    ];

    useEffect(() => {
        if (TabsData.length) {
            if (!TabsData[0]?.value) {
                for (let i = 0; i < TabsData.length; i += 1) {
                    if (TabsData[i] !== null) {
                        tab = TabsData[i].value;
                        console.log(TabsData.length, "mne tadatga");
                        break;
                    }
                }
            }
        }
        setValue(tab);
        navigate({
            pathname: window.location.pathname,
            search: `?tab=${tab}`
        });
    }, []);

    const handleDeleteBulk = async () => {
        const instance = NetworkManager(API.SUBADMIN.BULKDELETE);
        const payload = selectedCheckbox.map((row) => {
            const list = JSON.parse(row);
            return list.user_id;
        });

        const response = await instance.request({ids: payload});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            setSelectedCheckbox([]);
            setUpdateListCount((pre) => pre + 1);
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedCheckbox([]);
        // getUnplacedOrderList();
    };

    const columnData = [
        {key: "first_name", label: "User name"},
        {key: "email", label: "Email"},
        {key: "phone", label: "Phone"},
        {key: "role", label: "Role"},
        {key: "user.status", label: "Status"},
        {key: "designation", label: "Designation"},
        {key: "linkedin_link", label: "Linkedin Link"},
        {key: "calendar_link", label: "Calendar Link"}
    ];
    const csvBuilder = () => {
        // console.log("Selected Rows before CSV generation:", selectedCheckbox);

        new CsvBuilder("item_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                selectedCheckbox.map((row) => {
                    const arr = JSON.parse(row);
                    // console.log("Parsed Row:", arr);

                    return columnData.map((col) => {
                        const fieldValue = Fields(col.key, arr);
                        console.log(`Field Value for ${col.key}:`, fieldValue);
                        return fieldValue;
                    });
                })
            )
            .exportFile();

        setSelectedCheckbox([]);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        {...(modifierId && {onlyHeading: true})}
                        {...(tab === "roles" && {
                            onlySearch: true
                        })}
                        {...(tab === "account" && {
                            onlyHeading: true
                        })}
                        btnTitle={btnlabel}
                        lessmarginOnTop
                        pageheader={<PageHeader title={heading} />}
                        handleClick={handleHeaderActions}
                        handleSearch={handleSearch}
                        filterType="none"
                        orderFilter="orderFilter"
                        selectedCheckbox={selectedCheckbox}
                        handleDeleteBulk={WithPermission({module: "Users", permission: ["create_permission"]}) && handleDeleteBulk}
                        deleteBulkTitle="users"
                        csvBuilder={csvBuilder}
                    >
                        <Filter
                            filterState={filterState}
                            filterChips={filterChips}
                            // setFilterState={setFilterChips}
                            showFilter={showFilter}
                        />
                        <FilterChips
                            filterChips={filterChips}
                            // setFilterChips={setFilterChips}
                            getLabel={roles}
                        />

                        <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default Settings;
