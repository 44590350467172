import {Grid, Typography, Box, Button} from "@mui/material";
import React, {useState, useEffect} from "react";
import AnimateButton from "ui-component/extended/AnimateButton";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl, FormatCurrency} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import useAuth from "hooks/useAuth";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import DriverModal from "commonComponent/DilogBox/DriverModal";
import PrintOrderModal from "commonComponent/DilogBox/PrintOrderModal";
import {ellipsizeText} from "commonComponent/Apptable/textUtils";
import {getformateDateUS, getFormattedTimeinAMPM, getformateDate} from "utils/app-dates/dates";
import Slider, {ResponsiveObject, Settings} from "react-slick";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewNotes from "views/pages/privatePages/customers/CreateCustomer/AddNewNoteForm";
import StatusCards from "./StatusCards";
import OrderNotes from "../../Notes/OrderNotes";
import CancelModal from "commonComponent/DilogBox/CancelModal";
import CancellationSideout from "../../CancellationSideout";
import HouseAccountPayment from "commonComponent/DilogBox/HouseAccountPayment";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const scheduledData = [
    {
        id: "R5G-05Z",
        name: "GrillaJoe",
        date: "Fri, Jul 8, 9:30am",
        location: "Pinnacle dermatology",
        address: "201 Bluebird Dr  </br> Goodlettsville, TN 37072",
        amount: "$276.90",
        buttonText: "Confirm",
        buttonAction: () => {}
    },
    {
        id: "F4E-P8D",
        name: "NanaBakin",
        date: "Tue, Jul 9, 11:30am",
        location: "Houzz",
        address: "201 Bluebird Dr </br> Goodlettsville, TN 37072",
        amount: "$457.90",
        buttonText: "Assign driver",
        buttonAction: () => {}
    }
];

const settings = {
    autoplay: true,
    arrows: false,

    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 2500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const sliderItemsCountFix = (settings, itemsCount) => {
    if (!settings.responsive) return settings;
    const responsiveArray = settings.responsive.map((responsiveItem) => {
        const responsive = responsiveItem.settings !== "unslick" && responsiveItem.settings;
        if (!responsive) return;
        const slidesToShow = responsive.slidesToShow || 1;
        const isItemsMoreThanSlidesToShow = itemsCount > slidesToShow;

        // eslint-disable-next-line consistent-return
        return isItemsMoreThanSlidesToShow ? responsiveItem : {...responsiveItem, settings: "unslick"};
    });

    settings.slidesToShow = itemsCount < 3 ? 1 : itemsCount;

    return {...settings, responsive: responsiveArray};
};

function Scheduled(props) {
    const {
        search,
        showFilter,
        handleEditOrder,
        handleReorder,
        setSelectedCheckbox,
        updateListCount,
        selectedCheckbox,
        openEditDialog,
        filterChips,
        setFilterValue,
        setSearchedValue
    } = props;
    // console.log("filterChips : ", filterChips);
    const [searchParams] = useSearchParams();
    const OrderId = searchParams.get("orderId");
    const {orderUpdate} = useAuth();
    const {toggleDrawer, state} = useTempDrawer();

    const [open, setOpen] = React.useState(false);
    const [scheduleOrderListing, setScheduleOrderListing] = React.useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(25);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "desc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "created_at");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [openCustomMenu, setOpenCustomMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const [openDriverModal, setOpenDriverModal] = useState(false);
    const [printOptionModal, setPrintOptionModal] = useState(false);
    const [priceParam, setPriceParam] = useState("customer_order");
    const [showHeading, setShowHeading] = useState(false);
    const [openCancelModal, setCancelModal] = useState(false);
    const [openCancelFeeModal, setCancelFeeModal] = useState(false);
    const [btnTitle, setBtnTitle] = useState("Confirm");
    const [modalMessage, setModalMessage] = useState("");
    const [modalDescription, setModalDescription] = useState("This will permanently cancel the order.");
    const [statusToUpdate, setStatus] = useState("");
    const handleCustomMenu = () => {
        setOpenCustomMenu((pre) => !pre);
    };


    useEffect(() => {
     setSelectedCheckbox([])
    }, [])
    
    const navigation = useNavigate();

    const dispatch = useDispatch();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleCancelModal = () => {
        setCancelModal((pre) => !pre);
    };

    const handleCancelFeeModal = () => {
        setCancelFeeModal((pre) => !pre);
    };
    const [driverListing, setDriverListing] = React.useState([]);
    const getDriverList = async () => {
        const instance = NetworkManager(API.DRIVER.LISTING);

        const response = await instance.request();

        if (response.success) {
            const driverList = response.data?.driver;
            // Modified array of objects with new property names
            const modifiedArray = driverList
                ?.sort((a, b) => a?.name?.localeCompare(b?.name))
                ?.map((obj) => ({
                    phone: obj.phoneNumber,
                    name: obj.name,
                    ...obj
                }));
            setDriverListing(modifiedArray);
        }
    };

    React.useEffect(() => {
        if (OrderId) {
            // if user comes from request edit email template
            openEditDialog(OrderId);
        }
    }, [OrderId]);

    const getScheduleOrderList = async (moreFilters = false) => {
        setLoading(true);
        console.log(moreFilters, 209);
        const instance = NetworkManager(API.ORDER.GET);
        const params = {
            offset: currentPage,
            status: "New,Accepted,Needs Driver,Active,Driver Assigned,ON_THE_WAY,DELIVERED,Completed",
            limit: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };

        if (moreFilters && moreFilters.guest_count) {
            if (moreFilters.guestFilter === "equal" || moreFilters.guestFilter === undefined) {
                params.guest_count = moreFilters.guest_count;
            } else {
                delete params.guest_count;
                params[`guest_count${moreFilters.guestFilter}`] = moreFilters.guest_count;
            }
            delete params.guestFilter;
        }
        if (moreFilters && moreFilters.total_amount) {
            if (moreFilters.amountFilter === "equal" || moreFilters.amountFilter === undefined) {
                params.total_amount = moreFilters.total_amount;
            } else {
                delete params.total_amount;
                params[`total_amount${moreFilters.amountFilter}`] = moreFilters.total_amount;
            }
            delete params.amountFilter;
        }

        if (moreFilters?.charged_by) {
            params.charged_by = moreFilters?.charged_by.join(",");
        }

        if (currentOrderBy) {
            params.sort_by = currentOrderBy;
            params.sort = currentOrder;
        }

        if (search === "") {
            params.offset = currentPage;
        }
        if (search !== "") {
            params.search = search;
            setScheduleOrderListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);
        if (response.success) {
            setScheduleOrderListing(response.data.orderList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
        setFilterValue("");
        setSearchedValue({});
    };

    const handleStatus = async (status, row) => {
        const instance = NetworkManager(API.ORDER.STATUS(row.order_id, status));
        const payload = {
            link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${row.order_id}`
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        getScheduleOrderList();

        //  else if (row.status === "Active" || row.status === "Driver assigned" || row.status === "Needs driver") {
        //     handleSelectedRow(row);
        //     handleDriverModal(row);
        // }
    };

    const handleCancelOrder = async () => {
        setSelectedRow(null);
        handleStatus(statusToUpdate, selectedRow);
        handleCancelModal();
    };
    const handleCancelFeeOrder = async () => {
        setSelectedRow(null);
        handleCancelFeeModal();
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
        toggleDrawer("right", false)();
    };

    const handleDriverModal = () => {
        setOpenDriverModal((pre) => !pre);
    };

    const handlePrintModal = () => {
        setPrintOptionModal((pre) => !pre);
    };

    const handlePrintDownload = (price) => {
        handlePrintModal();
        window.open(`/print?orderId=${selectedRow.order_id}&price=${price}`, "_blank", "noreferrer");
    };
    const handleAssignDriver = async (values) => {
        const instance = NetworkManager(API.DRIVER.ASSIGN);

        const response = await instance.request({
            orderId: selectedRow?.order_id,
            carrierId: `${values}`
        });

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setOpenDriverModal((pre) => !pre);
        getScheduleOrderList();
    };

    const handleMarking = async (id, type) => {
        const instance = NetworkManager(API.ORDER.MARKINGPAID);
        const response = await instance.request({
            orderId: id,
            isPaid: type
        });

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }

        getScheduleOrderList();
    };
    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                handleEditOrder(row.order_id);
                break;
            }
            case "Re-order": {
                handleReorder(row.order_id);
                break;
            }

            case "Download": {
                window.open(`/print?orderId=${row.order_id}&price=customer_order`, "_blank", "noreferrer");
                break;
            }
            case "Delete draft": {
                handleDeleteModal();
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Mark complete": {
                handleStatus("Complete", row);
                break;
            }
            case "Cancel order": {
                // setBtnTitle("Confirm");
                // setModalMessage("Are you sure you want to cancel this order?");
                setStatus("Cancelled");
                handleCancelFeeModal();
                break;
            }
            case "Accept": {
                setBtnTitle("Accept");
                setModalMessage("Are you sure you want to accept this order?");
                setStatus("Accepted");
                handleCancelModal();
                break;
            }
            case "Reject": {
                setBtnTitle("Reject");
                setModalMessage("Are you sure you want to reject this order?");
                setModalDescription("This action cannot be undone.");
                setStatus("Rejected");
                handleCancelModal();
                break;
            }

            case "Assign driver": {
                handleDriverModal(row);
                break;
            }
            case "Print": {
                handlePrintModal(row);
                break;
            }
            case "Add note": {
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            case "Mark as paid": {
                handleCustomMenu();
                break;
            }
            case "Payment detail": {
                // handleMarking(row.order_id, false);
                handleCustomMenu();
                break;
            }
            // case "Mark as paid": {
            //     handleMarking(row.order_id, true);
            //     break;
            // }
            // case "Mark as unpaid": {
            //     handleMarking(row.order_id, false);
            //     break;
            // }

            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.ORDER.DELETE(selectedRow.order_id));
        const response = await instance.request();
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getScheduleOrderList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        if (val2 === "payment_by") {
            dispatch(setOrder(val1))
            dispatch(setOrderBy("charged_by"))
        } else if (val2 === "kitchen_location.market.market_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1));
            dispatch(setOrderBy(splitText[2]));
        } else if (val2 === "order.status" || val2 === "brand.brand_name") {
            const splitText = val2.split(".");
            dispatch(setOrder(val1));
            dispatch(setOrderBy(splitText[1]));
        } else if (val2 === "customer") {
            const filterText = `${val2}_name`;
            dispatch(setOrder(val1));
            dispatch(setOrderBy(filterText));
        } else {
            dispatch(setOrder(val1));
            dispatch(setOrderBy(val2));
        }
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        console.log(filterChips, 576);
        getDriverList();
        getScheduleOrderList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, orderUpdate, updateListCount]);

    const getMarketList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.marketList.forEach((el) => {
                tempData.push({id: el.market_id, label: el.market_name});
            });
        }
        return tempData;
    };

    const getBrandList = async (page = 1, pageSize = 1000) => {
        const tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: page,
            pageSize: pageSize
        };
        if (currentOrderBy) {
            params.sortBy = "createdAt";
            params.orderBy = "desc";
        }

        const response = await instance.request({}, params);

        if (response.success) {
            response.data.brandList.forEach((el) => {
                tempData.push({id: el.brand_id, label: el.brand_name});
            });
        }
        return tempData;
    };

    const filterState = [
        {
            key: "search",
            label: "Order id",
            type: "textfield"
        },
        {
            key: "start_date",
            label: "Created from",
            type: "startDate"
        },
        {
            key: "end_date",
            label: "Created to",
            type: "endDate"
        },
        {
            key: "delivery_start_date",
            label: "Delivery from",
            type: "startDate"
        },
        {
            key: "delivery_end_date",
            label: "Delivery to",
            type: "endDate"
        },
        {
            key: "customer_name",
            label: "Customer",
            type: "textfield"
        },
        {
            key: "delivery_address",
            label: "Address",
            type: "textfield"
        },
        {
            key: "source",
            label: "Source",
            type: "multiselect",
            getData: () => [
                {id: "ADMIN", label: "Direct order"},
                {id: "Customer", label: "Marketplace"},
                {id: "EZcater", label: "EZcater"},
                {id: "EZorder", label: "EZorder"}
            ]
        },
        {
            key: "service_type",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        },
        {
            key: "market_id",
            label: "Market",
            type: "multiselect",
            getData: getMarketList
        },
        {
            key: "brand_id",
            label: "Brand",
            type: "multiselect",
            getData: getBrandList
        },
        {
            key: "guest_count",
            label: "Guests",
            type: "numberfield",
            filterType: "Equals",
            filterKey: "guestFilter"
        },
        {
            key: "total_amount",
            label: "Total",
            type: "numberfieldWithDecimal",
            filterType: "Equals",
            filterKey: "amountFilter"
        },
        {
            key: "status",
            label: "Status",
            type: "select",
            getData: () => [
                {id: "New", label: "New"},
                {id: "Active,Driver Assigned", label: "Active"},
                {id: "Needs Driver", label: "Needs driver"},
                {id: "Accepted", label: "Accepted"},
                {id: "DELIVERED", label: "Delivered"},
                {id: "ON_THE_WAY", label: "On the way"},
                {id: "Completed", label: "Completed"}
            ]
        }
    ];

    const handleApplyFilter = (Morefilters) => {
        dispatch(setPageNumber(1));
        // setFilterChips(Morefilters);
    };

    const handleNoFee = async () => {
        const instance = NetworkManager(API.ORDER.STATUS(selectedRow.order_id, statusToUpdate));
        let payload = null;

        if (statusToUpdate === "Cancelled") {
            payload = {
                link: `${process.env.REACT_APP_CUSTOMER_URL}account?tab=orders&orderId=${selectedRow.order_id}`
            };
        }

        const response = await instance.request(payload);
        if (response.success) {
            setCancelModal(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleCancelFeeModal();
        getScheduleOrderList();
    };

    const handleOpenCancelSidebar = () => {
        toggleDrawer("right", "cancelationFee")();
        setCancelFeeModal(false);
    };

    const handleHouseAccountPayment = () => {
        getScheduleOrderList();
    };

    return (
        <>
            {/* {showFilter ? (
                <Filters
                    filterState={filterState}
                    filterChips={filterChips}
                    // setFilterChips={setFilterChips}
                    handleApplyFilter={handleApplyFilter}
                    sideBarStateName="orderFilter"
                />
            ) : null} */}

            <Grid container spacing={2} mt="0px" gap={1} paddingLeft="16px">
                <StatusCards
                    setLoading={setLoading}
                    generateUrl={generateUrl}
                    handleDriverModal={handleDriverModal}
                    selectedRow={selectedRow}
                    handleSelectedRow={handleSelectedRow}
                    getScheduleOrderList={getScheduleOrderList}
                    filterChips={filterChips}
                />

                <Grid container>
                    <Grid item xs={12}>
                        {/* <FilterChips
                            filterChips={filterChips}
                            // setFilterChips={setFilterChips}
                        /> */}
                        <CommonTable
                            withCheckbox
                            loading={loading}
                            currentTab="scheduled"
                            data={scheduleOrderListing}
                            checkbox
                            header={[
                                {key: "order_number", label: "Order #"},
                                {key: "created_at", label: "Created"},
                                {key: "delivery_date", label: "Delivery"},
                                {key: "customer", label: "Customer"},
                                {key: "delivery_address", label: "Address"},
                                {key: "service_type", label: "Service"},
                                {key: "kitchen_location.market.market_name", label: "Market"},
                                {key: "brand.brand_name", label: "Brand"},
                                {key: "driver", label: "Driver"},
                                {key: "total_price", label: "Total"},
                                {key: "payment_by", label: "Payment"},
                                {key: "order.status", label: "Status"},
                                {key: "action", label: "Action"}
                            ]}
                            height="50vh"
                            actions={[]}
                            handleActionBtn={handleActionBtn}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsChange}
                            handleSort={handleSort}
                            totalItems={totalItems}
                            rowsPerPage={rowsPerPage}
                            currentPage={currentPage - 1}
                            sortingLabel={[
                                "Order #",
                                "Created",
                                "Delivery",
                                "Total",
                                "Brand",
                                "Customer",
                                "Status",
                                "Service",
                                "Payment",
                                "Market"
                            ]}
                            setSelectedCheckbox={setSelectedCheckbox}
                            selectedCheckbox={selectedCheckbox}
                        />
                    </Grid>
                </Grid>
                {state.right === "addNewNotes" && (
                    <OrderNotes
                        userID={selectedRow?.customer?.user_id}
                        selectedData={selectedRow}
                        handleIsEdited={handleIsEdited}
                        handleSelectedRow={handleSelectedRow}
                        // setSelectedRow={setSelectedRow}
                    />
                )}
                {state.right === "cancelationFee" && (
                    <CancellationSideout
                        userID={selectedRow?.customer?.user_id}
                        selectedData={selectedRow}
                        handleIsEdited={handleIsEdited}
                        handleSelectedRow={handleSelectedRow}
                        setSelectedRow={setSelectedRow}
                    />
                )}
                {openDriverModal && (
                    <DriverModal
                        open={openDriverModal}
                        buttonTitle="Assign driver"
                        prefillDriverId={selectedRow?.carrier_id}
                        title="Assign a driver"
                        driversData={driverListing}
                        orderId={selectedRow?.order_id}
                        marketId={
                            selectedRow?.kitchen_location?.market?.market_id.length > 0
                                ? selectedRow?.kitchen_location?.market?.market_id
                                : []
                        }
                        description={`Select a driver to assign to order #${selectedRow?.order_number}`}
                        handleClose={handleDriverModal}
                        handleSubmit={(values) => handleAssignDriver(values)}
                    />
                )}
                <PrintOrderModal
                    open={printOptionModal}
                    buttonTitle="Print order"
                    title="Print order"
                    description="Select the type of order you would like to print"
                    handleClose={handlePrintModal}
                    handleSubmit={handlePrintDownload}
                    noOnClose
                    selected={priceParam}
                    onSelect={(price) => {
                        setPriceParam(price);
                        setShowHeading(price === "price");
                    }}
                />

                <DeleteModal
                    open={open}
                    title="Are you sure you want to delete this order?"
                    description="This will permanently remove all data associated with it."
                    handleClose={handleDeleteModal}
                    handleSubmit={handleDelete}
                />
                <DeleteModal
                    open={openCancelModal}
                    buttonTitle={btnTitle}
                    title={modalMessage}
                    description={modalDescription}
                    handleClose={handleCancelModal}
                    handleSubmit={handleCancelOrder}
                />
                <CancelModal
                    open={openCancelFeeModal}
                    buttonTitle="Yes charge fee"
                    title={`Cancel order #${selectedRow?.order_number}`}
                    description="Would you like to charge a cancelation fee?"
                    handleNoFee={handleNoFee}
                    handleClose={handleCancelFeeOrder}
                    handleSubmit={handleOpenCancelSidebar}
                />
                <HouseAccountPayment
                    open={openCustomMenu}
                    buttonTitle="Submit"
                    title="How much is received?"
                    orderId={selectedRow?.order_id}
                    remainingAmount={selectedRow?.remaining_amount_to_be_paid}
                    handleClose={handleCustomMenu}
                    handleHouseAccountPayment={handleHouseAccountPayment}
                    noOnClose
                />
            </Grid>
        </>
    );
}

export default Scheduled;
