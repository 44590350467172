import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import AnimateButton from "ui-component/extended/AnimateButton";

const ButtonGrouping = (props) => {
    console.log("props : ",props)
    const { justifyContent = "end", btnprops = [{btnTitle: "Cancel"}, {btnTitle: "Save"}]} = props;
    return (
        <Stack direction="row" spacing={1} justifyContent={justifyContent}>
            {btnprops?.map((obj, i) => {
                const {btnTitle, sx, size, ...props} = obj;
                return (
                    <AnimateButton key={i}>
                        <Button
                            {...props}
                            size={size}
                            sx={(theme) => ({
                                ...(!size && {width: 92, height: 35}),
                                ...(sx && {...sx(theme)})
                            })}
                        >
                            <Typography variant="body1">{btnTitle}</Typography>
                        </Button>
                    </AnimateButton>
                );
            })}
        </Stack>
    );
};

export default ButtonGrouping;
