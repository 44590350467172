import React, {useEffect, useState, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import useTempDrawer from "hooks/useTempDrawer";
import Profile from "./Tabs/Profile/Profile";
import PreferredPricing from "./Tabs/PreferredPricing/PreferredPricing";
import Notes from "./Tabs/Notes/Notes";
import Orders from "./Tabs/Orders/Orders";
import HouseAccount from "./Tabs/HouseAccount/HouseAccount";
import useToggleDilog from "hooks/useToggleDilog";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {NetworkManager, API} from "network/core";
import {sortFunction} from "utils/helperFunctions/helpers";
import {FilterContext} from "contexts/FilterContext";
import {useSelector, useDispatch} from "react-redux";
import {resetChildState, setChildShowFilter} from "store/slices/paginationSlice";

const useCustomerDetailController = () => {
    const navigate = useNavigate();
    const {toggleDilog} = useToggleDilog();
    const prm = useParams();
    const dispatch = useDispatch();
    const customerid = prm?.type;
    const [label, setLebel] = useState("");
    const [heading, setHeading] = useState("");
    const {openFilter, handleFilter} = useContext(FilterContext);
    const [search, setSearch] = useState("");
    // const [filterChips, setFilterChips] = useState({});
    // const [showFilter, setShowFilter] = useState(false);
    const showFilter = useSelector((state) => state?.pagination?.childShowFilter);
    const filterChips = useSelector((state) => state?.pagination?.childFilterChips);
    // console.log(filterChips, 3131);
    const [searchedValue, setSearchedValue] = useState({});
    const [filterValue, setFilterValue] = useState("");
    const [marketList, setMarketList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [breadCrumb, setBreadcrumb] = useState("Menu detail");
    const [editMenu, setEditMenu] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const {toggleDrawer} = useTempDrawer();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tab = urlSearchParams.get("tab");
    const editCustomerorNot = urlSearchParams.get("edit");
    const headerName = urlSearchParams.get("customerName");
    const showHouseAccount = urlSearchParams.get("show");
    const [value, setValue] = React.useState("profile");
    const userCreatePermission = WithPermission({module: "Customers", permission: "create_permission"});

    const userUpdatePermission = WithPermission({module: "Customers", permission: "update_permission"});
    const orderCreatePermission = WithPermission({module: "Orders", permission: "create_permission"});
    // const handleButtonLabelandFilter = (type) => {
    //     switch (type) {
    //         case "menuItems":
    //             setBtnLebel(itemCreatePermission ? "Create item" : null);
    //             setHeading("Menu items");
    //             break;
    //         case "modifierGroup":
    //             setBtnLebel(modifierCreatePermission ? "Create modifier group" : null);
    //             setHeading("Modifier groups");
    //             break;

    //         default:
    //             setBtnLebel(menuCreatePermission ? "Create menu" : null);
    //             setHeading("Menus");
    //             break;
    //     }
    // };

    const handleButtonLabelandFilter = (type) => {
        switch (type) {
            case "profile":
                setLebel(userUpdatePermission ? "Edit profile" : null);
                setHeading("Profile");
                break;
            case "orders":
                setLebel(orderCreatePermission ? "Create order" : null);
                setHeading("Orders");
                break;
            case "preferred_pricing":
                setLebel(userUpdatePermission ? "Edit preferred pricing" : null);
                setHeading("Preferred Pricing");
                break;
            case "notes":
                setLebel(userCreatePermission ? "Create note" : null);
                setHeading("Notes");
                break;
            case "houseAccount":
                setLebel(userUpdatePermission ? "Edit house account" : null);
                setHeading("House account");
                break;
            default:
                setLebel("");
                setHeading("Customer");
                break;
        }
    };

    useEffect(() => {
        handleButtonLabelandFilter(tab);
    }, [window.location.search]);

    useEffect(() => {
        setValue(tab);
    }, [tab]);

    useEffect(() => {
        setValue(tab);
        let search = headerName ? `?tab=${tab}&customerName=${headerName}` : `?tab=${tab}`;
        if (editCustomerorNot) {
            search = headerName ? `?tab=${tab}&customerName=${headerName}&edit=${editCustomerorNot}` : `?tab=${tab}&edit=false`;
        }
        navigate({
            pathname: window.location.pathname,
            search: search
        });
        if (customerid === undefined) {
            setBreadcrumb("Create Profile");
        }
    }, []);

    const handleChange = (event, newValue) => {
        dispatch(resetChildState());
        setValue(newValue);
        setSelectedCheckbox([]);
        if (editCustomerorNot) {
            navigate({
                pathname: window.location.pathname,
                search: headerName
                    ? `?tab=${newValue}&customerName=${headerName}&edit=${editCustomerorNot}`
                    : `?tab=${newValue}&edit=${editCustomerorNot}`
            });
            return;
        }
        navigate({
            pathname: window.location.pathname,
            search: headerName ? `?tab=${newValue}&customerName=${headerName}` : `?tab=${newValue}`
        });
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const handleCreateLocation = (btntype) => (e) => {
        switch (btntype) {
            case "Create note": {
                dispatch(setChildShowFilter(false));
                toggleDrawer("right", "addNewNotes")(e);
                break;
            }
            case "orderFilter": {
                dispatch(setChildShowFilter(!showFilter));
                handleFilter();
                break;
            }
            case "Create order": {
                dispatch(setChildShowFilter(false));
                navigate(`/orders?tab=scheduled&&customerName=${headerName}&&customerId=${customerid}`);
                toggleDilog("CreateOrder")();
                // toggleDrawer("right", true)(e);
                break;
            }
            case "Edit profile": {
                dispatch(setChildShowFilter(false));
                navigate({
                    pathname: window.location.pathname,
                    search: `?tab=profile&customerName=${headerName}&edit=true`
                });
                break;
            }
            case "Edit preferred pricing": {
                dispatch(setChildShowFilter(false));
                navigate({
                    pathname: window.location.pathname,
                    search: `?tab=preferred_pricing&customerName=${headerName}&edit=true`
                });
                break;
            }
            case "Edit house account": {
                dispatch(setChildShowFilter(false));
                navigate({
                    pathname: window.location.pathname,
                    search: `?tab=houseAccount&customerName=${headerName}&edit=true`
                });
                break;
            }
            case "none": {
                dispatch(setChildShowFilter(false));
                toggleDrawer("right", true)(e);
                break;
            }
            default: {
                break;
                // setEditMenu(true)
            }
        }
    };

    const dummyData = [
        {
            value: "profile",
            label: "Profile",
            comp: () => <Profile />
        },
        {
            value: "preferred_pricing",
            label: "Preferred pricing",
            comp: () => <PreferredPricing />
        },
        {
            value: "orders",
            label: "Orders",
            comp: () => (
                <Orders
                    search={search}
                    showFilter={showFilter}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                />
            )
        },
        {
            value: "notes",
            label: "Notes",
            comp: () => (
                <Notes
                    search={search}
                    // setShowFilter={setShowFilter}
                    showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    filterChips={filterChips}
                    setSearchedValue={setSearchedValue}
                />
            )
        },
        {
            value: "houseAccount",
            label: "House account",
            comp: () => <HouseAccount />
        }
        // showHouseAccount === "true"
        //     ? {
        //           value: "houseAccount",
        //           label: "House Account",
        //           comp: () => <HouseAccount />
        //       }
        //     : {
        //           value: "",
        //           label: "",
        //           comp: () => ""
        //       }
    ];

    const staticParams = {
        page: 1,
        pageSize: 1000,
        sortBy: "createdAt",
        orderBy: "desc"
    };

    const getMarketList = async () => {
        const tempData = [];
        const instance = NetworkManager(API.MARKET.GET);
        const params = {
            page: 1,
            pageSize: 1000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);
        if (response?.success) {
            response?.data?.marketList.forEach((el) => {
                tempData.push({id: el?.market_id, label: el?.market_name});
            });
        }
        setMarketList(sortFunction(tempData));
    };
    const getBrandList = async () => {
        const tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: 1,
            pageSize: 1000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);

        if (response?.success) {
            response?.data?.brandList.forEach((el) => {
                tempData.push({id: el?.brand_id, label: el?.brand_name});
            });
        }
        setBrandList(sortFunction(tempData));
    };

    const getFilterState = (tab) => {
        switch (tab) {
            case "orders":
                return [
                    // {
                    //     key: "start_date",
                    //     label: "Created from",
                    //     type: "startDate"
                    // },
                    {
                        type: "rangePicker",
                        key1: "start_date",
                        key2: "end_date",
                        startLabel: "Created date",
                        endLabel: "Created to"
                    },
                    // {
                    //     key: "end_date",
                    //     label: "Created to",
                    //     type: "endDate",
                    //     minDate:"start_date"
                    // },
                    {
                        type: "dateRange",
                        key1: "delivery_start_date",
                        key2: "delivery_end_date",
                        startLabel: "Delivery date",
                        endLabel: "Delivery to"
                    },
                    // {
                    //     key: "delivery_start_date",
                    //     label: "Delivery from",
                    //     type: "startDate"
                    // },
                    // {
                    //     key: "delivery_end_date",
                    //     label: "Delivery to",
                    //     type: "endDate",
                    //     minDate: "delivery_start_date"
                    // },

                    // {
                    //     key: "delivery_address",
                    //     label: "Address",
                    //     type: "textfield"
                    // },
                    {
                        key: "source",
                        label: "Source",
                        type: "multiselect",
                        getData: () => [
                            {id: "ADMIN", label: "Direct order"},
                            {id: "Customer", label: "Marketplace"},
                            {id: "EZcater", label: "EZcater"},
                            {id: "EZorder", label: "EZorder"}
                        ]
                    },
                    {
                        key: "service_type",
                        label: "Service",
                        type: "multiselect",
                        getData: () => [
                            {id: "CATERING", label: "Catering"},
                            {id: "HOME_MEALS", label: "Home meals"}
                        ]
                    },
                    {
                        key: "charged_by",
                        label: "Payment",
                        type: "multiselect",
                        getData: () => [
                            {id: "HOUSE_ACCOUNT", label: "House account"},
                            {id: "CREDIT_CARD", label: "Credit card"}
                        ]
                    },
                    {
                        key: "market_id",
                        label: "Market",
                        type: "multiselect",
                        getData: marketList
                    },
                    {
                        key: "brand_id",
                        label: "Brand",
                        type: "multiselect",
                        getData: brandList
                    },
                    {
                        key: "guest_count",
                        label: "Guests",
                        type: "numberfield",
                        filterType: "Equals",
                        filterKey: "guestFilter",
                        getData: [
                            {label: "Equals", value: "equal", id: "equal"},
                            {label: "Greater than", value: "greater than", id: "_greater_than"},
                            {label: "Less than", value: "less than", id: "_less_than"},
                            {label: "Not equal", value: "not equal", id: "_not_equal"}
                        ]
                    },
                    {
                        key: "total_amount",
                        label: "Total",
                        type: "numberfieldWithDecimal",
                        filterType: "Equals",
                        filterKey: "amountFilter",
                        getData: [
                            {label: "Equals", value: "equal", id: "equal"},
                            {label: "Greater than", value: "greater than", id: "_greater_than"},
                            {label: "Less than", value: "less than", id: "_less_than"},
                            {label: "Not equal", value: "not equal", id: "_not_equal"}
                        ]
                    },
                    {
                        key: `status`,
                        attrib: `status${Date.now()}`,
                        label: "Status",
                        type: "select",
                        getData: () => [
                            {id: "Quote Sent", label: "Quote sent"},
                            {id: "QUOTE_REJECTED", label: "Quote rejected"},
                            {id: "QUOTE_EXPIRED", label: "Quote expired"},
                            {id: "New", label: "New"},
                            {id: "Rejected", label: "Declined"},
                            {id: "Cancelled", label: "Canceled"},
                            {id: "Active", label: "Active"},
                            {id: "Complete", label: "Complete"},
                            {id: "Draft", label: "Draft"}
                        ]
                    }
                ];

            case "notes":
                return [
                    {
                        key1: "start_date",
                        key2: "end_date",
                        label: "Created date",
                        type: "rangePicker"
                    }
                ];
            default:
                break;
        }
        return [];
    };
    return {
        dummyData,
        value,
        editCustomerorNot,
        handleChange,
        breadCrumb,
        customerid,
        label,
        heading,
        handleCreateLocation,
        handleSearch,
        search,
        headerName,
        setSelectedCheckbox,
        selectedCheckbox,
        updateListCount,
        setUpdateListCount,
        getFilterState,
        showFilter,
        filterChips,
        // setFilterChips,
        getMarketList,
        getBrandList,
        marketList,
        brandList
    };
};

export default useCustomerDetailController;
