import React, {useEffect, useLayoutEffect, useState} from "react";
import {Box, Grid, Typography,  Button} from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {enforceFormat, formatToPhone, getManagers} from "utils/helperFunctions/helpers";
import {ErrorMessage,  Formik} from "formik";
import * as yup from "yup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import PlusIcon from "assets/images/icons/plus.png";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import useTempDrawer from "hooks/useTempDrawer";
import AddNewAddressForm from "../../../CreateCustomer/AddNewAddressForm";
import AddNewCardForm from "../../../CreateCustomer/AddNewCardForm";
import {useNavigate, useSearchParams, useParams, } from "react-router-dom";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {useDispatch} from "react-redux";
import SalesTaxExempt from "./SalesTaxExempt/SalesTaxExempt";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import Loader from "ui-component/Loader";
import ProfileView from "./ProfileView/ProfileView";
import Visa from "assets/images/card/visa.svg";
import Master from "assets/images/card/master.svg";
import Discover from "assets/images/card/discover.svg";
import American from "assets/images/card/american.svg";
import PayPal from "assets/images/card/PayPal.svg";
import ApplePay from "assets/images/card/ApplePay.svg";
import Other from "assets/images/card/other.svg";
import DeleteIcon from "assets/images/icons/Delete.svg";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";

const formSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    addCard: "",
    company: "",
    companyId: "",
    title: "",
    state: "",
    taxId: "",
    sales_tax_doc_url: "",
    accountManager: ""
};

function Profile(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {toggleDrawer} = useTempDrawer();
    const [isUpdate, setIsUpdate] = useState(false);
    const [initialValues, setInitialValues] = useState({
        ...formSchema,
        address: ""
    });
    const [isAddAddressFormOpen, setIsAddAddressFormOpen] = useState(false);
    const [isAddNewCardFormOpen, setIsAddNewCardFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [urlSearchParams] = useSearchParams();
    const prm = useParams();
    const profileId = prm.type;
    const [addressList, setAddresList] = useState([]);
    const [isListUpdated, setIsAddedListUpdated] = useState(false);
    const [cardList, setCardList] = useState([]);
    const mrkName = urlSearchParams.get("customerName");
    const editMode = urlSearchParams.get("edit");
    const [selectedAddress, setSelectedAddress] = useState("");
    const [buttonType, setbuttonType] = useState("");
    const [isAddressUpdate, setIsAddressUpdate] = useState(false);
    const [defaultCardId, setDefaultCardId] = useState("");
    const [selectedCard, setSelectedCard] = useState("");
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [selectEditAddress, setSelectEditAddress] = useState("");
    const [deleteType, setDeleteType] = useState("");
    const [managers, setManagers] = useState([]);
    useLayoutEffect(() => {
        getManagers(setManagers, setLoading);
    }, []);
    const handleAddressUpdated = () => {
        setIsAddressUpdate(!isAddressUpdate);
    };

    const handleUpdateList = () => {
        setIsAddedListUpdated(!isListUpdated);
    };

    const getCustomerProfileData = async () => {
        setLoading(true);
        const instance = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${profileId}`));

        const response = await instance.request();

        if (response.success) {
            setCustomerData(response?.data);
            const defaultAddress = response?.data?.customerAddresses?.find((address) => address.is_default);
            setInitialValues({
                first_name: response?.data?.full_name || "",
                last_name: response?.data?.last_name || "",
                email: response?.data?.email || "",
                phone: response?.data?.phone || "",
                address: defaultAddress?.delivery_address || "",
                addCard: response?.data?.addCard || "",
                company: response?.data?.company?.company_name ?? "",
                title: response?.data?.designation || "",
                state: response?.data?.sales_tax_state_id || "",
                taxId: response?.data?.tax_exempt_id || "",
                sales_tax_doc_url: response?.data?.sales_tax_doc_url || "",
                accountManager: response?.data?.account_manager_id || ""
            });
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setLoading(false);
    };

    const cardLogo = (cardType) => {
        switch (cardType) {
            case "Visa":
                return Visa;
            case "AmericanExpress":
                return American;
            case "Discover":
                return Discover;
            case "MasterCard":
                return Master;
            case "PayPal":
                return PayPal;
            case "ApplePay":
                return ApplePay;
            // case "DinersClub":
            //     return Discover;
            default:
                return Other;
        }
    };

    const getCardList = async () => {
        const instance = NetworkManager(API.CUSTOMER.GETCARDLIST(`${profileId}`));
        const res = await instance.request();
        if (res.success) {
            const cardsList = res?.data?.profile?.paymentProfiles || [];
            const dataMapping = cardsList.map((el) => {
                if (el?.defaultPaymentProfile) {
                    setDefaultCardId(el?.customerPaymentProfileId);
                }
                const contructlabel = (data) => (data ? `${data?.cardType} ending with ${data?.cardNumber?.substr(-4)}` : "");
                return {
                    id: el?.customerPaymentProfileId,
                    label: (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img
                                src={cardLogo(el?.payment?.creditCard?.cardType)}
                                style={{
                                    width: 60,
                                    height: 30,
                                    objectFit: "cover",
                                    marginRight: 5
                                }}
                                alt={el?.payment?.creditCard?.cardType}
                            />
                            <div style={{marginTop: "8px", marginRight: "4px"}}>****</div>
                            <div>{el?.payment?.creditCard?.cardNumber.slice(-4)}</div>
                        </div>
                    )
                };
            });
            setCardList([...dataMapping]);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    const getHouseAccountData = async () => {
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(profileId));

        const response = await instance.request();
        if (response.success) {
            const data = response.data;
            if (response.data?.customer_card_id) {
                setIsUpdate(true);
            }
        }
    };

    useEffect(() => {
        getCardList();
    }, [isListUpdated]);

    const getAddressList = async () => {
        let data;
        const instance = NetworkManager(API.CUSTOMER.GETADDRESSLIST(`${profileId}`));
        const response = await instance.request();

        if (response.success) {
            data = response?.data;
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        return setAddresList(data);
    };

    useEffect(() => {
        getAddressList();
    }, [isAddressUpdate]);

    useEffect(() => {
        getCardList();
        getHouseAccountData();
    }, []);

    useEffect(() => {
        getCustomerProfileData();
    }, [profileId]);

    const findSelectedAddress = (addressId) => addressList.find((address) => address.address_id === addressId);

    const handleAddCategory = (type, row, ind) => (e) => {
        setbuttonType(type);
        switch (type) {
            case "addAddressForm": {
                setIsAddAddressFormOpen(true);
                break;
            }
            case "addNewCardForm": {
                setIsAddNewCardFormOpen(true);
                break;
            }
            case "editAddress": {
                setIsAddAddressFormOpen(true);
                break;
            }
            default:
                break;
        }
    };
    const handleCreateCustomer = async (values) => {
        const instance = NetworkManager(API.CUSTOMER.UPDATEPROFILE(`${profileId}`));
        const payload = {
            email: values.email,
            full_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            company_id: values.companyId,
            address_id: values.address,
            customer_card_id: values.addCard,
            sales_tax_state_id: values.state,
            tax_exempt_id: values.taxId,
            designation: values.title,
            sales_tax_doc_url: values.sales_tax_doc_url,
            account_manager_id: values.accountManager !== "" ? values.accountManager : null
        };
        const response = await instance.request(payload);

        if (response.success) {
            setCustomerData(response?.data);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            navigate("/customers?tab=customer");
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };
    useEffect(() => {
        getCustomerProfileData();
    }, [profileId]);

    useEffect(() => {
        if (customerData.customerAddresses) {
            const defaultAddress = customerData?.customerAddresses.find((address) => address?.is_default);
            setInitialValues((prevValues) => ({
                ...prevValues,
                address: defaultAddress?.address_id || ""
            }));
        }
    }, [customerData]);

    const handleDeleteModal = (type) => {
        setOpen((pre) => !pre);
        setDeleteType(type);
        if (type === "address") {
            setTitle("Are you sure you want to delete this address?");
        } else if (type === "card") {
            setTitle("Are you sure you want to delete this card?");
        }
    };

    const handleDeleteCard = async () => {
        setLoading(true);
        setOpen((pre) => !pre);
        const id = selectedCard;

        const instance = NetworkManager(API.CARD.DELETE(profileId, id));

        const res = await instance.request();

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );

            getCardList();
            getHouseAccountData();
            setDeleteType("");
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        setLoading(false);
    };

    const handleDeleteAddress = async (formik) => {
        setOpen((pre) => !pre);
        const instance = NetworkManager(API.ADDRESS.DELETE(selectEditAddress));
        const res = await instance.request();
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );

            setSelectEditAddress("");
            setInitialValues((prevValues) => ({
                ...prevValues,
                address: ""
            }));
            setSelectedAddress("");
            formik.setFieldValue("address", ""); // Clear the form field

            // handleIsEdited();

            getAddressList();
            setDeleteType("");
            // getAllAddresslist();
            // const filteredaddress = addresslist?.filter((el) => el?.address_id !== address?.address_id);
            // dispatch(setAddress(filteredaddress?.[0]));
            // dispatch(setAddressList([...filteredaddress]));
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
    };

    return (
        <>
            {loading && <Loader />}
            {isAddAddressFormOpen && (
                <AddNewAddressForm
                    userID={profileId}
                    setIsAddAddressFormOpen={setIsAddAddressFormOpen}
                    selectedAddress={selectedAddress}
                    findSelectedAddress={findSelectedAddress}
                    buttonType={buttonType}
                    handleAddressUpdated={handleAddressUpdated}
                    // selectedAddressId={selectedAddressId}
                />
            )}
            {isAddNewCardFormOpen && (
                <AddNewCardForm userID={profileId} setIsAddNewCardFormOpen={setIsAddNewCardFormOpen} handleCardUpdated={getCardList} />
            )}
            {editMode ? (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={yup.object({
                        first_name: yup.string().required("First name is required").nullable().trim(),
                        last_name: yup.string().required("Last name is required").nullable().trim(),
                        email: yup.string().required("Email is required").email("Enter valid email").nullable().trim()
                        // phone: yup.string().required("Phone number is required").nullable().trim()
                        // marketId: yup.string().required("Market is required").nullable().trim(),
                        // address: yup.string().required("address is required").nullable().trim(),
                        // addCard: yup.string().required("Card is required").nullable().trim(),
                        // state: yup.string().required("State is required").nullable().trim(),
                        // taxId: yup.string().required("Tax exempt ID is required").nullable().trim()
                    })}
                    onSubmit={(values, actions) => handleCreateCustomer(values, actions)}
                >
                    {(formik) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item xs={12} mt={1}>
                                    <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Customer details</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                    <CustomTextField
                                        placeholder="First name"
                                        label="First name"
                                        fullWidth
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                        }}
                                        inputProps={{maxLength: 50}}
                                        {...formik.getFieldProps("first_name")}
                                    />
                                    <Box sx={{height: "27px"}}>
                                        <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                    <CustomTextField
                                        placeholder="Last name"
                                        label="Last name"
                                        fullWidth
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                        }}
                                        inputProps={{maxLength: 50}}
                                        {...formik.getFieldProps("last_name")}
                                    />
                                    <Box sx={{height: "27px"}}>
                                        <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sx={{paddingTop: "0px !important"}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                            <CustomTextField
                                                placeholder="Email"
                                                label="Email"
                                                fullWidth
                                                inputProps={{maxLength: 50}}
                                                {...formik.getFieldProps("email")}
                                            />
                                            <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                            <CustomTextField
                                                fullWidth
                                                label="Phone number"
                                                onKeyDown={enforceFormat}
                                                onKeyUp={formatToPhone}
                                                inputProps={{maxLength: 14}}
                                                // {...(formik.values.phone && {
                                                //     InputProps: {
                                                //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                                //     }
                                                // })}
                                                {...formik.getFieldProps("phone")}
                                            />
                                            <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}> Address</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                    <CustomSelect
                                        label="Address"
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                color: "#212121",
                                                fontSize: "14px",
                                                fontWeight: 500
                                            }
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 200
                                                }
                                            }
                                        }}
                                        ellipSizeNumber={60}
                                        onChange={setSelectedAddress(formik.values.address)}
                                        menuItems={addressList?.map((obj) => ({label: obj?.delivery_address, id: obj?.address_id}))}
                                        {...formik.getFieldProps("address")}
                                    />
                                    <ErrorMessage name="address" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    <Box
                                        mt={2}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                cursor: "pointer",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                            onClick={(e) => {
                                                handleAddCategory("addAddressForm")(e);
                                                toggleDrawer("right", "addAddressForm")(e);
                                            }}
                                        >
                                            <Box sx={{height: "15px"}}>
                                                <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                            </Box>

                                            <Typography
                                                sx={{
                                                    marginLeft: "5px",
                                                    fontWeight: 600,
                                                    color: (theme) => theme.palette.primary.main
                                                }}
                                            >
                                                Add new address
                                            </Typography>
                                        </Box>
                                        {selectedAddress !== "" && (
                                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <Box
                                                    onClick={(e) => {
                                                        handleAddCategory("editAddress")(e);
                                                        toggleDrawer("right", "addAddressForm")(e);
                                                    }}
                                                    sx={{
                                                        marginLeft: "5px",
                                                        marginRight: "8px",
                                                        fontWeight: 600,
                                                        color: (theme) => theme.palette.primary.main,
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    Edit
                                                </Box>
                                                <Box
                                                    sx={{height: "15px", cursor: "pointer"}}
                                                    onClick={(e) => {
                                                        setSelectEditAddress(selectedAddress);
                                                        handleDeleteModal("address");
                                                    }}
                                                >
                                                    <img src={DeleteIcon} alt="delete" />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}> Payment</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                                    <CustomSelect
                                        label="Payment"
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                color: "#212121",
                                                fontSize: "14px",
                                                fontWeight: 500
                                            }
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    maxHeight: 200
                                                }
                                            }
                                        }}
                                        menuItems={cardList}
                                        numberValidation
                                        value={formik.values.addCard || defaultCardId}
                                        onChange={(e) => {
                                            const id = e.target.value;
                                            formik.setFieldValue("addCard", id);
                                        }}
                                    />
                                    <ErrorMessage name="addCard" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                    <Box>
                                        <Box
                                            mt={2}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Box
                                                onClick={(e) => {
                                                    handleAddCategory("addNewCardForm")(e);
                                                    toggleDrawer("right", "addNewCardForm")(e);
                                                }}
                                                sx={{
                                                    display: "flex",
                                                    cursor: "pointer",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Box sx={{height: "15px"}}>
                                                    <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        marginLeft: "5px",
                                                        fontWeight: 600,
                                                        color: (theme) => theme.palette.primary.main
                                                    }}
                                                >
                                                    Add new card
                                                </Typography>
                                            </Box>
                                            {formik.values.addCard !== "" || defaultCardId ? (
                                                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <Box
                                                        sx={{height: "15px", cursor: "pointer"}}
                                                        onClick={(e) => {
                                                            setSelectedCard(formik.values.addCard || defaultCardId);
                                                            handleDeleteModal("card");
                                                        }}
                                                    >
                                                        <img src={DeleteIcon} alt="delete" />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                            {/* <Box
                                            sx={{
                                                marginLeft: "5px",
                                                fontWeight: 600,
                                                color: (theme) => theme.palette.primary.main,
                                                cursor: "pointer"
                                            }}
                                        >
                                            Edit
                                        </Box> */}
                                        </Box>
                                    </Box>
                                    {!isUpdate && (
                                        <Box
                                            onClick={(e) => {
                                                navigate(
                                                    `/customers/${profileId}/?tab=houseAccount&customerName=${mrkName}&edit=true&show=true`
                                                );
                                            }}
                                            mt={1}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                cursor: "pointer"
                                            }}
                                        >
                                            <img src={PlusIcon} alt="plus-icon" width="15px" height="15px" />
                                            <Typography
                                                sx={{
                                                    marginLeft: "5px",
                                                    fontWeight: 600,
                                                    color: (theme) => theme.palette.primary.main
                                                }}
                                            >
                                                Create house account
                                            </Typography>
                                        </Box>
                                    )}
                                </Grid>
                                <CompanyDetails formik={formik} />
                                <SalesTaxExempt formik={formik} />
                                <Grid item xs={12} sm={6} md={4} mt={1} xl={3.5}>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontSize: "1.375rem", fontWeight: 600, color: "#000"}}>Account Manager</Typography>
                                    </Grid>
                                    <Box mt={1}>
                                        {" "}
                                        <CustomSelect
                                            label="Account Manager (optional)"
                                            menuItems={managers}
                                            {...formik.getFieldProps("accountManager")}
                                        />
                                        {formik?.values?.accountManager !== "" && (
                                            <Button
                                                variant="text"
                                                sx={{fontSize: "0.937rem", fontWeight: 600, color: "#00ab3a"}}
                                                onClick={() => formik.setFieldValue("accountManager", "")}
                                            >
                                                Unassign
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <ButtonGrouping
                                        btnprops={[
                                            {
                                                btnTitle: "Cancel",
                                                sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                                onClick: (e) => {
                                                    formik.resetForm();
                                                    navigate("/customers?tab=customer");
                                                }
                                            },
                                            {
                                                btnTitle: "Save",
                                                variant: "contained",
                                                sx: (t) => ({color: t.palette.background.paper, width: "92px"}),
                                                onClick: (e) => {
                                                    formik.handleSubmit();
                                                }
                                            }
                                        ]}
                                    />
                                </Grid>
                                <DeleteModal
                                    open={open}
                                    buttonTitle="Delete"
                                    title={title}
                                    description="This will remove this from the list"
                                    handleClose={handleDeleteModal}
                                    handleSubmit={() => {
                                        if (deleteType === "card") {
                                            handleDeleteCard();
                                        } else if (deleteType === "address") {
                                            handleDeleteAddress(formik);
                                        }
                                    }}
                                />
                            </Grid>
                        );
                    }}
                </Formik>
            ) : (
                <ProfileView />
            )}
        </>
    );
}

export default Profile;
