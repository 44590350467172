import React, {useState} from "react";
import {Stack, Typography, useMediaQuery, useTheme, Box, Grid, FormControlLabel, Checkbox} from "@mui/material";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import TemporaryDrawer from "commonComponent/CommonSideBar/CommonSideBar";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import {
    enforceNumericAndMaxLength,
    enforceDateAndMaxLength,
    formatExpiryDate,
    onlyNumbersWithOutDecimal
} from "utils/helperFunctions/helpers";
import {ErrorMessage, Formik, Form} from "formik";
import useTempDrawer from "hooks/useTempDrawer";
import {useSelector} from "react-redux";
import {addNewCardValidation} from "constants/validationConstant";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import {dispatch} from "store";
import Unchecked from "assets/images/icons/unchecked.svg";

const defaultInitialValues = {
    name: "",
    card_number: "",
    month: "",
    year: "",
    cvv: "",
    is_default_card: true
};

const AddNewCardForm = ({setIsAddNewCardFormOpen, userID, handleCardUpdated}) => {
    const headerData = useSelector((state) => state.headerData);
    const {toggleDrawer} = useTempDrawer();
    const [submitEvent, setSubmitEvent] = useState(null);

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("sm"));

    function convertToTwoDigit(number) {
        return number < 10 ? `0${number}` : `${number}`;
    }

    const handleSaveDetails = async (detail, setSubmitting, e) => {
        setSubmitting(true);

        const paylaod = {
            user_id: userID,
            name_on_card: detail.name,
            card_number: detail.card_number,
            expiration_date: `${convertToTwoDigit(detail.month)}/${detail.year}`,
            cvv: detail.cvv,
            is_default_card: detail.is_default_card
        };

        const instance = NetworkManager(API.CARD.ADD);
        const res = await instance.request(paylaod);
        console.log(res, "resss");
        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
            if (e) {
                toggleDrawer("right", false)(e);
            }
            handleCardUpdated();
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Enter valid card details",
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }

        setSubmitting(false);
    };

    return (
        <>
            <Formik
                enableReinitialize
                validationSchema={addNewCardValidation}
                initialValues={defaultInitialValues}
                onSubmit={(val, {setSubmitting}) => handleSaveDetails(val, setSubmitting, submitEvent)}
            >
                {(formik) => (
                    <Form>
                        <TemporaryDrawer sideDrawerState="addNewCardForm">
                            <Stack sx={{height: "100%"}} direction="column" justifyContent="space-between" padding={1}>
                                <Box>
                                    <Typography sx={{fontSize: "1.625rem", fontWeight: 700, color: "#000"}}>Add new card</Typography>
                                    <Grid container gap={2} sx={{marginTop: "28px"}}>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                label="Name on card"
                                                placeholder="Name on card"
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                                                }}
                                                {...formik.getFieldProps("name")}
                                            />
                                            <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                placeholder="Card number"
                                                label="Card number"
                                                onKeyDown={enforceNumericAndMaxLength(formik.getFieldProps("card_number").value, 16)}
                                                {...formik.getFieldProps("card_number")}
                                            />
                                            <ErrorMessage name="card_number" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{minWidth: "200px"}}>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="number"
                                                        placeholder="MM"
                                                        label="Expiration month"
                                                        inputProps={{maxLength: 2}}
                                                        onKeyDown={onlyNumbersWithOutDecimal}
                                                        {...formik.getFieldProps("month")}
                                                    />
                                                    <ErrorMessage
                                                        name="month"
                                                        render={(msg) => <CustomErrorMessage errorMessage={msg} />}
                                                    />
                                                </Box>
                                                <Box sx={{color: "#9E9E9E", width: "16px", textAlign: "center", marginTop: "15px"}}>/</Box>
                                                <Box sx={{minWidth: "200px"}}>
                                                    <CustomTextField
                                                        fullWidth
                                                        type="number"
                                                        placeholder="YY"
                                                        label="Expiration year"
                                                        inputProps={{maxLength: 2}}
                                                        onKeyDown={onlyNumbersWithOutDecimal}
                                                        {...formik.getFieldProps("year")}
                                                    />
                                                    <ErrorMessage name="year" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                fullWidth
                                                placeholder="CVV"
                                                label="CVV"
                                                onKeyDown={enforceNumericAndMaxLength(formik.getFieldProps("cvv").value, 4)}
                                                {...formik.getFieldProps("cvv")}
                                            />
                                            <ErrorMessage name="cvv" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formik.values.is_default_card}
                                                        icon={<img src={Unchecked} alt="checked" />}
                                                        onChange={(e) =>
                                                            e.target.checked
                                                                ? formik.setFieldValue("is_default_card", true)
                                                                : formik.setFieldValue("is_default_card", false)
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography sx={{fontSize: "0.875rem", fontWeight: 600, color: "#25272B"}}>
                                                        Make this default card
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{position: "fixed", bottom: 40, height: "52px", width: !matchUpMd ? "290px" : "420px"}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box sx={{position: "sticky", bottom: 0, mt: 2, p: 2, bgcolor: "#fff", zIndex: 1}}>
                                                <ButtonGrouping
                                                    btnprops={[
                                                        {
                                                            btnTitle: "Cancel",
                                                            sx: (t) => ({color: t.palette.error.dark, width: "92px"}),
                                                            onClick: (e) => {
                                                                setIsAddNewCardFormOpen(false);
                                                                formik.resetForm();
                                                                toggleDrawer("right", false)(e);
                                                            }
                                                        },
                                                        {
                                                            btnTitle: "Save",
                                                            variant: "contained",
                                                            disabled: formik.isSubmitting,
                                                            onClick: (e) => {
                                                                setSubmitEvent(e);
                                                                formik.handleSubmit();
                                                                formik.validateForm().then((res) => {
                                                                    // to close the sidebar if no field has validation error
                                                                    if (Object.keys(res).length === 0) {
                                                                        setTimeout(() => {
                                                                            formik.resetForm();
                                                                        }, 1500);
                                                                    }
                                                                });
                                                                // toggleDrawer("right", false)(e);
                                                            },
                                                            sx: (t) => ({color: t.palette.background.paper, width: "92px"})
                                                        }
                                                    ]}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </TemporaryDrawer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddNewCardForm;
