import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, FormControlLabel, Radio, RadioGroup, InputAdornment } from "@mui/material";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { enforceFormat, formatToPhone, enforceNumericAndMaxLength } from "utils/helperFunctions/helpers";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import ButtonGrouping from "commonComponent/Buttons/ButtonGroup";
import CustomRadioButton from "commonComponent/Form/Fields/CustomRadioButton";
import { API, NetworkManager } from "network/core";
import { openSnackbar } from "store/slices/snackbar";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { addNewHouseCardValidation } from "constants/validationConstant";
import HouseAccountView from "./HouseAccountView/HouseAccountView";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const formSchema = {
    customer_name: "",
    company: "",
    payment: "",
    bank_routing_no: "",
    bank_account_no: "",
    paymentTerm: "Due on recepit",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: ""
};

function HouseAccount({ props }) {
    const [initialValues, setInitialValues] = useState(formSchema);

    const prm = useParams();
    const [urlSearchParams] = useSearchParams();

    const editMode = urlSearchParams.get("edit");
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [houseData, setHouseData] = useState([]);
    const [pincode, setPincode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [street, setStreet] = useState("");
    const [country, setCountry] = useState("");
    const customerId = prm?.type;
    const [existingCompany, setExistingCompany] = useState([]);
    const [companySearch, setCompanySearch] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getCustomerProfileData = async () => {
        const instance = NetworkManager(API.CUSTOMER.GETCUSTOMERDATA(`${customerId}`));
        const response = await instance.request();
        if (response.success) {
            setCustomerData(response?.data);

            setLoading(false);
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getHouseAccountData = async () => {
        const instance = NetworkManager(API.CARD.HOUSEACCOUNTDATA(customerId));

        const response = await instance.request();
        if (response.success) {
            const data = response.data;
            if (response.data?.customer_card_id) {
                setIsUpdate(true);
                setHouseData(response.data);
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Error fetching existing companies",
                    variant: "error",
                    close: true
                })
            );
        }
    };
    useEffect(() => {
        getHouseAccountData();
        getCustomerProfileData();
    }, [customerId]);

    const getExistingCompany = async () => {
        const instance = NetworkManager(API.COMPANY.GET);
        const response = await instance.request({}, [`?company_name=${companySearch}`]);
        if (response.success) {
            const data = response.data.companyList;
            setExistingCompany(data);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Error fetching existing companies",
                    variant: "error",
                    close: true
                })
            );
        }
    };

    const createCompany = async (name) =>
        new Promise((resolve) => {
            const instance = NetworkManager(API.COMPANY.ADD);
            instance.request({ company_name: name }).then((response) => {
                if (response.success) {
                    resolve(response.data?.company?.company_id);
                }
            });
        });

    useEffect(() => {
        if (companySearch) {
            getExistingCompany();
        } else {
            setExistingCompany([]);
        }
    }, [companySearch, dispatch]);

    const stopPropagationForTab = (event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    };

    const handleCompanyClick = () => {
        getExistingCompany();
    };

    const handleSaveDetails = async (detail, setSubmitting) => {
        setSubmitting(true);
        let paylaod = {};

        if (detail.cardType === "ACH") {
            paylaod = {
                card_type: "HOUSE_ACCOUNT",
                payment_term: detail.paymentTerm,
                routing_number: `${detail.routingNumber}`,
                bank_account: `${detail.bankAccount}`,
                billing_first_name: detail.first_name,
                billing_last_name: detail.last_name,
                billing_email: detail.email,
                billing_phone: detail.phone,
                billing_address: detail.billingAddress,
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country:country
                }
            };
        } else {
            paylaod = {
                card_type: "HOUSE_ACCOUNT",
                payment_term: detail.paymentTerm,
                billing_first_name: detail.first_name,
                billing_last_name: detail.last_name,
                billing_email: detail.email,
                billing_phone: detail.phone,
                billing_address: detail.billingAddress,
                check_number: detail.checkNumber,
                address_details: {
                    street_line: street,
                    city: city,
                    state: state,
                    pincode: pincode,
                    country:country
                }
            };
        }

        const instance = isUpdate
            ? NetworkManager(API.CARD.HOUSEACCOUNTUPDATE(customerId))
            : NetworkManager(API.CARD.HOUSEACCOUNT(customerId));

        const res = await instance.request(paylaod);

        if (res.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: true
                })
            );
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
        }
        navigate(-2);
        setSubmitting(false);
    };

    const userName = (row) => {
        return row?.full_name + (row?.last_name ? ` ${row?.last_name}` : "") || " "
    }

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

     const parseAddressComponents = async (addressComponents) => {
        if (addressComponents) {
            let newPincode = "";
            let newCity = "";
            let newState = "";
            let newStreetLine = "";
            let newCountry="";
            for (let i = 0; i < addressComponents.length; i+=1) {
                const component = addressComponents[i];
                if (component.types.includes("postal_code")) {
                    
                    newPincode = component.long_name;
                } else if (component.types.includes("locality") ) {
                    
                    newCity = component.short_name;
                }
                else if (component.types.includes("administrative_area_level_2")) {
                    
                    newState += component.short_name + " ";// eslint-disable-line prefer-template
                }
                 else if (component.types.includes("administrative_area_level_1")) {
                    
                    newState += component.short_name;
                }
                else if (component.types.includes("premise")) {
                    
                    newStreetLine += component.short_name +", ";// eslint-disable-line prefer-template
                }
                else if (component.types.includes("subpremise")) {
                    
                    newStreetLine += component.short_name +", ";// eslint-disable-line prefer-template
                } else if (component.types.includes("route")) {
                    
                    newStreetLine += component.short_name;
                } else if (component.types.includes("street_number")) {
                    
                    newStreetLine += component.short_name + " ";// eslint-disable-line prefer-template
                } else if (component.types.includes("point_of_interest")) {
                    
                    newStreetLine += component.short_name + ", ";// eslint-disable-line prefer-template
                }
                else if(component.types.includes("country")){
                    newCountry = component.short_name;
                }
            }
            setPincode(newPincode);
            setCity(newCity);
            setState(newState);
            setStreet(newStreetLine);
            setCountry(newCountry)
        }
    };

    return editMode ? (
        <>
            <Formik
                enableReinitialize
                validationSchema={addNewHouseCardValidation}
                initialValues={{
                    name: userName(customerData),
                    company: customerData?.company?.company_name,
                    cardType: houseData?.bank_account ? "ACH" : "check",
                    paymentTerm: houseData?.payment_term || "DUE_ON_RECEIPT",
                    routingNumber: houseData?.routing_number || "",
                    bankAccount: houseData?.bank_account || "",
                    first_name: houseData?.billing_first_name || "",
                    last_name: houseData?.billing_last_name || "",
                    email: houseData?.billing_email || "",
                    phone: houseData?.billing_phone || "",
                    billingAddress: houseData?.billing_address || "",
                    checkNumber: "1111111111"
                }}
                onSubmit={(val, { setSubmitting }) => handleSaveDetails(val, setSubmitting)}

            // validationSchema={yup.object({
            //     customer_name: yup.string().required("Customer name is required").nullable().trim(),
            //     payment: yup.string().required("Payment type is required").nullable().trim(),
            //     bank_routing_no: yup.string().required("Bank routing number is required").nullable().trim(),
            //     bank_account_no: yup.string().required("Bank account number is required").nullable().trim(),
            //     first_name: yup.string().required("First name is required").nullable().trim(),
            //     last_name: yup.string().required("Last name is required").nullable().trim(),
            //     email: yup.string().required("Email is required").email("Enter valid email").nullable().trim(),
            //     phone: yup.string().required("Phone number is required").nullable().trim(),
            //     paymentTerm: yup.string().required("Payment term is required").nullable().trim()
            // })}
            >
                {(formik) => (
                    <Grid container spacing={2}>
                        <Grid item xs={12} mt={2}>
                            <Typography variant="body4" color="#000" fontSize="1.375rem" fontWeight="600">
                                Customer
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                            <CustomTextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                label="Customer name"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                {...formik.getFieldProps("name")}
                            />
                            <ErrorMessage name="name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={1} xl={3.5} sx={{ position: "relative" }}>
                            <CustomTextField
                                disabled
                                InputProps={{
                                    readOnly: true
                                }}
                                {...props}
                                fullWidth
                                name="company"
                                label="Company (optional)"
                                onKeyDown={stopPropagationForTab}
                                value={formik.values.company || ""}
                                onClick={handleCompanyClick}
                                onChange={(e) => {
                                    setCompanySearch(e.target.value);
                                    formik.setFieldValue("company", e.target.value);
                                }}
                                onBlur={async () => {
                                    if (companySearch) {
                                        const companyId = await createCompany(companySearch);
                                        formik.setFieldValue("companyId", companyId);
                                    }
                                }}
                            />
                            {!!existingCompany?.length && (
                                <Box
                                    sx={{
                                        p: 1,
                                        boxShadow: "0px 0px 3px rgba(0,0,0,0.3)",
                                        borderRadius: 0.5,
                                        position: "absolute",
                                        width: "100%",
                                        bgcolor: "#fff",
                                        maxHeight: "200px",
                                        overflow: "auto",
                                        zIndex: 1380
                                    }}
                                >
                                    {existingCompany?.map((obj, i) => (
                                        <Box
                                            key={i}
                                            sx={{ p: 0.2, py: 0.2, cursor: "pointer", "&:hover": { bgcolor: "#Fafafa" } }}
                                            onClick={() => {
                                                formik.setFieldValue("company", obj.company_name);
                                                formik.setFieldValue("companyId", obj.company_id);
                                                setExistingCompany([]);
                                            }}
                                        >
                                            <Typography variant="body3" fontSize="0.875rem" fontWeight={500}>
                                                {obj?.company_name}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography variant="body4" color="#000" fontSize="1.375rem" fontWeight="600">
                                Invoice settings
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={3.5} mt={0.5}>
                            <Typography sx={{ fontSize: "0.875rem", fontWeight: 700 }}>Payment terms</Typography>
                            <Typography mt={0.4} sx={{ fontSize: "0.813rem", fontWeight: 500 }}>
                                Days until payment is due, upon invoice generation
                            </Typography>
                            <Box mt={2}>
                                <CustomRadioButton
                                    options={[
                                        {
                                            label: "Due on receipt",

                                            value: "DUE_ON_RECEIPT"
                                        },
                                        { label: "Net 10", value: "NET_10" },
                                        { label: "Net 15", value: "NET_15" },
                                        { label: "Net 30", value: "NET_30" },
                                        { label: "Net 60", value: "NET_60" }
                                    ]}
                                    value={formik.values.paymentTerm}
                                    onChange={(e) => formik.setFieldValue("paymentTerm", e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                            <Box>
                                <CustomSelect
                                    label="Payment type"
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 200
                                            }
                                        }
                                    }}
                                    fullWidth
                                    noNone
                                    menuItems={[
                                        {
                                            label: "Check",
                                            id: "check"
                                        },
                                        {
                                            label: "ACH",
                                            id: "ACH"
                                        }
                                    ]}
                                    {...formik.getFieldProps("cardType")}
                                />

                                <ErrorMessage name="cardType" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Box>

                            {formik.values.cardType === "ACH" && (
                                <Box mt={2.5}>
                                    <CustomTextField
                                        fullWidth
                                        type="number"
                                        label="Bank routing number"
                                        placeholder="Bank routing number"
                                        {...formik.getFieldProps("routingNumber")}
                                    />
                                    <ErrorMessage name="routingNumber" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Box>
                            )}
                            {formik.values.cardType === "ACH" && (
                                <Box mt={2.5}>
                                    <CustomTextField
                                        fullWidth
                                        type="number"
                                        label="Bank account number"
                                        placeholder="Bank account number"
                                        {...formik.getFieldProps("bankAccount")}
                                    />
                                    <ErrorMessage name="bankAccount" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Box>
                            )}
                            {formik.values.cardType === "NOT REQUIRED" && (
                                <Box mt={2.5}>
                                    <CustomTextField
                                        fullWidth
                                        label="Check number"
                                        type="number"
                                        placeholder="Check number"
                                        {...formik.getFieldProps("checkNumber")}
                                    />
                                    <ErrorMessage name="checkNumber" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Typography variant="body4" color="#000" fontSize="1.375rem" fontWeight="600">
                                Account payable details
                            </Typography>
                            <Typography mt={1.5} sx={{ fontSize: "0.812rem", fontWeight: 500, color: "#26272A" }}>
                                This is your billing contact. Their information will appear on invoices that you generate for this House
                                Account.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                            <CustomTextField
                                placeholder="First Name"
                                label="First Name"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                {...formik.getFieldProps("first_name")}
                            />
                            <ErrorMessage name="first_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={3.5} mt={1}>
                            <CustomTextField
                                placeholder="Last Name"
                                label="Last Name"
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                {...formik.getFieldProps("last_name")}
                            />
                            <ErrorMessage name="last_name" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                    <CustomTextField
                                        placeholder="Email"
                                        label="Email"
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        {...formik.getFieldProps("email")}
                                    />
                                    <ErrorMessage name="email" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} xl={3.5}>
                                    <CustomTextField
                                        fullWidth
                                        label="Phone number"
                                        onKeyDown={enforceFormat}
                                        onKeyUp={formatToPhone}
                                        inputProps={{ maxLength: 14 }}
                                        // {...(formik.values.phone && {
                                        //     InputProps: {
                                        //         startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                        //     }
                                        // })}
                                        {...formik.getFieldProps("phone")}
                                    />
                                    <ErrorMessage name="phone" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} xl={3.5}>

                                    {window.google && (
                                        <PlacesAutocomplete
                                            value={formik.values.address}
                                            {...formik.getFieldProps("billingAddress")}
                                            onChange={formik.handleChange(`billingAddress`)}

                                            searchOptions={{
                                                componentRestrictions: { country: "us" }
                                            }}

                                            onSelect={(value) => {
                                                const setletlong = async () => {
                                                    const result = await geocodeByAddress(value);
                                                    await parseAddressComponents(result[0]?.address_components);
                                                    const ll = await getLatLng(result[0]);

                                                    const addressWithoutCountry = result[0].formatted_address.replace(/,\sUSA$/, '');
                                                    formik.setFieldValue(`billingAddress`, addressWithoutCountry);
                                                    formik.setFieldValue(`latitude`, ll.lat);
                                                    formik.setFieldValue(`longitude`, ll.lng);
                                                };
                                                setletlong();
                                            }}
                                            onError={onError}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div style={{ position: "relative" }}>
                                                    <CustomTextField
                                                        fullWidth
                                                        label="Billing address (optional)"
                                                        value={formik.values.billingAddress}

                                                        {...getInputProps({
                                                            placeholder: "",
                                                            className: "location-search-input",
                                                            onKeyDown: stopPropagationForTab
                                                        })}
                                                        autoComplete='followmore'
                                                                id='falcon'
                                                                name={`random-name-${Math.random()}`}
                                                    />
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            zIndex: 1000,
                                                            width: "100%",
                                                            boxShadow: "0px 0px 5px rgba(0,0,0,0.5)"
                                                        }}
                                                    >
                                                        {loading && (
                                                            <div style={{ padding: "6px 12px", backgroundColor: "#ffffff" }}>Loading...</div>
                                                        )}
                                                        {suggestions.map((suggestion, index) => {
                                                            const style = suggestion.active
                                                                ? {
                                                                    cursor: "pointer",
                                                                    color: "#191919",
                                                                    fontSize: "16px",
                                                                    padding: "6px 16px",
                                                                    backgroundColor: "#F5F7FB"
                                                                }
                                                                : {
                                                                    backgroundColor: "#ffffff",
                                                                    color: "#191919",
                                                                    fontSize: "16px",
                                                                    padding: "6px 16px",
                                                                    cursor: "pointer"
                                                                };
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        style
                                                                    })}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={4}>
                            <ButtonGrouping
                                btnprops={[
                                    {
                                        btnTitle: "Cancel",
                                        sx: (t) => ({ color: t.palette.error.dark }),
                                        onClick: (e) => {
                                            formik.resetForm();
                                            navigate(-1);
                                        }
                                    },
                                    {
                                        btnTitle: "Save",
                                        variant: "contained",
                                        sx: (t) => ({ color: t.palette.background.paper }),
                                        onClick: (e) => {
                                            formik.handleSubmit();

                                            // formik.validateForm().then((res) => {
                                            //     if (Object.keys(res).length === 0) {
                                            //         setTimeout(() => {
                                            //             formik.resetForm();
                                            //         }, 1500);
                                            //     }
                                            // });
                                        }
                                    }
                                ]}
                            />
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    ) : (
        <HouseAccountView />
    );
}

export default HouseAccount;
