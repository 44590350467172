// import {fontSize, maxHeight, useTheme} from "@mui/system";

// export const useStyles = () => {
//     const theme = useTheme();
//     return {
//         image: {
//             // height: "44.2px",
//             height:'auto',
//             width: "120.9px",
//             maxHeight: '54px'
//         },
//         titleContainer: {
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             flexDirection: "row",
//             position:'relative'
//         },
//         title: {
//             fontWeight: 500,
//             fontSize: "18px",
//             lineHeight: "21.78px",

//             textAlign: "right"
//         },
//         orderId: {
//             fontSize: "16px",
//             fontWeight: 600,
//             lineHeight: "20.8px",
//             textAlign: "left"
//         },
//         deliveryDate: {
//             fontSize: "12px",
//             fontWeight: 400,
//             lineHeight: "16.8px",
//             color: "#8F9198"
//         },
//         subDetail: {
//             fontSize: "14px",
//             fontWeight: 600,
//             lineHeight: "18.2px"
//         },
//         color: {
//             color: "#26272A"
//         },
//         color2: {
//             color: "#8F9198"
//         },
//         detailTitle: {
//             fontSize: "10px",
//             fontWeight: 500,
//             lineHeight: "12.5px",
//             paddingBottom: "2.902px"
//         },
//         spcl: {
//             fontSize: "11px",
//             fontStyle: "normal",
//             fontWeight: 400,
//             lineHeight: "175%"
//         },

//         detail: {
//             color: " #26272A",
//             fontFamily: "Inter",
//             fontSize: "11px",
//             fontStyle: "normal",
//             fontWeight: 400,
//             lineHeight: 1.25, // Converted 125% to a decimal value
//             letterSpacing: "-0.7px",
//             wordBreak: "break-all"
//         },
//         detail1: {
//             color: " #26272A",
//             fontFamily: "Inter",
//             fontSize: "11px",
//             fontStyle: "normal",
//             fontWeight: 400,
//             textAlign: "right",
//             lineHeight: 1.25, // Converted 125% to a decimal value
//             letterSpacing: "-0.7px",
//             wordBreak: "break-all"
//         },
//         pricing: {
//             fontSize: "11px",
//             fontStyle: "normal",
//             fontWeight: 400,
//             lineHeight: "125%" // Converted 125% to a decimal value
//         },
//         medium:{
//             fontWeight:'500 !important'
//         },
//         backPrice: {
//             background: "rgba(217,217,217,0.2)"
//         },
//         total: {
//             fontSize: "11px",
//             fontStyle: "normal",
//             lineHeight: 1.25 // Converted 125% to a decimal value
//         },
//         quoteBtn: {
//             color: "#00AB3A",
//             fontSize: "0.8125rem",
//             width: "168px",
//             height: "36px",
//             fontWeight: 600,
//             border: "1px solid #00AB3A",
//             marginRight: "0.3em",
//             display: "flex",
//             alignItems: "center",
//             gap: "4px"
//         },
//         hr: {
//             width: "100%",
//             border: "1px solid #f0f0f0",
//             marginBottom: "16px"
//         },
//         hr1: {
//             width: "100%",
//             border: "1px solid #f0f0f0",
//             margin:0
//         },
//         itemName: {
//             color: "var(--Grey-Dark, #26272A)",
//             fontSize: "11px",
//             fontStyle: "normal",
//             fontWeight: 400,
//             lineHeight: "125%",
//             marginBottom: "3px"
//         },
//         modifierList: {
//             fontSize: "10px",
//             fontStyle: "normal",
//             fontWeight: 500,
//             lineHeight: "125%"
//         },
//         modifierList1: {
//             fontSize: "10px",
//             fontStyle: "normal",
//             fontWeight: 500,
//             lineHeight: "155%"
//         },

//         instructions: {
//             fontSize: "13px",
//             fontStyle: "normal",
//             fontWeight: 500,
//             lineHeight: "125%"
//         },
//         instructions1: {
//             fontSize: "13px",
//             fontStyle: "normal",
//             fontWeight: 500,
//             lineHeight: "155%"
//         },
//         lineImpHeight: {
//             lineHeight: "155% !important"
//         },
//         paddingBott: {
//             paddingBottom: "2.902px"
//         },

//     };
// };

import {display, fontSize, useTheme} from "@mui/system";

export const useStyles = () => {
    const theme = useTheme();
    return {
        image: {
            // height: "44.2px",
            height: "auto",
            width: "126px"
        },
        titleContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row"
        },
        title: {
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "21.78px",

            textAlign: "right"
        },
        orderId: {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "20.8px",
            textAlign: "left"
        },
        deliveryDate: {
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "16.8px",
            color: "#8F9198"
        },
        subDetail: {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "18.2px"
        },
        color: {
            color: "#26272A"
        },
        color2: {
            color: "#8F9198"
        },
        detailTitle: {
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "12.5px",
            // paddingBottom: "2.902px",
            color: " #A7A7A7"
        },
        spcl: {
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "175%"
        },

        detail: {
            color: " #26272A",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "17.5px", // Converted 125% to a decimal value
            letterSpacing: "-0.7px",
            textAlign: "left",
            wordBreak: "break-all"
        },
        detail1: {
            color: " #26272A",
            fontFamily: "Inter",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: 400,
            textAlign: "right",
            lineHeight: 1.25, // Converted 125% to a decimal value
            letterSpacing: "-0.7px",
            wordBreak: "break-all"
        },
        pricing: {
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "125%" // Converted 125% to a decimal value
        },
        medium: {
            fontWeight: "500 !important"
        },
        backPrice: {
            background: "rgba(217,217,217,0.2)"
        },
        total: {
            fontSize: "11px",
            fontStyle: "normal",
            lineHeight: 1.25 // Converted 125% to a decimal value
        },
        quoteBtn: {
            color: "#00AB3A",
            fontSize: "0.8125rem",
            width: "168px",
            height: "36px",
            fontWeight: 600,
            border: "1px solid #00AB3A",
            marginRight: "0.3em",
            display: "flex",
            alignItems: "center",
            gap: "4px"
        },
        hr: {
            width: "100%",
            border: "1px solid #f0f0f0",
            marginBottom: "16px"
        },
        hr1: {
            width: "100%",
            border: "0.7px solid #f0f0f0",
            margin: 0
        },
        itemName: {
            color: "var(--Grey-Dark, #26272A)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "17.5px"
            // marginBottom: "3px"
        },
        modifierList: {
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "18.67px"
        },
        modifierList1: {
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "155%"
        },

        instructions: {
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "125%"
        },
        instructions1: {
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "155%"
        },
        lineImpHeight: {
            lineHeight: "155% !important"
        },
        paddingBott: {
            paddingBottom: "2.902px"
        },
        
    };
};
