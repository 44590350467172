import {Grid} from "@mui/material";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import DeleteModal from "commonComponent/DilogBox/DeleteModal";
import CommonTable from "commonComponent/TableSection/CommonTable";
import {useDispatch, useSelector} from "store";
import useAuth from "hooks/useAuth";
import {openSnackbar} from "store/slices/snackbar";
import {NetworkManager, API} from "network/core";
import Filters from "commonComponent/Filters";
import {generateUrl} from "utils/helperFunctions/helpers";
import FilterChips from "commonComponent/FilterChipsComponent";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import useToggleDilog from "hooks/useToggleDilog";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const TemplatesList = (props) => {
    const {search, showFilter, handleHeaderActions, setSelectedCheckbox, updateListCount, selectedCheckbox, filterChips} = props;
    const [open, setOpen] = React.useState(false);
    const {toggleDilog} = useToggleDilog();
    const [templateListing, setTemplateListing] = React.useState([]);
    const {orderUpdate} = useAuth();
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "template_name");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [filterChips, setFilterChips] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDeleteModal = () => {
        setOpen((pre) => !pre);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [filterChips]);

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.MENU.STATUS(values.menu_id));

        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            status: JSON.stringify(isActive)
        };

        const response = await instance.request(payload);

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );

            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const getTemplateList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.TEMPLATE.GET);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,
            ...(moreFilters && {...moreFilters})
        };
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }
        // while searching we dont need page number
        if (search === "") {
            params.offset = currentPage;
        }

        if (search !== "") {
            params.search = search.trim();
            setTemplateListing([]);
        }
        const url = generateUrl(params);
        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempList = [];
            response.data.orderTemplate.forEach((row) => {
                const tempObj = {};
                tempObj.brand_id = row.brand_id;
                tempObj.brand_name = row.brand?.brand_name;
                tempObj.order_template_id = row?.order_template_id;
                tempObj.template_name = row.order_template_name;
                tempObj.createdAt = row.created_at;
                tempObj.menu_name = row.menu.menu_name;
                tempObj.menu_id = row.menu_id;
                tempObj.services = row?.service_type;
                tempObj.guest = row?.no_of_guest;
                tempObj.createdBy = `${row?.user?.first_name ?? ""} ${row?.user?.last_name !== null ? row?.user?.last_name : ""}`;
                tempList.push(tempObj);
            });

            setTemplateListing(tempList);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleDuplicate = async (id, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.TEMPLATE.DUPLICATE(id));
        const response = await instance.request();

        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        getTemplateList();
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "Edit": {
                toggleDilog("CreateTemplate")();
                navigate(`/menus?tab=templates&templateId=${row.order_template_id}`);
                break;
            }
            case "Delete": {
                handleDeleteModal();
                break;
            }
            case "Duplicate": {
                handleDuplicate(row.order_template_id);
                break;
            }
            case "Deactivate": {
                handleStatus(row, type);
                break;
            }
            case "Activate": {
                handleStatus(row, type);
                break;
            }
            default:
                break;
        }
    };

    const handleDelete = async () => {
        const instance = NetworkManager(API.TEMPLATE.BULKDELETE);
        const payload = [selectedRow?.order_template_id];
        const response = await instance.request({orderTemplateIds: payload});
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
        setSelectedRow(null);
        handleDeleteModal();
        getTemplateList();
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };
    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));

        switch (val2) {
            case "template_name":
                dispatch(setOrderBy("templateName"));
                break;
            case "templateBrandName":
                dispatch(setOrderBy("brandName"));
                break;
            case "templateMenuName":
                dispatch(setOrderBy("menuName"));
                break;
            case "templateService":
                dispatch(setOrderBy("service"));
                break;
            case "guest":
                dispatch(setOrderBy("guestCount"));
                break;
            default:
                dispatch(setOrderBy(val2));
                break;
        }
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getTemplateList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, orderUpdate, isEdited, filterChips, updateListCount]);

    const getBrandList = async () => {
        let tempData = [];
        const instance = NetworkManager(API.BRAND.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const BrandData = response.data.brandList
                ?.sort((a, b) => a?.brand_name?.localeCompare(b?.brand_name))
                ?.map((el) => ({id: `${el.brand_id}`, label: el.brand_name}));
            tempData = [...BrandData];
        }
        return tempData;
    };
    const getMenuList = async () => {
        let tempData = [];
        const instance = NetworkManager(API.MENU.GET);
        const params = {
            page: 1,
            pageSize: 5000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const BrandData = response.data.menuList
                ?.sort((a, b) => a?.menu?.menu_name?.localeCompare(b?.menu?.menu_name))
                ?.map((el) => ({id: `${el.menu.menu_id}`, label: el.menu.menu_name}));
            tempData = [...BrandData];
        }
        return tempData;
    };
    const getTemplateFilterList = async () => {
        let tempData = [];
        const instance = NetworkManager(API.TEMPLATE.GET);
        const params = {
            offset: 1,
            limit: 5000,
            sortBy: "createdAt",
            orderBy: "desc"
        };

        const response = await instance.request({}, params);

        if (response.success) {
            const BrandData = response.data.orderTemplate
                ?.sort((a, b) => a?.order_template_name?.localeCompare(b?.order_template_name))
                ?.map((el) => ({id: `${el.order_template_id}`, label: el.order_template_name}));
            tempData = [...BrandData];
        }
        return tempData;
    };

    const filterState = [
        {
            key: "templateIds",
            label: "Template",
            type: "multiselect",
            getData: getTemplateFilterList
        },
        {
            key: "menuIds",
            label: "Menu",
            type: "multiselect",
            getData: getMenuList
        },
        {
            key: "brandIds",
            label: "Brand",
            type: "multiselect",
            getData: getBrandList
        },
        {
            key: "services",
            label: "Service",
            type: "multiselect",
            getData: () => [
                {id: "CATERING", label: "Catering"},
                {id: "HOME_MEALS", label: "Home meals"}
            ]
        }
    ];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <CommonTable
                        loading={loading}
                        withCheckbox
                        data={templateListing}
                        height="75vh"
                        header={[
                            {key: "template_name", label: "Template"},
                            {key: "templateBrandName", label: "Brand"},
                            {key: "templateMenuName", label: "Menu"},
                            {key: "templateService", label: "Service"},
                            {key: "guest", label: "Guest"},
                            {key: "createdBy", label: "Created by"},
                            {key: "createdAt", label: "Created"},

                            WithPermission({
                                module: "Templates",
                                permission: ["delete_permission", "create_permission", "update_permission"]
                            }) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        actions={[
                            WithPermission({module: "Templates", permission: "update_permission"}) && "Edit",
                            WithPermission({module: "Templates", permission: "update_permission"}) && "Duplicate",
                            WithPermission({module: "Templates", permission: "delete_permission"}) && "Delete"
                        ]}
                        handleActionBtn={handleActionBtn}
                        handleHeaderActions={handleHeaderActions}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        currentPage={currentPage - 1}
                        sortingLabel={["Template", "Menu", "Brand", "Service", "Guest", "Created by", "Created"]}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                    />
                </Grid>
            </Grid>

            <DeleteModal
                open={open}
                title="Are you sure you want to delete this template?"
                description="This will permanently remove all data associated with it."
                handleClose={handleDeleteModal}
                handleSubmit={handleDelete}
            />
        </>
    );
};

export default TemplatesList;
