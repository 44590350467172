import { Box, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { IconGripVertical } from "@tabler/icons";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { API } from "network/core";
import networkManager from "network/core/networkManager";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import DietaryOptions from "../DietaryPopup/DietaryPopup";
import { openSnackbar } from "store/slices/snackbar";
import { dispatch } from "store";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import { enforceFormat, twoDigitAfterDecimal,enforceFormatFor3Digits } from "utils/helperFunctions/helpers";
import ActionButton from "commonComponent/Apptable/ActionButton";
import { validationContant } from "utils/validations/validationConstants";

const defaultValue = {
    name: "",
    price: "",
    dietary: []
};

const EditModifier = (props) => {
    const { provided, dataObj, handleActionBtn, index, arrayHelper, modifierGroupId } = props;

    const [initialValue, setInitalValue] = useState(defaultValue);

    const handleEditModifier = async (val, setSubmitting) => {
        setSubmitting(true);

        const instance = networkManager(API.MODIFIER.UPDATE(val.id));

        const payload = {
            name: val.name,
            price: val.price || 0,
            modifier_group_id: [modifierGroupId],
            modifier_dietary: val.dietary,
            series_no:index,
        };

        const response = await instance.request(payload);

        if (response.success) {
            arrayHelper.replace(index, { ...dataObj, isEdit: false });

            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    transition: "Fade",
                    close: true
                })
            );
        }

        setSubmitting(false);
    };

    // useEffect(()=>{
    //     handleEditModifier({ id: dataObj?.id, name: dataObj?.name, price: dataObj?.price, dietary: dataObj.dietary })
    // },[index])

    useEffect(() => {
        setInitalValue({ id: dataObj?.id, name: dataObj?.name, price: dataObj?.price, dietary: dataObj.dietary });
    }, [dataObj]);

    return (
        <>
            <Formik
                initialValues={initialValue}
                enableReinitialize
                validationSchema={yup.object({
                    name: yup
                        .string()
                        .required("Modifier name is required")
                        .max(validationContant.maxCharLimit, "Only up to 250 characters allow"),
                    price: yup.string().test("is-decimal", "Two digits decimal", twoDigitAfterDecimal)
                })}
                onSubmit={(val, { setSubmitting }) => {
                    handleEditModifier(val, setSubmitting);
                }}
            >
                {(formik) => (
                    <Grid container spacing={1.5} alignItems="center">
                        <Grid item xs>
                            <Stack direction="row" alignItems={dataObj?.isEdit ? "center" : "flex-start"}>
                                <span {...provided.dragHandleProps}>
                                    <IconGripVertical icon="grip-vertical" style={{ color: "#E0E2E7" }} />
                                </span>
                                <Box sx={{ width: "100%" }}>
                                    {!dataObj?.isEdit ? (
                                        <Typography variant="body4" fontSize="13px" sx={{ mb: 2 }}>
                                            {formik.values.name}
                                        </Typography>
                                    ) : (
                                        <CustomTextField
                                            sx={{
                                                fontSize: "0.73em",

                                                "&": {
                                                    height: "40px !important",
                                                    "& .MuiInputBase-root > input": {
                                                        bgcolor: "#F0F0F0"
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        bgcolor: "#F0F0F0",
                                                        height: "40px",
                                                        overflow: "hidden",
                                                        "& >input": {
                                                            fontWeight: 500
                                                        }
                                                    }
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 80
                                            }}
                                            fullWidth
                                            placeholder=""
                                            {...formik.getFieldProps("name")}
                                        />
                                    )}
                                    <ErrorMessage
                                        name="name"
                                        render={(msg) => <CustomErrorMessage fontSize="0.5em" errorMessage={msg} />}
                                    />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={3}>
                            {!dataObj?.isEdit ? (
                                <Stack direction="row" justifyContent="end">
                                    <Typography variant="body4" fontSize="13px">
                                        {Number(formik.values.price).toFixed(2)}
                                    </Typography>
                                </Stack>
                            ) : (
                                <>
                                    <CustomTextField
                                        disabled={!dataObj?.isEdit}
                                        sx={{
                                            fontSize: "0.73em",
                                            fontWeight: 500,
                                            "&": {
                                                height: "40px !important",
                                                "& .MuiInputBase-root > input": {
                                                    bgcolor: "#F0F0F0"
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    bgcolor: "#F0F0F0",
                                                    height: "40px",
                                                    overflow: "hidden",
                                                    "& >input": {
                                                        fontWeight: 500
                                                    }
                                                }
                                            }
                                        }}
                                        fullWidth
                                        placeholder=""
                                        InputProps={{
                                            maxLength: 6,
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{ color: "#212121" }}>
                                                    $
                                                </InputAdornment>
                                            )
                                        }}
                                        onKeyDown={enforceFormat}
                                        // {...formik.getFieldProps("price")}
                                        value={formik.values.price}
                                        onChange={(e) => {
                                            if (enforceFormatFor3Digits(e)) {
                                                formik.setFieldValue("price", e.target.value);
                                            }
                                        }}
                                    />
                                    <ErrorMessage
                                        name="price"
                                        render={(msg) => <CustomErrorMessage errorMessage={msg} fontSize="0.5em" />}
                                    />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={1}>
                            <FieldArray
                                name="dietary"
                                render={(arrayHelper) => (
                                    <DietaryOptions
                                        disabled={!dataObj?.isEdit}
                                        haslength={formik.values.dietary.length}
                                        checked={(obj) => formik.values.dietary.includes(obj.value)}
                                        onChange={(obj) => {
                                            if (formik.values.dietary.length && formik.values.dietary.includes(obj.value)) {
                                                const valIndex = formik.values.dietary.findIndex((el) => el === obj.value);
                                                arrayHelper.remove(valIndex);
                                            } else {
                                                arrayHelper.push(obj.value);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={!dataObj?.isEdit ? 1 : 2}>
                            {!dataObj?.isEdit ? (
                                <ActionButton
                                    row={dataObj}
                                    ActionArray={["Duplicate", "Edit", "Delete"]}
                                    handleActionBtn={(type, row, handleClose) => (e) => {
                                        handleActionBtn(type, row, handleClose, arrayHelper, index)(e);
                                    }}
                                />
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                >
                                    <Typography
                                        fontSize="0.5em"
                                        sx={(t) => ({
                                            p: 0.8,
                                            color: "#FFF",
                                            bgcolor: t.palette.primary.main,
                                            borderRadius: 0.5
                                        })}
                                    >
                                        Save
                                    </Typography>
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </>
    );
};

export default EditModifier;
