import moment from "moment";
import dayjs from "dayjs";

export function Dates() {
    function addInCurrent(amount, unit = "minutes") {
        return moment(new Date()).add(amount, unit);
    }

    return {
        addInCurrent
    };
}

export function getformateDate(date, format = "dd/mm/yyyy") {
    if (date) {
        const today = new Date(date);
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();

        if (dd < 10) {
            dd = `0${dd}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        switch (format) {
            case "dd/mm/yyyy":
                return `${dd}/${mm}/${yyyy}`;
            case "dd/mm/yy":
                return `${dd}/${mm}/${yyyy.toString().slice(-2)}`;
            case "yyyy-mm-dd":
            case "mm/dd/yyyy":
                return `${mm}/${dd}/${yyyy}`;
            default:
                return `${mm}/${dd}/${yyyy}`;
        }
    }
    return "";
}

export function getDayjsFormat(date, format = "M/DD/YYYY") {
    return dayjs(date).format(format);
}

export function getMonDateYear(date) {
    const utcDateString = date;
    const utcDate = new Date(utcDateString);

    // Convert UTC date to local timezone
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

    // Format the local date
    const formattedDate = localDate.toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"});
    return formattedDate.replace(/,/g, ""); // Remove any commas
}

export function getHHMMTime(time) {
    const today = new Date(time);
    let minutes = today?.getMinutes();
    let hour = today?.getHours();
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (hour < 10) {
        hour = `0${hour}`;
    }

    return `${hour}:${minutes}`;
}

export function convertDaysToHHMM(days) {
    if (days > 1) {
        const hours = Math.floor((days % 1) * 24);
        const minutes = Math.floor((((days % 1) * 24) % 1) * 60);
        const hoursPart = `${hours}`.slice(-2);
        const minutesPart = `${minutes}`.slice(-2);

        if (minutesPart !== "0") {
            return `${Math.round(days)} ${Number(days) < 2 ? "day" : "days"}, ${hoursPart} ${
                hoursPart === "01" ? `hour` : `hours`
            }, ${minutesPart} minutes`;
        }
        // return `${Math.round(days)} days`;
        return `${Math.round(days)} ${Number(days) < 2 ? "day" : "days"}, ${hoursPart} ${hoursPart === "01" ? `hour` : `hours`}`;
    }
    const hours = Math.round(days * 24);
    const minutes = Math.round((days * 24 * 60) % 60);
    const hoursPart = `${hours}`.slice(-2);
    const minutesPart = `${minutes}`.slice(-2);

    if (minutesPart !== "0") {
        return `${hoursPart} ${hoursPart === "01" ? `hour` : `hours`}, ${minutesPart} minutes`;
    }
    return `${hoursPart} ${hoursPart === "01" ? `hour` : `hours`}`;
}

export const getFormattedTimeinAMPM = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${ampm}`;
    // if (time) {
    //     const str = new Date(time).toLocaleString("en-US", {
    //         hour: "numeric",
    //         minute: "numeric",
    //         hour12: true
    //     });
    //     return str;
    // }
    // return "";
};

export function getHHMMWithTimeString(time) {
    const now = new Date();
    const nowDateTime = now.toISOString();
    const nowDate = nowDateTime.split("T")[0];
    const hms = time;
    const target = new Date(`${nowDate}T${hms}`);
    return getFormattedTimeinAMPM(target);
}

export function getformateDateUS(date, format) {
    if (date) {
        const today = new Date(date);
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();

        switch (format) {
            case "dd/mm/yyyy":
                return `${dd}/${mm}/${yyyy}`;
            case "yyyy/mm/dd":
                return `${yyyy}/${mm}/${dd}`;
            default:
                return `${mm}/${dd}/${yyyy}`;
        }
    }
    return "";
}


export function convertToUTC(date) {
    const UTC = date ? moment(date).toISOString() : null;
    return UTC;
}

export function convertToLocal(date) {
    const local = date ? moment(date).toLocaleString() : null;
    return local;
}
