import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TableTopFilterWrapper from "commonComponent/TableSection/TableTopFilterWrapper";
import CustomizedTabs from "commonComponent/NewTab";
import {useNavigate, useSearchParams} from "react-router-dom";
import PageHeader from "commonComponent/Pageheader/PageHeader";
import useToggleDilog from "hooks/useToggleDilog";
import Customer from "./Tabs/Orders/Orders";
import HouseAccounts from "./Tabs/Sales/Sales";
import useTempDrawer from "hooks/useTempDrawer";

import {NetworkManager, API} from "network/core";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {CsvBuilder} from "filefy";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import {resetState, setShowFilter} from "store/slices/paginationSlice";

const Reports = () => {
    const {toggleDrawer} = useTempDrawer();
    const [value, setValue] = useState("Reports");
    // const [btnlabel, setBtnLebel] = useState("Create customer");
    const [csvButtonLabel, setcsvButtonLabel] = useState("Export CSV");
    const [heading, setHeading] = useState("Reports");
    const [search, setSearch] = useState("");
    // const [showFilter, setShowFilter] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [updateListCount, setUpdateListCount] = useState(1);
    const [urlSearchParams] = useSearchParams();
    const {toggleDilog} = useToggleDilog();
    const dispatch = useDispatch();
    const showFilter = useSelector((state) => state?.pagination?.showFilter);

    const navigate = useNavigate();
    const tab = urlSearchParams.get("tab");
    // const OrderId = urlSearchParams.get("d");

    useEffect(() => {
        setSelectedCheckbox([]);
    }, [window.location.search]);

    useEffect(() => {
        setValue(tab);
        const search = `?tab=${tab}`;

        navigate({
            pathname: window.location.pathname,
            search: search
        });
    }, []);

    useEffect(() => {
        // is user edits the url query then we need to redirect it to default tab
        if (tab == null || (tab !== "orders" && tab !== "sales")) {
            setValue("orders");
            const search = `?tab=${"orders"}`;

            navigate({
                pathname: window.location.pathname,
                search: search
            });
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setSearch("");
        setValue(newValue);
        dispatch(resetState());
        const search = `?tab=${newValue}`;

        navigate({
            pathname: window.location.pathname,
            search: search
        });
    };

    const handleSearch = (val) => {
        setSearch(val);
    };

    const columnData =
        tab === "orders"
            ? [
                { key: "period", label: "Period" },
                { key: "orders", label: "Orders" },
                { key: "guests", label: "Guests" },
                {key: "refund", label: "Refunds"},
                { key: "orderItems", label: "Order items" },
                { key: "preferredPrice", label: "Preferred pricing" },
                { key: "discounts", label: "Discounts" },
                { key: "netOrderItems", label: "Net order items" },
                { key: "serviceFee", label: "Service fee" },
                { key: "salesTax", label: "Sales tax" },
                { key: "sales", label: "Sales " }
            ]
            : [
                  {key: "period", label: "Period"},
                  {key: "orders", label: "Orders"},
                  {key: "sales", label: "Sales"},
                  {key: "taxExempt", label: "Tax exempt"},
                  {key: "salesTax", label: "Sales tax"}
              ];

    const csvBuilder = () => {
        new CsvBuilder("report_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                selectedCheckbox.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);

                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();
        setSelectedCheckbox([]);
    };

    const TabsData = [
        {
            value: "orders",
            label: "Sales",
            comp: () => (
                <Customer
                    search={search}
                    // showFilter={showFilter}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                />
            )
        },
        {
            value: "sales",
            label: "Sales tax",
            comp: () => (
                <HouseAccounts
                    search={search}
                    setHeading={setHeading}
                    setSelectedCheckbox={setSelectedCheckbox}
                    selectedCheckbox={selectedCheckbox}
                    updateListCount={updateListCount}
                    // showFilter={showFilter}
                />
            )
        }
    ];
    const handleHeaderActions = (btntype) => (e) => {
        switch (btntype) {
            case "none": {
                dispatch(setShowFilter(true));
                toggleDrawer("right", true)(e);
                break;
            }
            default: {
                break;
            }
        }
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableTopFilterWrapper
                        lessmarginOnTop
                        onlyHeading
                        onlyDownload
                        pageheader={<PageHeader title={heading} />}
                        // csvButton={csvButtonLabel}
                        handleClick={handleHeaderActions}
                        handleSearch={handleSearch}
                        filterType="none"
                        orderFilter="orderFilter"
                        selectedCheckbox={selectedCheckbox}
                        csvBuilder={csvBuilder}
                        deleteBulkTitle="customers"
                    >
                        <CustomizedTabs tabsInfo={TabsData} value={value} handleChange={handleTabChange} />
                    </TableTopFilterWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default Reports;
