import React, {useEffect, useState, useCallback} from "react";
import Box from "@mui/material/Box";
import styles from "./createOrder.module.scss";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import CustomizedAccordions from "./accordian";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomTextField from "commonComponent/Form/Fields/CustomTextField";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import {
    enforceFormat,
    FormatCurrency,
    checkforPerfectNumber,
    enforceFormatFor3Digits,
    enforceFormatFor5Digits,
    validateInputForPercentage
} from "utils/helperFunctions/helpers";
import StorageManager from "storage/StorageManager";
import useTempDrawer from "hooks/useTempDrawer";
import useToggleDilog from "hooks/useToggleDilog";
import {store, dispatch} from "store/index";
import Checked from "assets/images/icons/lightCheckedBox.svg";
import Unchecked from "assets/images/icons/unchecked.svg";
import DownArrow from "assets/images/icons/downArrow.svg";
import OrderUpdationModal from "commonComponent/DilogBox/OrderPriceUpdationModal";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {styled} from "@mui/material/styles";
import expandIcon from "assets/images/icons/expand.svg";
import {ErrorMessage} from "formik";
import collapseIcon from "assets/images/icons/collapse.svg";
import {API, NetworkManager} from "network/core";
import {openSnackbar} from "store/slices/snackbar";
import ViewAllPromotionsDrawer from "./Promotion/viewPromotionDrawer";
import {ClickAwayListener, MenuItem, MenuList, Popper, Tooltip} from "@mui/material";
import _debounce from "lodash/debounce";
import {useSearchParams} from "react-router-dom";
import networkManager from "network/core/networkManager";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

function CreateOrderFooter({
    orderId,
    formik,
    navigate,
    initialValues,
    handleReset,
    setOpenDialog,
    setOpenPaymentDialog,
    setOpenRefundDialog,
    setRefundAmount,
    setExtraAmount,
    Reorder,
    setFooterHeight,
    updateFooterDimensions,
    handleUpdateOrder,
    setCartName,
    customerName,
    newServiceFessRef,
    preferrefPriceRef,
    orderPlaced,
    setOrderPlaced,
    kitchenIdRef,
    setSingleQuoteData,
    setCreateData
}) {
    console.log("formik : ", formik);
    const {toggleDrawer, state} = useTempDrawer();
    const {toggleDilog} = useToggleDilog();
    const [discountFlag, setDiscountFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDiscountAdded, setISDiscountAdded] = useState(false);
    const [openCancelModal, setCancelModal] = useState(false);
    const [discountCode, setDiscount] = useState("");
    const [expanded, setExpanded] = React.useState("panel1");
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [serviceFess, setserviceFess] = useState(0);
    const [newServiceFess, setNewServiceFess] = useState(0);
    const [initialValue, setInitialValues] = useState({});
    const [newMinimumServiceFees, setNewMinimumServiceFees] = useState(0);
    const [searchParams] = useSearchParams();
    const [kitchenLocationId, setkitchenLocationId] = useState();
    const OrderId = searchParams.get("orderId");
    const reorderID = searchParams.get("reOrder");
    const handleBtnClick = (event) => {
        setOpenPaymentDialog(false);
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handlePopupClose = () => {
        setOpen(false);
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setFooterHeight(newExpanded ? panel : false);
        updateFooterDimensions();
    };

    const handlePaymentView = (e) => {
        toggleDrawer("right", "Payment")(e);
    };

    let orderPriceForAllItem = 0;
    let serviceTaxTotal = 0;
    let discountedPrice = 0;
    let salesTaxTotalPrice = 0;
    let overAllPrice = 0;
    let preferredPriceTotal = 0;

    // useEffect(() => {
    //     if (formik.values.headerData.customer?.userId) {
    //         setDiscount(""); // Reset the discount code
    //         setISDiscountAdded(false); // Reset the discount flag
    //         formik.setFieldValue("discountOrFee.description", ""); // Clear the description field
    //         formik.setFieldValue("discountOrFee.value", ""); // Clear the discount value field
    //         formik.setFieldValue("discountOrFee.type", ""); // Clear the discount type field (optional)
    //         formik.setFieldValue("discountOrFee.priceType", ""); // Clear the price type field (optional)
    //         formik.setFieldTouched("discountOrFee.description", true); // Mark the field as touched
    //     }
    // }, [formik.values.headerData.customer?.userId]);

    const getServiceData = async () => {
        const instance = NetworkManager(API.SERVICE.GET);
        const response = await instance.request();
        if (response.success) {
            const data = response.data.serviceFee.cateringRate?.service_fee;
            setNewServiceFess(data); // Set the new service fee from the API response
            newServiceFessRef.current = data;
            setNewMinimumServiceFees(response.data.serviceFee.cateringRate?.minimum_service_fee);
            formik.setFieldValue("serviceFees.value", data);
        }
    };

    const getCutOffTime = async () => {
        const matchingKitchen = formik?.values?.kitchenList?.find((kitchen) => kitchen.kitchen_location_id === formik.kitchenLocationId);
        // console.log("matchingKitchen : ", matchingKitchen);
        if (matchingKitchen) {
            formik.setFieldValue("kitchen_location_name", matchingKitchen);
        } else {
            formik.setFieldValue("kitchen_location_name", formik?.values?.selectedKitchen);
        }
        const instance = networkManager(API.ORDER.CUTOFFTIME);
        const params = {
            delivery_date: formik?.values?.headerData?.when,
            kitchen_location_id: kitchenIdRef.current
        };
        const response = await instance.request({}, params);
        formik.setFieldValue("delivery_date", new Date(formik?.values?.headerData?.when));
        formik.setFieldValue("brand_name");
        formik.setFieldValue("order_id", orderId?.order_id);
        formik.setFieldValue("order_number", orderId?.order_number);
        if (response.success) {
            const cutOffTime = response?.data?.cutoff_time;
            formik.setFieldValue("cutOffTime", cutOffTime);
            formik.setFieldValue("quote_expiry_time", cutOffTime);
            // console.log("response : ", response);
        }
    };

    useEffect(() => {
        if (!OrderId && !reorderID) {
            getServiceData();
        } else {
            setNewServiceFess(formik?.values?.serviceFees?.value);
        }
    }, [formik?.values?.serviceFeeAdded]); // Fetch data when component mounts

    // useEffect to update formik value when newServiceFess is set
    useEffect(() => {
        if (newServiceFess) {
            formik.setFieldValue("serviceFees.value", newServiceFess);
            newServiceFessRef.current = newServiceFess;
        }
    }, [newServiceFess, newServiceFessRef]);

    const handleItemTotalPrice = () => {
        const {itemDetail} = formik.values;
        let totalPrice = 0;

        itemDetail?.forEach((obj, itemIndex) => {
            let tempPrice = 0;
            tempPrice += parseFloat(itemDetail[itemIndex]?.price);
            tempPrice *= parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1);
            itemDetail[itemIndex]?.checkboxModifier?.forEach((row) => {
                if (row.allowQuantityModification) {
                    // for custom modifier (means for those we can modify quantity)
                    tempPrice += parseFloat(row?.quantity !== "" ? row?.quantity : 1) * parseFloat(row.price);
                } else {
                    // for modifier that doest not have modifiable quantity
                    // for those we have to take item quantity * modifier price
                    tempPrice +=
                        parseFloat(itemDetail[itemIndex]?.quantity !== "" ? itemDetail[itemIndex]?.quantity : 1) * parseFloat(row.price);
                }
            });
            itemDetail[itemIndex]?.radioModifier?.forEach((row) => {
                const obj = JSON.parse(row);
                if (obj.allowQuantityModification) {
                    tempPrice += parseFloat(obj?.quantity !== "" ? obj?.quantity : 1) * parseFloat(obj.price);
                } else {
                    tempPrice +=
                        parseFloat(itemDetail[itemIndex].quantity !== "" ? itemDetail[itemIndex].quantity : 1) * parseFloat(obj.price);
                }
            });

            totalPrice += tempPrice;
            orderPriceForAllItem = totalPrice;
        });
        StorageManager.put("orderItemPrice", totalPrice);
        return totalPrice;
    };

    const calculatePreferredPrice = () => {
        let prePrice = 0;
        if (formik.values.headerData.preferredDiscountType === "PERCENTAGE") {
            prePrice = parseFloat(parseFloat(orderPriceForAllItem) * (parseFloat(formik.values.headerData.preferredDiscountValue) / 100));
        } else {
            prePrice = parseFloat(formik.values.headerData.preferredDiscountValue);
        }
        if (Number.isNaN(prePrice)) {
            preferredPriceTotal = 0.0;
            prePrice = 0.0;
        } else {
            preferredPriceTotal = prePrice;
        }
        StorageManager.put("preferredPrice", parseFloat(prePrice).toFixed(2));
        return parseFloat(prePrice).toFixed(2);
    };

    useEffect(() => {}, []);

    const calculateServiceTax = () => {
        let servicetax = 0;

        // If serviceFees.value is 0, set servicetax to 0 and skip all other calculations
        if (formik.values.serviceFees.value === 0) {
            servicetax = 0.0;
        } else if (formik.values.serviceFees.unitType === "percent") {
            servicetax = parseFloat(parseFloat(orderPriceForAllItem) * (parseFloat(formik.values.serviceFees.value) / 100));
        } else {
            servicetax = parseFloat(formik.values.serviceFees.value);
        }

        // Make sure servicetax remains 0 if serviceFees.value is 0
        if (formik.values.serviceFees.value === "0") {
            servicetax = 0.0;
        }

        if (Number.isNaN(servicetax)) {
            serviceTaxTotal = 0.0;
            servicetax = 0.0;
        } else {
            serviceTaxTotal = servicetax;
        }

        StorageManager.put("ServiceTax", parseFloat(servicetax).toFixed(2));
        return parseFloat(servicetax).toFixed(2);
    };

    const calculatePriceAfterDiscount = () => {
        const {discountOrFee} = formik.values;
        let discountValue = 0;
        if (discountOrFee.type === "Percentage" && parseFloat(discountOrFee.value) > 0) {
            discountValue = (parseFloat(discountOrFee.value) / 100) * parseFloat(orderPriceForAllItem);
        } else if (discountOrFee.type === "Amount") {
            discountValue = parseFloat(discountOrFee.value);
        }
        if (Number.isNaN(discountValue)) {
            discountedPrice = 0.0;
            discountValue = 0.0;
        } else {
            discountedPrice = discountValue;
        }
        return parseFloat(discountValue).toFixed(2);
    };

    const calculateSalesTax = () => {
        const {salesTax, isTaxExempts} = formik.values;
        const newFinalPrefPrice = localStorage.getItem("preferredPrice");

        const totalPriceToApplyForSalesTax = Number(orderPriceForAllItem) - newFinalPrefPrice;
        let priceForSalesTax = 0;
        if (!isTaxExempts) {
            if (parseFloat(salesTax.value) > 0) {
                priceForSalesTax = (parseFloat(salesTax.value) / 100) * parseFloat(totalPriceToApplyForSalesTax);
            }
        }

        if (Number.isNaN(priceForSalesTax)) {
            salesTaxTotalPrice = 0.0;
            priceForSalesTax = 0.0;
        } else {
            salesTaxTotalPrice = priceForSalesTax;
        }

        StorageManager.put("salesTax", parseFloat(priceForSalesTax).toFixed(2));
        return parseFloat(priceForSalesTax).toFixed(2);
    };

    const calculateOverallPrice = () => {
        const newFinalPrefPrice = localStorage.getItem("preferredPrice");
        let finalPrice =
            parseFloat(orderPriceForAllItem) + parseFloat(serviceTaxTotal) + parseFloat(salesTaxTotalPrice) - newFinalPrefPrice;
        if (preferrefPriceRef < finalPrice) {
            finalPrice -= Number(finalPrice);
        }
        if (formik.values.discountOrFee.priceType === "Fee") {
            finalPrice += parseFloat(discountedPrice);
            StorageManager.put("priceType", "Fee");
        } else if (formik.values.discountOrFee.priceType === "Discount") {
            finalPrice -= parseFloat(discountedPrice);
            StorageManager.put("priceType", "Discount");
        }
        if (formik.values.discountOrFee.type === "Percentage") {
            if (Number(formik.values.discountOrFee.value) > 0) {
                StorageManager.put("discountTypeValue", formik.values.discountOrFee.value);
            } else {
                StorageManager.removeItem("discountTypeValue");
            }
        } else {
            StorageManager.removeItem("discountTypeValue");
        }
        StorageManager.put("discountPrice", parseFloat(discountedPrice).toFixed(2));
        overAllPrice = finalPrice;
        StorageManager.put("orderTotal", parseFloat(finalPrice).toFixed(2));
        return parseFloat(finalPrice).toFixed(2);
    };

    const calculatePerPersonOrderItem = () => {
        const {headerData} = formik.values;
        let price = 0;
        if (headerData.forHowMany !== "") {
            if (parseFloat(orderPriceForAllItem) > 0 && parseFloat(headerData.forHowMany) > 0) {
                price = parseFloat(orderPriceForAllItem) / parseFloat(headerData.forHowMany);
            }
        }
        StorageManager.put("orderPrice1", parseFloat(price).toFixed(2));
        return parseFloat(price).toFixed(2);
    };

    const calculatePerPersonTotal = () => {
        const {headerData} = formik.values;
        let price = 0;
        if (headerData.forHowMany !== "") {
            if (parseFloat(overAllPrice) > 0 && parseFloat(headerData.forHowMany) > 0) {
                price = parseFloat(overAllPrice) / parseFloat(headerData.forHowMany);
            }
        }
        StorageManager.put("orderPrice2", parseFloat(price).toFixed(2));
        return parseFloat(price).toFixed(2);
    };

    const handlePaymentText = () => {
        const totalPrice = parseFloat(calculateOverallPrice());
        const paidAlready = parseFloat(formik.values.paid_already);
        let finalWord = "Place order";
        if (
            formik.values.orderStatus === "Accepted" ||
            formik.values.orderStatus === "Active" ||
            formik.values.orderStatus === "Driver Assigned" ||
            formik.values.orderStatus === "ON_THE_WAY" ||
            formik.values.orderStatus === "Needs Driver" ||
            formik.values.orderStatus === "DELIVERED"
        ) {
            // if (totalPrice === paidAlready) {
            //     finalWord = "Place order"
            // }
            // else if (totalPrice < paidAlready) {
            //     finalWord = `Issue refund ${FormatCurrency(paidAlready - totalPrice)}`
            // }
            // else if (totalPrice > paidAlready) {
            //     finalWord = `Extra amount ${FormatCurrency(totalPrice - paidAlready)}`
            // }
            if (Reorder === "reOrder") {
                finalWord = "Place order";
            } else {
                finalWord = "Save edits";
            }
        }
        return finalWord;
    };

    const handleOpenModal = () => {
        formik.validateForm().then((res) => {
            // to open the sidebar if no field has validation error
            if (Object.keys(res).length === 0) {
                setCancelModal(true);
            } else {
                formik.handleSubmit();
            }
        });
    };

    const handleOrderUpdationModal = () => {
        setOpenPaymentDialog(true);
        setCancelModal(false);
        formik.handleSubmit();
    };

    const handleClose = () => {
        setCancelModal(false);
    };

    const handleImageClick = (event) => {
        // Prevent the accordion from expanding/collapsing when the image is clicked
        event.stopPropagation();
    };

    const handleViewAllPromotions = () => {
        // toggleNewDrawer("viewAllPromotions", "right", true)();
        toggleDrawer("right", "viewAllPromotions")();
    };

    const handleApplyDicount = async (data) => {
        setLoading(true);
        const payload = {
            discount_code: data ? data?.discount_code : discountCode,
            service_type: "CATERING",
            market_id: [formik.values?.marketIDForDiscount || ""],
            brand_id: [formik.values?.selectedBrand],
            company_id: formik.values.headerData?.customer?.companyId || ""
        };
        const instance = NetworkManager(API.PROMOTIONS.POST);

        const res = await instance.request(payload);

        if (res.success) {
            const minOrderValueForCoupon = res.data.minimum_order_value;

            // if (formik.values.discount_applied_coupon_id === res?.data?.discount_id) {
            // commenting this for timeBeing @mehran-nickefox
            //     dispatch(
            //         openSnackbar({
            //             open: true,
            //             message: "Already applied this coupon",
            //             variant: "alert",
            //             alert: {
            //                 color: "success"
            //             },
            //             close: true
            //         })
            //     );
            // } else
            if (Number(orderPriceForAllItem) >= Number(minOrderValueForCoupon)) {
                formik.setFieldValue("discount", res?.data);
                formik.setFieldTouched("discount", res?.data);

                formik.setFieldError("discount", undefined);
                const temp = {
                    type: res?.data?.discount_type === "PERCENTAGE" ? "Percentage" : "Amount",
                    priceType: "Discount",
                    value: res?.data?.amount ? Number(res?.data?.amount) : 0,
                    description: res?.data?.discount_code
                };
                formik.setFieldValue("discountOrFee", temp);
                formik.setFieldTouched("discountOrFee", temp);

                formik.setFieldValue("discount_applied_coupon_id", res?.data?.discount_id);
                formik.setFieldTouched("discount_applied_coupon_id", res?.data?.discount_id);

                setISDiscountAdded(true);
                toggleDrawer("right", false)();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: res.message,
                        variant: "alert",
                        alert: {
                            color: "success"
                        },
                        close: true
                    })
                );
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: `Minimum order value should be ${FormatCurrency(res.data.minimum_order_value)}`,
                        variant: "alert",
                        alert: {
                            color: "error"
                        },
                        close: true
                    })
                );
                formik.setFieldError("discount", "Discount code does not exist");
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: res.message,
                    variant: "alert",
                    alert: {
                        color: "error"
                    },
                    close: true
                })
            );
            formik.setFieldError("discount", res.message);
        }
        setLoading(false);
    };

    const handleTouch = (formik) => {
        const allFields = Object.keys(formik.values);
        const touchedFields = {};

        allFields.forEach((field) => {
            touchedFields[field] = true;
        });

        formik.setTouched(touchedFields);
        formik.setFieldTouched("headerData.customer.userId", true);
        formik.setFieldTouched("headerData.deliveryTo", true);
        formik.setFieldTouched("headerData.when", true);
        formik.setFieldTouched("headerData.forHowMany", true);
        formik.setFieldTouched("serviceFees.value", true);
    };

    const handleScheduleOrder = async () => {
        await handleUpdateOrder(formik.values, true);
    };

    const debouncedHandleUpdateOrder = _debounce(handleUpdateOrder, 4000);

    useEffect(() => {
        if (!orderPlaced) {
            if (formik.values.orderStatus === "" || formik.values.orderStatus === "Draft") {
                debouncedHandleUpdateOrder(formik.values, false, true);
            }
        }

        // Cleanup function to cancel the debounced function if the component unmounts or order is placed
        return () => {
            debouncedHandleUpdateOrder.cancel();
        };
    }, [formik.values, orderPlaced]);

    const getCateringService = async () => {
        setLoading(true);
        const instance = NetworkManager(API.SERVICE.GET);
        const response = await instance.request();
        const data = response.data;
        if (data) {
            setLoading(false);
        }

        // Transform the data to match Formik's expected initialValues structure
        const transformedData = {
            serviceFee: data.serviceFee.cateringRate.service_fee,
            minimumServiceFee: data.serviceFee.cateringRate.minimum_service_fee,
            largeOrderMinimum: data.serviceFee.cancellationPolicy.large_order_minimum,
            largeOrderFees: data.serviceFee.cancellationPolicy.greater_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee
            })),
            smallOrderFees: data.serviceFee.cancellationPolicy.less_than_order_minimum.map((fee) => ({
                hours: fee.hours_before_delivery,
                fee: fee.cancellation_fee
            }))
        };
        setserviceFess(transformedData.serviceFee);
    };

    useEffect(() => {
        getCateringService();
    }, []);
    return (
        <>
            {state.right === "viewAllPromotions" && <ViewAllPromotionsDrawer handleApplyDicount={handleApplyDicount} formik={formik} />}
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} sx={{border: "none"}}>
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                        background: "transparent",
                        padding: "0px",
                        marginLeft: "0px",
                        minHeight: "1px !important",
                        position: "relative",
                        cursor: "auto !important",
                        "& .MuiAccordionSummary-content": {
                            margin: "0px",
                            position: "relative"
                        }
                    }}
                >
                    <span style={{position: "absolute", top: "-8px", left: "35px", cursor: "pointer"}}>
                        <img src={expanded ? expandIcon : collapseIcon} alt="expand" />
                    </span>
                    <Box
                        sx={{borderTop: "1px solid #E0E2E6", width: "100%", padding: "0px", paddingBottom: "2rem"}}
                        onClick={handleImageClick}
                    >
                        <Box sx={{marginTop: "-10px !important", marginLeft: "37px"}}>{/* abc */}</Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{marginTop: "-2.5rem", borderTop: "none"}}>
                    <div className="footerScale">
                        <Grid container className={styles.orderFooterMain}>
                            <Grid item xs={5.8} lg={7}>
                                <Box>
                                    <CustomizedAccordions formik={formik} />
                                </Box>
                            </Grid>
                            <Grid item xs={0.2}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={6} lg={4.8} mt={1}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Typography className={styles.summary} fontWeight={700}>
                                                Order items
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {FormatCurrency(parseFloat(handleItemTotalPrice()))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Stack>
                                                    <Typography className={styles.summary} fontWeight={400}>
                                                        Service fee
                                                    </Typography>
                                                </Stack>
                                                <Stack ml={0.8}>
                                                    <Tooltip
                                                        placement="top"
                                                        arrow
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    backgroundColor: "#36454F !important",
                                                                    color: "#fff !importantt",
                                                                    boxShadow: 1
                                                                }
                                                            },
                                                            arrow: {
                                                                sx: {
                                                                    color: "#36454F !important",
                                                                    border: "none !important"
                                                                }
                                                            }
                                                        }}
                                                        title="Includes plates, napkins, eating and serving utensils, delivery, setup and gratuity."
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{width: "12px", height: "12px", marginTop: "3px", color: "#00ab3a"}}
                                                        />
                                                    </Tooltip>

                                                    {/* <InfoOutlinedIcon sx={{ width: "12px", height: "12px", color: "#00ab3a" }} /> */}
                                                </Stack>

                                                <Stack ml={1.5} sx={{display: "flex", flexDirection: "row"}}>
                                                    <CustomSelect
                                                        sx={{
                                                            height: "32px !important",
                                                            width: "58px !important",
                                                            marginRight: "3px",
                                                            padding: "-2px !important",
                                                            "& .MuiSelect-select": {
                                                                padding: "2px 11px !important"
                                                            }
                                                        }}
                                                        menuItems={[
                                                            {label: "%", id: "percent", value: "percent"},
                                                            {label: "$", id: "usd", value: "usd"}
                                                        ]}
                                                        noNone
                                                        value={formik.values.serviceFees.unitType}
                                                        onChange={(e) => {
                                                            // const val = formik.values.serviceFees;
                                                            // val.unitType = e.target.value;
                                                            // val.value = serviceFess;

                                                            const updatedUnitType = e.target.value;

                                                            // Only update unitType without affecting value
                                                            formik.setFieldValue("serviceFees.unitType", updatedUnitType);
                                                            formik.setFieldTouched("serviceFees.unitType", true);
                                                        }}
                                                    />

                                                    <Box sx={{display: "flex", flexDirection: "column"}}>
                                                        <OutlinedInput
                                                            id="outlined-adornment-weight"
                                                            aria-describedby="outlined-weight-helper-text"
                                                            inputProps={{
                                                                "aria-label": "weight",
                                                                inputMode: "numeric",
                                                                pattern: "^-?d{0,3}(.d{0,2})?$" // eslint-disable-line
                                                            }}
                                                            value={newServiceFess ?? serviceFess}
                                                            sx={{
                                                                "&": {
                                                                    height: "32px !important",
                                                                    width: "55px !important",
                                                                    "& .MuiInputBase-input": {
                                                                        padding: "6.5px 13px"
                                                                    }
                                                                }
                                                            }}
                                                            // onChange={(e) => {
                                                            //     if (enforceFormatFor3Digits(e)) {
                                                            //         const val = formik.values.serviceFees;
                                                            //         val.value = e.target.value;
                                                            //         formik.setFieldValue("formik.values.serviceFees", val);
                                                            //         setserviceFess(e.target.value);
                                                            //     }
                                                            // }}
                                                            onChange={(e) => {
                                                                if (enforceFormatFor3Digits(e)) {
                                                                    const val = formik.values.serviceFees;
                                                                    val.value = e.target.value;
                                                                    // const updatedServiceFees = parseFloat(e.target.value);
                                                                    setNewServiceFess(e.target.value); // Update the newServiceFess state
                                                                    formik.setFieldValue("formik.values.serviceFees", val); // Update formik value if needed
                                                                }
                                                            }}
                                                        />
                                                        <ErrorMessage
                                                            name="serviceFees.value"
                                                            render={(msg) => <CustomErrorMessage errorMessage={` ${msg}`} />}
                                                        />
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                paddingRight: "10px"
                                            }}
                                        >
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {FormatCurrency(calculateServiceTax())}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} mt={0.2}>
                                            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Stack>
                                                    <Typography className={styles.summary} sx={{mt: "2px"}} fontWeight={400}>
                                                        Preferred pricing{" "}
                                                        {formik.values.headerData.preferredDiscountValue &&
                                                        formik.values.headerData.preferredDiscountType === "PERCENTAGE"
                                                            ? Math.floor(Number(formik.values.headerData.preferredDiscountValue))
                                                            : ""}
                                                        {formik.values.headerData.preferredDiscountType === "PERCENTAGE" && "%"}
                                                    </Typography>
                                                </Stack>
                                                <Stack ml={0.8}>
                                                    <Tooltip
                                                        placement="top"
                                                        arrow
                                                        componentsProps={{
                                                            tooltip: {
                                                                sx: {
                                                                    backgroundColor: "#36454F !important",
                                                                    color: "#fff !important",
                                                                    boxShadow: 1
                                                                },
                                                                arrow: {
                                                                    sx: {
                                                                        color: "#36454F !important",
                                                                        border: "none !important"
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        title="Large, regular, or recurring orders may qualify for special pricing."
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{width: "12px", height: "12px", marginTop: "3px", color: "#00ab3a"}}
                                                        />
                                                    </Tooltip>
                                                    {/* <InfoOutlinedIcon sx={{ width: "12px", height: "12px", color: "#00ab3a" }} /> */}
                                                </Stack>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} mt={0.2} className={styles.priceData}>
                                            <Typography className={styles.summary} sx={{mt: "2px"}} fontWeight={400}>
                                                {/* {calculatePreferredPrice() > 0 && "-"} {FormatCurrency(calculatePreferredPrice())} */}
                                                {preferrefPriceRef.current
                                                    ? `-$${calculatePreferredPrice(preferrefPriceRef.current)}`
                                                    : "$0.00"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} mt={0.6}>
                                            <Stack sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Stack>
                                                    {discountFlag ? (
                                                        <RemoveCircleIcon
                                                            onClick={() => {
                                                                setDiscountFlag(false);
                                                                updateFooterDimensions();
                                                            }}
                                                            sx={{fontSize: "1rem", color: "#00ab3a", cursor: "pointer"}}
                                                        />
                                                    ) : (
                                                        <AddCircleIcon
                                                            onClick={() => {
                                                                setDiscountFlag(true);
                                                                updateFooterDimensions();
                                                            }}
                                                            sx={{fontSize: "1rem", color: "#00ab3a", cursor: "pointer"}}
                                                        />
                                                    )}
                                                </Stack>
                                                <Stack ml={0.5}>
                                                    <Typography className={styles.discount}>Discount or fee</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {!discountFlag &&
                                                    formik.values.discountOrFee.priceType === "Discount" &&
                                                    calculatePriceAfterDiscount() > 0 &&
                                                    "-"}{" "}
                                                {!discountFlag && FormatCurrency(calculatePriceAfterDiscount())}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={9}
                                            mt={1}
                                            sx={{
                                                height: discountFlag ? 90 : "0px",
                                                transition: "0.3s ease-out",
                                                overflow: "hidden",
                                                display: "flex",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <Box sx={{display: "flex", mb: 0.7, flexDirection: "row", alignItems: "center"}}>
                                                <Stack>
                                                    <CustomSelect
                                                        sx={{
                                                            height: "33px !important",
                                                            width: "105px !important"
                                                        }}
                                                        menuItems={[
                                                            {label: "Discount", id: "Discount"},
                                                            {label: "Fees", id: "Fee"}
                                                        ]}
                                                        noNone
                                                        value={formik.values.discountOrFee.priceType}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("discountOrFee.priceType", e.target.value);
                                                            formik.setFieldTouched("discountOrFee.priceType", true);
                                                        }}
                                                    />
                                                </Stack>

                                                <Stack ml={0.3}>
                                                    <CustomSelect
                                                        sx={{
                                                            "&": {
                                                                height: "33px !important",
                                                                width: "66px !important"
                                                            }
                                                        }}
                                                        menuItems={[
                                                            {label: "%", id: "Percentage"},
                                                            {label: "$", id: "Amount"}
                                                        ]}
                                                        noNone
                                                        value={formik.values.discountOrFee.type}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("discountOrFee.type", e.target.value);
                                                            formik.setFieldTouched("discountOrFee.type", true);
                                                        }}
                                                    />
                                                </Stack>
                                                <Stack ml={0.3}>
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                            "aria-label": "weight"
                                                        }}
                                                        onKeyDown={enforceFormat}
                                                        sx={{
                                                            "&": {
                                                                height: "33px !important",
                                                                width: "65px !important"
                                                            }
                                                        }}
                                                        value={formik.values.discountOrFee.value}
                                                        onChange={(e) => {
                                                            if (formik.values.discountOrFee.type === "Percentage") {
                                                                if (validateInputForPercentage(e.target.value)) {
                                                                    formik.setFieldValue("discountOrFee.value", e.target.value);
                                                                    formik.setFieldTouched("discountOrFee.value", true);
                                                                }
                                                            } else if (formik.values.discountOrFee.type === "Amount") {
                                                                if (enforceFormatFor5Digits(e)) {
                                                                    formik.setFieldValue("discountOrFee.value", e.target.value);
                                                                    formik.setFieldTouched("discountOrFee.value", true);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                            </Box>
                                            <Box>
                                                <Stack>
                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        aria-describedby="outlined-weight-helper-text"
                                                        fullWidth
                                                        inputProps={{
                                                            "aria-label": "weight",
                                                            maxLength: 250
                                                        }}
                                                        sx={{
                                                            "&": {
                                                                height: "32px !important",
                                                                width: "242px",

                                                                ".MuiOutlinedInput-input": {
                                                                    padding: "10.5px 8px"
                                                                }
                                                            }
                                                        }}
                                                        placeholder="Description"
                                                        value={formik.values.discountOrFee.description}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("discountOrFee.description", e.target.value);
                                                            formik.setFieldTouched("discountOrFee.description", true);
                                                        }}
                                                    />
                                                </Stack>
                                            </Box>
                                            {isDiscountAdded && (
                                                <Box>
                                                    <Stack direction="row" spacing={0} alignItems="center" sx={{mb: 1}}>
                                                        <Stack spacing={2} direction="row">
                                                            <Typography
                                                                sx={{cursor: "pointer"}}
                                                                component="div"
                                                                color="#f0475c"
                                                                onClick={() => {
                                                                    formik.setFieldValue("discount", "");
                                                                    formik.setFieldValue("discount_applied_coupon_id", "");
                                                                    const temp = {
                                                                        type: "Amount",
                                                                        priceType: "Discount",
                                                                        value: 0,
                                                                        description: ""
                                                                    };
                                                                    formik.setFieldValue("discountOrFee", temp);
                                                                    formik.setFieldTouched("discountOrFee", true);
                                                                    setDiscount("");
                                                                    setISDiscountAdded(false);
                                                                }}
                                                            >
                                                                Remove
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            )}
                                            <Box>
                                                <Stack>
                                                    <Typography
                                                        variant="semiDetail2"
                                                        fontSize="1em"
                                                        color="#00ab3a"
                                                        sx={{cursor: "pointer"}}
                                                        onClick={handleViewAllPromotions}
                                                    >
                                                        View all promotions
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                alignItems: "center",
                                                height: discountFlag ? 32 : "0px",
                                                transition: "0.3s ease-out",
                                                overflow: "hidden",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                paddingRight: "10px"
                                            }}
                                        >
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {formik.values.discountOrFee.priceType === "Discount" &&
                                                    calculatePriceAfterDiscount() > 0 &&
                                                    "-"}{" "}
                                                {FormatCurrency(calculatePriceAfterDiscount())}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} mt={0.1}>
                                            <Stack sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Stack sx={{marginRight: "12px"}}>
                                                    <Typography className={styles.summary} fontWeight={400}>
                                                        Sales tax {checkforPerfectNumber(formik.values.salesTax.value || "")}%
                                                    </Typography>
                                                </Stack>
                                                <Stack ml={0.5}>
                                                    <Checkbox
                                                        sx={{width: "24px", heigth: "24px"}}
                                                        checked={formik.values.isTaxExempts}
                                                        disableRipple
                                                        icon={<img src={Unchecked} style={{width: "18px", height: "18px"}} alt="checked" />}
                                                        checkedIcon={
                                                            <img src={Checked} alt="checkbox" style={{width: "18px", height: "18px"}} />
                                                        }
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                formik.setFieldValue("isTaxExempts", true);
                                                            } else {
                                                                formik.setFieldValue("isTaxExempts", false);
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                                <Stack className={styles.tax}>This order is tax exempt</Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            className={styles.priceData}
                                            sx={{alignItems: "center", display: "flex", justifyContent: "flex-end", paddingRight: "10px"}}
                                        >
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {FormatCurrency(calculateSalesTax())}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={0.3} sx={{borderTop: "2px solid #CCCFD6", paddingTop: "5px"}}>
                                        <Grid item xs={9}>
                                            <Typography className={styles.totalHeader} fontWeight={400}>
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.totalHeader} fontWeight={400}>
                                                {FormatCurrency(calculateOverallPrice())}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                Per person order items
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {FormatCurrency(calculatePerPersonOrderItem())}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                Per person total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} className={styles.priceData}>
                                            <Typography className={styles.summary} fontWeight={400}>
                                                {FormatCurrency(calculatePerPersonTotal())}
                                            </Typography>
                                        </Grid>
                                        {formik.values.advance_deposit_amount ? (
                                            <>
                                                <Grid item xs={9}>
                                                    <Typography className={styles.summary} fontWeight={400}>
                                                        Advance deposit
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} className={styles.priceData}>
                                                    <Typography className={styles.summary} fontWeight={400}>
                                                        {FormatCurrency(formik.values.advance_deposit_amount)}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        ) : null}
                                        {(formik.values.orderStatus === "Accepted" ||
                                            formik.values.orderStatus === "Active" ||
                                            formik.values.orderStatus === "Driver assigned" ||
                                            formik.values.orderStatus === "ON_THE_WAY" ||
                                            formik.values.orderStatus === "Needs Driver" ||
                                            formik.values.orderStatus === "New") &&
                                            Reorder !== "reOrder" && (
                                                <Grid
                                                    item
                                                    xs={11.8}
                                                    mt={1}
                                                    sx={{background: "#EEF7FF", borderRadius: "4px", padding: "8px"}}
                                                >
                                                    <Typography variant="subDetail1" fontSize="0.813rem" color="#020A11" fontWeight={600}>
                                                        <Stack
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                color: "#2196F3"
                                                            }}
                                                        >
                                                            <Stack ml={0.5}>
                                                                Last total was {FormatCurrency(formik.values.paid_already)}
                                                            </Stack>
                                                        </Stack>
                                                    </Typography>
                                                </Grid>
                                            )}
                                    </Grid>
                                </Box>
                                <Grid container mt={2.5}>
                                    <Grid item xs={2}>
                                        {/* <Button
                                            className={styles.cancelBtn}
                                            onClick={() => {
                                                toggleDilog(false)();
                                                formik.resetForm({values: initialValues});
                                                handleReset();

                                                dispatch({
                                                    type: "RemoveItem"
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button> */}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={10}
                                        sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end", textAlign: "right"}}
                                    >
                                        <Button
                                            className={styles.cancelBtn}
                                            onClick={() => {
                                                toggleDilog(false)();
                                                formik.resetForm({values: initialValues});
                                                handleReset();

                                                dispatch({
                                                    type: "RemoveItem"
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        {(formik.values.orderStatus === "Draft" ||
                                            formik.values.orderStatus === "Quote Sent" ||
                                            formik.values.orderStatus === "") && (
                                            <Button
                                                variant="outlined"
                                                className={styles.quoteBtn}
                                                onClick={(e) => {
                                                    handleBtnClick(e);
                                                    // handleTouch(formik)
                                                    // formik.handleSubmit();
                                                    // formik.setFieldTouched("headerData.customer.userId", true);
                                                    // const {userId} = formik.values.headerData.customer;
                                                    // if (userId !== "" && userId !== null) {
                                                    //     handleBtnClick(e);
                                                    // handleUpdateOrder(formik.values);
                                                    // }
                                                }}
                                            >
                                                Save draft
                                                <img src={DownArrow} alt="down_arrow" />
                                            </Button>
                                        )}
                                        {Reorder === "reOrder" && (
                                            <Button
                                                variant="outlined"
                                                className={styles.quoteBtn}
                                                onClick={(e) => {
                                                    handleBtnClick(e);
                                                    // formik.setFieldTouched("headerData.customer.userId", true);
                                                    // const {userId} = formik.values.headerData.customer;
                                                    // if (userId !== "" && userId !== null) {
                                                    //     handleUpdateOrder(formik.values);
                                                    // }
                                                }}
                                            >
                                                Save draft
                                                <img src={DownArrow} alt="down_arrow" />
                                            </Button>
                                        )}

                                        {(formik.values.orderStatus === "Draft" ||
                                            formik.values.orderStatus === "Quote Sent" ||
                                            formik.values.orderStatus === "") && (
                                            <Button
                                                variant="outlined"
                                                className={styles.quoteBtn}
                                                disabled={
                                                    calculateOverallPrice() === parseFloat(formik.values.paid_already).toFixed(2)
                                                }
                                                onClick={(e) => {
                                                    setOpenDialog(true);
                                                    handleTouch(formik);
                                                    formik.handleSubmit();
                                                    getCutOffTime();
                                                    // formik.validateForm().then((res) => {
                                                    //     // to open the sidebar if no field has validation error
                                                    //     if (Object.keys(res).length === 0) {
                                                    //         handleQuoteView(e);
                                                    //     }
                                                    // });
                                                }}
                                            >
                                                Send quote
                                            </Button>
                                        )}
                                        {Reorder === "reOrder" && (
                                            <Button
                                                variant="outlined"
                                                className={styles.quoteBtn}
                                                onClick={(e) => {
                                                    setOpenDialog(true);
                                                    handleTouch(formik);
                                                    formik.handleSubmit();
                                                    getCutOffTime();

                                                    // formik.validateForm().then((res) => {
                                                    //     // to open the sidebar if no field has validation error
                                                    //     if (Object.keys(res).length === 0) {
                                                    //         handleQuoteView(e);
                                                    //     }
                                                    // });
                                                }}
                                            >
                                                Send quote
                                            </Button>
                                        )}

                                        <Button
                                            variant="contained"
                                            className={styles.paymentBtn}
                                            onClick={(e) => {
                                                if (Reorder === "reOrder") {
                                                    setOpenPaymentDialog(true);
                                                    setTimeout(() => {
                                                        formik.handleSubmit("reOrder");
                                                    }, 1000);
                                                } else if (
                                                    formik.values.orderStatus === "Accepted" ||
                                                    formik.values.orderStatus === "Active" ||
                                                    formik.values.orderStatus === "Driver Assigned" ||
                                                    formik.values.orderStatus === "ON_THE_WAY" ||
                                                    formik.values.orderStatus === "Needs Driver" ||
                                                    formik.values.orderStatus === "DELIVERED"
                                                ) {
                                                    // if order is in schedule tab
                                                    if (parseFloat(calculateOverallPrice()) < parseFloat(formik.values.paid_already)) {
                                                        // setOpenRefundDialog(true)
                                                        setRefundAmount(
                                                            parseFloat(formik.values.paid_already) - parseFloat(calculateOverallPrice())
                                                        );
                                                        handleOpenModal(true);
                                                    } else if (
                                                        parseFloat(calculateOverallPrice()) > parseFloat(formik.values.paid_already)
                                                    ) {
                                                        setExtraAmount(
                                                            parseFloat(calculateOverallPrice()) - parseFloat(formik.values.paid_already)
                                                        );
                                                        handleOpenModal(true);
                                                    } else {
                                                        setOpenPaymentDialog(true);
                                                        formik.handleSubmit();
                                                    }
                                                } else {
                                                    // if order is in draft or quote sent stage or in Unplaced tab
                                                    setOpenPaymentDialog(true);
                                                    formik.handleSubmit();
                                                }
                                                handleTouch(formik);
                                                // formik.setTouched({ itemModifier: true });
                                            }}
                                        >
                                            {handlePaymentText()}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <OrderUpdationModal
                            open={openCancelModal}
                            buttonTitle="Confirm"
                            title={`Updated order total: ${FormatCurrency(calculateOverallPrice())}`}
                            description="Would you like to charge a cancelation fee?"
                            handleClose={handleClose}
                            handleSubmit={handleOrderUpdationModal}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
            {!expanded && (
                <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: "0.9rem", paddingBottom: "1rem"}}>
                    <Box sx={{width: "75%"}}>{/* {} */}</Box>
                    <Box sx={{width: "34%"}}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography className={styles.totalHeader} fontWeight={400}>
                                    Total
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className={styles.priceData}>
                                <Typography className={styles.totalHeader} fontWeight={400}>
                                    {FormatCurrency(calculateOverallPrice())}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container mt={2.5}>
                            <Grid item xs={1} sx={{paddingLeft: "0.3rem"}}>
                                {/* <Button
                                    className={styles.cancelBtn}
                                    onClick={() => {
                                        toggleDilog(false)();
                                        formik.resetForm({values: initialValues});
                                        handleReset();

                                        dispatch({
                                            type: "RemoveItem"
                                        });
                                    }}
                                >
                                    Cancel
                                </Button> */}
                            </Grid>
                            <Grid item xs={11} sx={{display: "flex", flexDirection: "row", justifyContent: "flex-end", textAlign: "right"}}>
                                <Button
                                    className={styles.cancelBtn}
                                    onClick={() => {
                                        toggleDilog(false)();
                                        formik.resetForm({values: initialValues});
                                        handleReset();

                                        dispatch({
                                            type: "RemoveItem"
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                                {(formik.values.orderStatus === "Draft" ||
                                    formik.values.orderStatus === "Quote Sent" ||
                                    formik.values.orderStatus === "") && (
                                    <Button
                                        variant="outlined"
                                        sx={{padding: "5px 12px"}}
                                        className={styles.quoteBtnOuter}
                                        onClick={(e) => {
                                            handleBtnClick(e);
                                            // formik.setFieldTouched("headerData.customer.userId", true);
                                            // const {userId} = formik.values.headerData.customer;
                                            // if (userId !== "" && userId !== null) {
                                            //     handleUpdateOrder(formik.values);
                                            // }
                                        }}
                                    >
                                        Save draft
                                        <img src={DownArrow} alt="down_arrow" />
                                    </Button>
                                )}
                                {Reorder === "reOrder" && (
                                    <Button
                                        variant="outlined"
                                        sx={{padding: "5px 12px"}}
                                        className={styles.quoteBtnOuter}
                                        onClick={(e) => {
                                            handleBtnClick(e);
                                            // formik.setFieldTouched("headerData.customer.userId", true);
                                            // const {userId} = formik.values.headerData.customer;
                                            // if (userId !== "" && userId !== null) {
                                            //     handleUpdateOrder(formik.values);
                                            // }
                                        }}
                                    >
                                        Save draft
                                        <img src={DownArrow} alt="down_arrow" />
                                    </Button>
                                )}

                                {(formik.values.orderStatus === "Draft" ||
                                    formik.values.orderStatus === "Quote Sent" ||
                                    formik.values.orderStatus === "") && (
                                    <Button
                                        variant="outlined"
                                        sx={{padding: "5px 12px"}}
                                        className={styles.quoteBtnOuter}
                                        disabled={calculateOverallPrice() === parseFloat(formik.values.paid_already).toFixed(2)}
                                        onClick={(e) => {
                                            setOpenDialog(true);
                                            handleTouch(formik);
                                            formik.handleSubmit();
                                            getCutOffTime();

                                            // formik.validateForm().then((res) => {
                                            //     // to open the sidebar if no field has validation error
                                            //     if (Object.keys(res).length === 0) {
                                            //         handleQuoteView(e);
                                            //     }
                                            // });
                                        }}
                                    >
                                        Send quote
                                    </Button>
                                )}
                                {Reorder === "reOrder" && (
                                    <Button
                                        variant="outlined"
                                        className={styles.quoteBtnOuter}
                                        sx={{padding: "5px 12px"}}
                                        onClick={(e) => {
                                            setOpenDialog(true);
                                            handleTouch(formik);
                                            formik.handleSubmit();
                                            getCutOffTime();

                                            // formik.validateForm().then((res) => {
                                            //     // to open the sidebar if no field has validation error
                                            //     if (Object.keys(res).length === 0) {
                                            //         handleQuoteView(e);
                                            //     }
                                            // });
                                        }}
                                    >
                                        Send quote
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    className={styles.paymentBtnOuter}
                                    sx={{padding: "5px 15px"}}
                                    onClick={(e) => {
                                        if (Reorder === "reOrder") {
                                            setOpenPaymentDialog(true);
                                            formik.handleSubmit();
                                        } else if (
                                            formik.values.orderStatus === "Accepted" ||
                                            formik.values.orderStatus === "Active" ||
                                            formik.values.orderStatus === "Driver Assigned" ||
                                            formik.values.orderStatus === "ON_THE_WAY" ||
                                            formik.values.orderStatus === "Needs Driver" ||
                                            formik.values.orderStatus === "DELIVERED"
                                        ) {
                                            // if order is in schedule tab
                                            if (parseFloat(calculateOverallPrice()) < parseFloat(formik.values.paid_already)) {
                                                // setOpenRefundDialog(true)
                                                setRefundAmount(
                                                    parseFloat(formik.values.paid_already) - parseFloat(calculateOverallPrice())
                                                );
                                                handleOpenModal(true);
                                            } else if (parseFloat(calculateOverallPrice()) > parseFloat(formik.values.paid_already)) {
                                                setExtraAmount(
                                                    parseFloat(calculateOverallPrice()) - parseFloat(formik.values.paid_already)
                                                );
                                                handleOpenModal(true);
                                            } else {
                                                setOpenPaymentDialog(true);
                                                formik.handleSubmit();
                                            }
                                        } else {
                                            // if order is in draft or quote sent stage or in Unplaced tab
                                            setOpenPaymentDialog(true);
                                            formik.handleSubmit();
                                        }
                                        handleTouch(formik);
                                        // formik.setTouched({ itemModifier: true });
                                    }}
                                >
                                    {handlePaymentText()}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}

            <Popper open={open} anchorEl={anchorEl} sx={{zIndex: 1250, marginBottom: "17px !important", backgroundColor: "#fff"}}>
                <ClickAwayListener onClickAway={handlePopupClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "8px"}}>
                        <MenuItem
                            onClick={() => {
                                formik.setFieldTouched("headerData.customer.userId", true);
                                // formik.setFieldTouched("headerData.when", true);
                                formik.setFieldTouched("headerData.deliveryTo", true);
                                formik.setFieldTouched("kitchenLocationId", true);

                                const {userId} = formik.values.headerData.customer;
                                toggleDrawer("right", false)();

                                // const date = formik.values.headerData.when;
                                const deliveryTo = formik.values.headerData.deliveryTo;
                                // const selectedMenu = formik.values.selectedMenu;
                                // const selectedBrand = formik.values.selectedBrand;
                                // const itemDetail = formik.values.itemDetail;
                                const kitchenId = formik.values.kitchenLocationId;
                                if (userId !== "" && userId !== null && deliveryTo !== "" && deliveryTo !== null && kitchenId !== "") {
                                    handleScheduleOrder();
                                }
                                handlePopupClose();
                            }}
                        >
                            Schedule on calendar
                        </MenuItem>
                        <MenuItem
                            onClick={(e) => {
                                formik.setFieldTouched("headerData.customer.userId", true);
                                setOpenPaymentDialog(false);
                                const {userId} = formik.values.headerData.customer;
                                if (userId !== "" && userId !== null) {
                                    handleUpdateOrder(formik.values);
                                }
                                handlePopupClose();
                            }}
                        >
                            Save as draft
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Popper>
        </>
    );
}

export default CreateOrderFooter;
