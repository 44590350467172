import {Box, Grid, Stack, Typography} from "@mui/material";
import CustomErrorMessage from "commonComponent/Form/Fields/CustomErrorMessage";
import CustomSelect from "commonComponent/Form/Fields/CustomSelect";
import CustomSwitch from "commonComponent/Form/Fields/CustomSwitch";
import {ErrorMessage} from "formik";
import React from "react";

const RulesSection = (props) => {
    const {formik} = props;
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="headingOne">Rules</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography variant="body4" fontSize="13px">
                                Require a selection
                            </Typography>
                        </Box>
                        <CustomSwitch
                            // disabled={!isEdit}
                            checked={formik.values.requireSelection}
                            onChange={(e) => {
                                formik.setFieldValue("requireSelection", e.target.checked);
                            }}
                        />
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography variant="body4" fontSize="13px">
                                Allow multiple selections
                            </Typography>
                        </Box>
                        <CustomSwitch
                            // disabled={!isEdit}
                            checked={formik.values.allowMultiple}
                            onChange={(e) => {
                                formik.setFieldValue("allowMultiple", e.target.checked);
                                formik.setFieldValue("minimum", "");
                                formik.setFieldValue("maximum", "");
                            }}
                        />
                    </Stack>
                </Grid>

                {formik.values.allowMultiple && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <CustomSelect
                                    // disabled={!isEdit}
                                    noNone
                                    label="Minimum"
                                    numberValidation
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 200
                                            }
                                        }
                                    }}
                                    menuItems={[
                                        {
                                            label: 1,
                                            id: 1
                                        },
                                        {
                                            label: 2,
                                            id: 2
                                        },
                                        {
                                            label: 3,
                                            id: 3
                                        },
                                        {
                                            label: 4,
                                            id: 4
                                        },
                                        {
                                            label: 5,
                                            id: 5
                                        }
                                    ]}
                                    {...formik.getFieldProps("minimum")}
                                />
                                <ErrorMessage name="minimum" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <CustomSelect
                                noNone
                                    // disabled={!isEdit}
                                    label="Maximum"
                                    numberValidation
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 250
                                            }
                                        }
                                    }}
                                    menuItems={[
                                        {
                                            label: 1,
                                            id: 1
                                        },
                                        {
                                            label: 2,
                                            id: 2
                                        },
                                        {
                                            label: 3,
                                            id: 3
                                        },
                                        {
                                            label: 4,
                                            id: 4
                                        },
                                        {
                                            label: 5,
                                            id: 5
                                        },
                                        {
                                            label: "No max",
                                            id: 0,
                                        }
                                    ]}
                                    {...formik.getFieldProps("maximum")}
                                />
                                <ErrorMessage name="maximum" render={(msg) => <CustomErrorMessage errorMessage={msg} />} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Typography variant="body4" fontSize="13px">
                                Allow quantity modification
                            </Typography>
                        </Box>
                        <CustomSwitch
                            // disabled={!isEdit}
                            checked={formik.values.allowQuantityModification}
                            onChange={(e) => {
                                formik.setFieldValue("allowQuantityModification", e.target.checked);
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default RulesSection;
