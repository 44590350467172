import {Grid} from "@mui/material";

import CommonTable from "commonComponent/TableSection/CommonTable";
import {NetworkManager, API} from "network/core";
import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {openSnackbar} from "store/slices/snackbar";
import {useNavigate, useSearchParams} from "react-router-dom";
import FilterChips from "commonComponent/FilterChipsComponent";
import {generateUrl} from "utils/helperFunctions/helpers";
import Filters from "commonComponent/Filters";
import useTempDrawer from "hooks/useTempDrawer";
import {CsvBuilder} from "filefy";
import WithPermission from "commonComponent/RBAC/permissionHoc";
import Fields from "commonComponent/Apptable/getFieldsForDownload";
import {setPageNumber, setRowsPerPage, setOrder, setOrderBy} from "store/slices/paginationSlice";

const Sales = (props) => {
    const {toggleDrawer} = useTempDrawer();
    const {search, showFilter, setSelectedCheckbox, updateListCount, selectedCheckbox} = props;
    const [title, setTitle] = useState("Create Customer");

    const [open, setOpen] = React.useState(false);
    const [orderListing, setOrderListing] = React.useState([]);
    const currentPage = useSelector((state) => state?.pagination?.currentPage);
    const rowsPerPage = useSelector((state) => state?.pagination?.rowsPerPage);
    const currentOrder = useSelector((state) => state?.pagination?.order || "asc");
    const currentOrderBy = useSelector((state) => state?.pagination?.orderBy || "period");
    const [isEdited, setEdited] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const filterChips = useSelector((state) => state?.pagination?.filterChips);
    const [urlSearchParams] = useSearchParams();

    const navigation = useNavigate();
    const tab = urlSearchParams.get("tab");
    const modifierId = urlSearchParams.get("id");
console.log("this is it")
    const dispatch = useDispatch();

    const getOrdersList = async (moreFilters = false) => {
        setLoading(true);
        const instance = NetworkManager(API.REPORTS.SALESLISTING);
        const params = {
            offset: currentPage,
            limit: rowsPerPage,

            ...(moreFilters && {...moreFilters})
        };
        if (moreFilters && moreFilters.ltv) {
            if (moreFilters.ltvFilter === "equal" || moreFilters.ltvFilter === undefined) {
                params.ltv = moreFilters.ltv;
            } else {
                delete params.ltv;
                params[`ltv${moreFilters.ltvFilter}`] = moreFilters.ltv;
            }
            delete params.ltvFilter;
        }
        if (moreFilters && moreFilters.aov) {
            if (moreFilters.aovFilter === "equal" || moreFilters.aovFilter === undefined) {
                params.aov = moreFilters.aov;
            } else {
                delete params.aov;
                params[`aov${moreFilters.aovFilter}`] = moreFilters.aov;
            }
            delete params.aovFilter;
        }
        if (moreFilters && moreFilters.order_count) {
            if (moreFilters.orderCountFilter === "equal" || moreFilters.orderCountFilter === undefined) {
                params.order_count = moreFilters.order_count;
            } else {
                delete params.order_count;
                params[`order_count${moreFilters.orderCountFilter}`] = moreFilters.order_count;
            }
            delete params.orderCountFilter;
        }
        if (currentOrderBy) {
            params.sortBy = currentOrderBy;
            params.orderBy = currentOrder;
        }

        if (search === "") {
            params.page = currentPage;
        }
        if (search !== "") {
            params.full_name = search;
            setOrderListing([]);
        }

        const url = generateUrl(params);

        const response = await instance.request({}, [url]);

        if (response.success) {
            const tempData = [];
            response.data?.salesReport.forEach((element) => {
                const obj = element;
                obj.reportUniqueID = "uniqueReportElement";

                tempData.push(obj);
            });
            setOrderListing(tempData);
            setTotalItems(response.data.pagination.totalItems);
            setSelectedRow(null);
        }
        setLoading(false);
    };

    const handleSelectedRow = (row) => {
        setSelectedRow(row);
    };

    const handleIsEdited = () => {
        setEdited((prev) => !prev);
    };

    const handleStatus = async (values, type) => {
        // if selectedRow is not null have to add PUT api else ADD api
        const instance = NetworkManager(API.DRIVER.UPDATE(`${values.driver_id}`));
        let isActive = false;
        if (type === "Deactivate") {
            isActive = false;
        } else {
            isActive = true;
        }
        const payload = {
            is_active: isActive
        };
        const response = await instance.request(payload);
        if (response.success) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        color: "success"
                    },
                    close: false
                })
            );
            handleIsEdited();
        } else if (response.error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response.message,
                    variant: "alert",
                    alert: {
                        severity: "error",
                        color: "error"
                    },
                    close: false
                })
            );
        }
    };

    const columnData = [
        {key: "period", label: "Period"},
        {key: "orders", label: "Orders"},
        {key: "sales", label: "Sales"},
        {key: "taxExempt", label: "Tax exempt"},
        {key: "salesTax", label: "Sales tax"}
    ];

    const csvBuilder = (rows) => {
        const temp = [];
        temp.push(JSON.stringify(rows));

        new CsvBuilder("report_list.csv")
            .setColumns(columnData.map((col) => col.label))
            .addRows(
                temp.map((row) =>
                    columnData.map((col) => {
                        const arr = JSON.parse(row);

                        return Fields(col.key, arr);
                    })
                )
            )
            .exportFile();

        setSelectedCheckbox([]);
    };

    const handleActionBtn = (type, row, handleClose) => (e) => {
        handleSelectedRow(row);
        handleClose();
        switch (type) {
            case "View orders": {
                navigation(`/reports/orderlist?PeriodName=${row?.period}`);
                break;
            }
            case "Download": {
                csvBuilder(row);
                break;
            }

            default:
                break;
        }
    };

    const handlePageChange = (e, number) => {
        dispatch(setPageNumber(number + 1));
        // setCurrentPage(number + 1);
    };

    const handleRowsChange = (e) => {
        const number = parseInt(e.target.value, 10);
        // setRowsPerPage(number);
        dispatch(setRowsPerPage(number));
        dispatch(setPageNumber(1));
    };

    const handleSort = (val1, val2) => {
        dispatch(setOrder(val1));
        dispatch(setOrderBy(val2));
    };

    useEffect(() => {
        if (search) {
            dispatch(setPageNumber(1));
        }
    }, [search]);

    useEffect(() => {
        getOrdersList(Object.keys(filterChips).length ? filterChips : "");
    }, [search, currentPage, rowsPerPage, currentOrder, currentOrderBy, isEdited, filterChips, updateListCount]);

    // const handleApplyFilter = (Morefilters) => {
    //     setCurrentPage(1);
    //     setFilterChips(Morefilters);
    // };
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <FilterChips filterChips={filterChips} 
                    // setFilterChips={setFilterChips}
                     />
                    <CommonTable
                        withCheckbox
                        loading={loading}
                        data={orderListing}
                        notCaptilize
                        header={[
                            {key: "period", label: "Period"},
                            {key: "orders", label: "Orders"},
                            {key: "sales", label: "Sales"},
                            {key: "taxExempt", label: "Tax exempt"},
                            {key: "salesTax", label: "Sales tax"},

                            WithPermission({module: "Orders", permission: ["read_permission"]}) && {
                                key: "action",
                                label: "Action"
                            }
                        ].filter((el) => el)}
                        handleActionBtn={handleActionBtn}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsChange}
                        handleSort={handleSort}
                        totalItems={totalItems}
                        rowsPerPage={rowsPerPage}
                        setSelectedCheckbox={setSelectedCheckbox}
                        selectedCheckbox={selectedCheckbox}
                        currentPage={currentPage - 1}
                        csvBuilder={csvBuilder}
                        sortingLabel={["Period"]}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Sales;
